<template>
  <v-card>
    <v-card-title><b>{{ $t('key656') }}</b></v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th 
                v-for="header in headers_dett"
                :key="header.value"
              >
                {{header.text}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="dett in items.dett"
              :key="dett.name"
            >
              <td v-for="header in headers_dett" :key="header.value">{{ header.value == "brdtsc" ? dett[`${header.value}`] > 0 ? formatAS400toData(dett[`${header.value}`]) : "" : dett[`${header.value}`] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="downloadCSV()"
        text
        :fab="$vuetify.breakpoint.xs"
        :small="$vuetify.breakpoint.xs"
        color="grey darken-3"
      >
        <span v-if="!$vuetify.breakpoint.xs" v-blur>
          {{ $t('key619') }}
          <v-icon right> mdi-download </v-icon>
        </span>
        <v-icon v-else> mdi-download </v-icon>
      </v-btn>
      <v-spacer/>
      <v-btn
        color="primary"
        depressed
        @click="$emit('close-dialog')"
      >
        {{$t("key118")}}
      </v-btn>
    </v-card-actions>

    <!-- EXPORT DIALOG -->
    <v-dialog v-model="dialog_CSV" max-width="500px">
      <v-card>
        <v-card-title class="headline pt-4">Download CSV</v-card-title>
        <v-card-text>
          <div class="mt-4" v-if="!dialog_CSV_loading">
            <p style="display: inline">{{ $t("key641") }}</p>
            <a
              :download="`Export_dettaglio_documenti_uscita_${date_export}.csv`"
              :href="file_CSV"
              style="text-decoration: none; display: inline"
              class="ml-4"
            >
              <v-btn @click="dialog_CSV = false" color="primary" depressed>
                Download
                <v-icon right> mdi-download </v-icon>
              </v-btn>
            </a>
          </div>
          <div class="d-flex align-center flex-column mt-4" v-else>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="dialog_CSV = false">{{$t("key118")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "DocumentiUscita",
  data: () => ({
    //Export
    dialog_CSV: false,
    dialog_CSV_loading: false,
    date_export: null,
    file_CSV: null
  }),
  props: ['items'],
  methods: {
    formatAS400toData(as400_data) {
      as400_data = as400_data.toString();

      if (as400_data.length < 8) {
        return "";
      }

      return `${as400_data.substr(6, 8)}/${as400_data.substr(4, 2)}/${as400_data.substr(0, 4)}`;
    },
    downloadCSV() {
      this.dialog_CSV_loading = true;
      this.dialog_CSV = true;

      this.$nextTick(() => {
        var csv = "";
        csv += `"${this.$t('key657')}";"${this.$t("key639")}";"${this.$t("key651")}";"UM";"${this.$t("key652")}";"${this.$t("key653")}";"${this.$t("key654")}"\n`;
        // csv += `"N. RIGA";"COD. ART.";"DESCRIZIONE";"UM";"N. LOTTO";"DT. SCAD.";"QTÀ"\n`;

        this.items.dett.forEach((row) => {
          csv += `"${row["brprig"]}";`;
          csv += `"${row["brcart"]}";`;
          csv += `"${row["ardesc"]}";`;
          csv += `"${row["arumma"]}";`;
          csv += `"${row["brnlot"]}";`;
          csv += `"${row["brdtsc"] > 0 ? this.formatAS400toData(row["brdtsc"]) : ""}";`;
          csv += `"${row["quantita"].toString().replace(".", ",")}"\n`;
        });

        var universalBOM = "\uFEFF";
        var csvData = new Blob([universalBOM + csv], {
          type: "text/csv;charset=utf-8",
        });
        // var csvData = new Blob([csv], { type: "text/csv;charset=utf8" });
        this.file_CSV = URL.createObjectURL(csvData);

        this.dialog_CSV_loading = false;

        var d = new Date();
        this.date_export =
          d.getFullYear() +
          "" +
          (d.getMonth() + 1) +
          "" +
          d.getDate() +
          "" +
          d.getHours() +
          "" +
          d.getMinutes() +
          "" +
          d.getSeconds();
      });
    },
  },
  computed: {
    headers_dett() { 
      return [
        { text: "#", value: "brprig"},
        { text: this.$t("key639"), value: "brcart"},
        { text: this.$t("key651"), value: "ardesc"},
        { text: "U.M.", value: "arumma"},
        { text: this.$t("key652"), value: "brnlot"},
        { text: this.$t("key653"), value: "brdtsc"},
        { text: this.$t("key654"), value: "quantita"},
      ]
    } 
  }
};
</script>