<template>
  <v-layout justify-center>
    <v-dialog v-model="overlay" persistent max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imageNotFound" max-width="400">
      <v-card>
        <v-card-title>
          <b>FOTO NON TROVATA</b>
        </v-card-title>
        <v-card-text>
          Il file della foto richiesta non è presente sui sistemi Arco
          Spedizioni.<br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errore" max-width="400">
      <v-card>
        <v-card-title>
          <b>ERRORE VISAULIZZAZIONE FOTO</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile recuperare la foto richiesta.<br />
          Si prega di riprovare.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mostraImmagine"
      persistent
      :max-width="larghezzaImmagine + 200"
      :max-height="altezzaImmagine"
    >
      <v-card>
        <v-card-text style="padding-top: 20px">
          <v-carousel
            :hide-delimiters="true"
            :height="altezzaImmagine"
            :width="larghezzaImmagine"
            light
            class="text-center"
          >
            <v-carousel-item v-for="(item, i) in images" :key="i">
              <img :src="item.src" :alt="item.src" />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  data() {
    return {
      arrows: false,
      overlay: false,
      imageNotFound: false,
      errore: false,
      mostraImmagine: false,
      altezzaImmagine: 0,
      larghezzaImmagine: 0,
      images: [],
      folder: "",
      anno: "",
      mese: "",
      giorno: "",
      image: "",
      path: "",
      url: "",
    };
  },
  mounted() {
    //this.folder = this.$route.params.folder;
    this.anno = this.$route.params.anno;
    this.mese = this.$route.params.mese;
    this.giorno = this.$route.params.giorno;
    this.image = this.$route.params.image;
    this.path =
      this.anno + "\\" + this.mese + "\\" + this.giorno + "\\" + this.image;

    this.url = this.$store.state.servicePath + "/api/Pod/Piombi"; //Devo aggiungere il nome del controller in coda
    this.axios
      .post(this.url, {
        nomeFile: this.path,
        estensione: "",
      })
      .then((res) => {
        if (res.data.nomeFile == "Not found") {
          this.imageNotFound = true;
          //creo dialog per immagine non trovata
        } else {
          var jpg = res.data.src;
          this.images.push({
            src: "data:image/jpg" + ";base64," + jpg,
          });
          this.altezzaImmagine = Number(res.data.nomeFile);
          this.larghezzaImmagine = Number(res.data.estensione);
          this.mostraImmagine = true;
          //apro immagine
        }
      })
      .catch(() => {
        this.errore = true;
        //Gestisco l'errore di ricerca immagine
      });
  },
};
</script>