<template>
  <v-layout justify-center>
    <v-dialog v-model="overlay" persistent max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="firmaNonTrovata" max-width="400">
      <v-card>
        <v-card-title>
          <b>FOTO ANOMALIA NON TROVATA</b>
        </v-card-title>
        <v-card-text>
          Il file della foto anomalia richiesta non è presente sui sistemi Arco
          Logistica.<br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errore" max-width="400">
      <v-card>
        <v-card-title>
          <b>ERRORE VISAULIZZAZIONE FOTO ANOMALIA</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile recuperare la foto anomalia richiesta.<br />
          Si prega di riprovare tra qualche minuto e nel caso non si riuscisse
          contattare l'assistenza.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mostraPod" persistent max-width="980px">
      <v-card>
        <v-card-text>
          <v-carousel
            :cycle="false"
            :show-arrows="true"
            :hide-delimiters="true"
            height="altezzaPod"
            prev-icon="skip_previous"
            next-icon="skip_next"
            light
          >
            <v-carousel-item v-for="(item, i) in images" :key="i">
              <img
                :src="item.src"
                style="width: 770px; height: auto"
                :alt="item.src"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      numeroSpedizione: "",
      mostraPod: false,
      altezzaPod: 0,
      images: [],
      img: "",
      overlay: true,
      errore: false,
      firmaNonTrovata: false,
    };
  },
  mounted() {
    this.numeroSpedizione = this.$route.params.numeroSpedizione;
    this.numeroLista = this.$route.params.numeroLista;
    this.scaricaAnomalia(this.numeroSpedizione, this.numeroLista);
  },
  methods: {
    scaricaAnomalia(numsped, numlista) {
      if (numsped === undefined || numsped === null) {
        numsped = "";
      }
      if (numlista === undefined || numlista === null) {
        numlista = "";
      }
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/DownloadAnomalia/" +
            numsped.trim() +
            "/" +
            numlista.trim()
        )
        .then((res) => {
          this.overlay = false;
          var lunghezzaLista = res.data.length;
          if (lunghezzaLista === 0) {
            this.firmaNonTrovata = true;
          } else {
            var pdf = res.data[0].src;
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            const blobUrl = URL.createObjectURL(blob);
            window.location = blobUrl;
            // pdfWindow.document.write(
            //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            //     pdf +
            //     "'></iframe>"
            // );
          }
        })
        .catch(() => {
          this.overlay = false;
          this.errore = true;
        });
    },
  },
};
</script>
