<template>
  <v-container>
    <v-row v-if="$store.state.visualizzaSelectMaster">
      <v-col cols="12" md="4">
        <v-select
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogFattu" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#4EABDD" dark text @click="dialogFattu = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEspresso" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
        <v-card-text>{{ $t("key616") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="dialogEspresso = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout justify-center>
      <v-dialog v-model="dialogRitiroTassativo" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text>{{ $t("key615") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="dialogRitiroTassativo = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="utenteBloccato" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
          <v-card-text>{{ $t("key613") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="close()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreAnagrafica" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
          <v-card-text>{{ $t("key614") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                erroreAnagrafica = false;
                Reset();
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="messaggioMisure" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key599") }}</v-card-title>
          <v-card-text>{{ $t("key598") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="messaggioMisure = false"
              >OK</v-btn
            >
            <v-btn depressed color="primary" @click="ChiudiErroreDettaglio()">{{
              $t("key597")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="messaggioAssicurata" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text>{{ $t("key591") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="messaggioAssicurata = false"
              >SI</v-btn
            >
            <v-btn
              depressed
              color="primary"
              @click="
                messaggioAssicurata = false;
                $refs.assicurata.focus();
              "
              >NO</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreAssicurata" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text
            >Il valore massimo dell'assicurata è 9999999€</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                erroreAssicurata = false;
                $refs.assicurata.focus();
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="messageEspresso" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text>{{ $t("key435") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="messageEspresso = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErroreVolume" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text v-html="erroreVolume">{{ erroreVolume }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                dialogErroreVolume = false;
                $refs.volume.focus();
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="riferimentoConsegna" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              :label="$t('key437')"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
            <v-data-table
              :headers="headersRiferimenti"
              :items="listaRiferimentiConsegna"
              :items-per-page="10"
              sort-by="ref"
              @click:row="selezionaRiferimentoConsegna"
            >
            </v-data-table>
            <v-btn
              large
              depressed
              color="primary"
              @click="selezionaRiferimentoConsegna()"
              >{{ $t("key436") }}</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="riferimentiRitiro" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              :label="$t('key437')"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
            <v-data-table
              :headers="headersRiferimenti"
              :items="listaRiferimentiRitiro"
              :items-per-page="10"
              sort-by="ref"
              @click:row="selezionaRiferimentoRitiro"
            >
            </v-data-table>
            <v-btn
              large
              depressed
              color="primary"
              @click="selezionaRiferimentoRitiro()"
              >{{ $t("key436") }}</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="Rubrica" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              :label="$t('key437')"
              single-line
              hide-details
              v-model="search"
              @input="customFilter"
            ></v-text-field>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="10"
              sort-by="ragioneSociale"
              @click:row="selezionaRecord"
            >
            </v-data-table>
            <v-btn large depressed color="primary" @click="Rubrica = false">{{
              $t("key436")
            }}</v-btn>
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="RubricaRitiro" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-text-field
              append-icon="mdi-magnify"
              :label="$t('key437')"
              single-line
              hide-details
              v-model="search"
              @input="customFilter"
            ></v-text-field>

            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="10"
              sort-by="ref"
              @click:row="selezionaRecordRitiro"
            >
            </v-data-table>
            <v-btn
              large
              depressed
              color="primary"
              @click="RubricaRitiro = false"
              >{{ $t("key436") }}</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCalendario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key434") }}</v-card-title>
          <v-card-text v-html="text"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="erroreCalendario = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogMisure" persistent max-width="650">
        <v-card>
          <v-card-title style="margin-bottom: 0px" class="headline">{{
            $t("key410")
          }}</v-card-title>
          <v-card-text>
            <v-form>
              <v-container class="inputs-container">
                <v-row class="py-0">
                  <v-col cols="12" sm="3" class="py-0">
                    <h4>{{ $t("key411") }}</h4>
                  </v-col>
                  <v-col cols="12" sm="3" class="py-0">
                    <h4>{{ $t("key113") }}</h4>
                  </v-col>
                  <v-col cols="12" sm="3" class="py-0">
                    <h4>{{ $t("key114") }}</h4>
                  </v-col>
                  <v-col cols="12" sm="3" class="py-0">
                    <h4>{{ $t("key115") }}</h4>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <div v-for="item in MisureBancali" :key="item.message">
              <v-form v-model="validMisure" ref="formMisure">
                <v-container class="inputs-container">
                  <v-row class="py-0">
                    <v-col cols="12" sm="3" class="py-1">
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        hide-details
                        v-model="item.numeroPallet"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) => v <= 99 || $t('key438'),
                          (v) => CheckInteri(v) || $t('key439'),
                        ]"
                      />
                    </v-col>
                    <!-- @blur="ControlloMisure" -->
                    <v-col cols="12" sm="3" class="py-1">
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        hide-details
                        v-model="item.lunghezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) => v <= 600 || $t('key440'),
                          (v) => CheckInteri(v) || $t('key439'),
                        ]"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="py-1">
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        hide-details
                        v-model="item.larghezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) => v <= 600 || $t('key440'),
                          (v) => CheckInteri(v) || $t('key439'),
                        ]"
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="py-1">
                      <v-text-field
                        style="padding: 0; margin: 0; line-heigth: 0.2"
                        outlined
                        maxlength="3"
                        hide-details
                        v-model="item.altezza"
                        @keypress="isInteger($event)"
                        :rules="[
                          (v) => v <= 235 || $t('key441'),
                          (v) => CheckInteri(v) || $t('key439'),
                        ]"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              :disabled="!validMisure"
              @click="chiudiDialogMisure"
              >{{ $t("key412") }}</v-btn
            >
            <v-btn
              depressed
              color="primary"
              @click="
                MisureBancali.length < 10
                  ? MisureBancali.push({
                      numeroPallet: '',
                      lunghezza: '',
                      larghezza: '',
                      altezza: '',
                    })
                  : function () {}
              "
              >{{ $t("key413") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="RitiroAggiunto" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key443") }}</v-card-title>
          <v-card-text>
            {{ $t("key444") }} {{ numeroRitiroAggiunto }}.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                RitiroAggiunto = false;
                Reset();
              "
              >{{ $t("key445") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioMAM" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key446") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.mamText.focus();
              "
              >{{ $t("key445") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPDP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key447") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.pdpText.focus();
              "
              >{{ $t("key445") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrarioPAP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key448") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.papText.focus();
              "
              >{{ $t("key445") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="DialogErroreOrario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key449") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                $refs.mdmText.focus();
              "
              >{{ $t("key445") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="salvaRitiro" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key450") }}</v-card-title>
          <v-card-text>{{ $t("key451") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="SalvaRubricaRitiro">{{
              $t("key452")
            }}</v-btn>
            <v-btn
              depressed
              color="primary"
              @click="
                salvaRitiro = false;
                RitiroAggiunto = true;
              "
              >{{ $t("key453") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogNotSave" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key453") }}</v-card-title>
          <v-card-text>{{ $t("key455") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="Indietro()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
          <v-card-text v-html="erroreInserimento">{{
            erroreInserimento
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="logout()">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreCappario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text>{{ $t("key456") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="erroreCappario = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog
        v-if="CapparioDestino"
        v-model="CapparioDestino"
        persistent
        max-width="1400"
      >
        <v-card>
          <v-container>
            <v-data-table
              :headers="headersCappario"
              :items="lista_cappario"
              :search="searchCapparioDestino"
              @click:row="SelezionaCapparioDestino"
            >
            </v-data-table>
            <v-btn
              large
              depressed
              color="primary"
              @click="ChiudiCapparioDestino"
              >{{ $t("key436") }}</v-btn
            >
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-if="Cappario" v-model="Cappario" persistent max-width="1400">
        <v-card>
          <v-container>
            <v-data-table
              :headers="headersCappario"
              :items="lista_cappario"
              :search="searchCappario"
              @click:row="SelezionaCapparioRitiro"
            >
            </v-data-table>
            <v-btn large depressed color="primary" @click="ChiudiCappario">{{
              $t("key436")
            }}</v-btn>
          </v-container>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="erroreValidazione" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">ERRORE</v-card-title>
          <v-card-text>{{ $t("key457") }}</v-card-text>
          <v-card-text v-html="Errore">{{ Errore }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" @click="erroreValidazione = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-card>
      <v-card-text>
        <v-form v-model="validForm" ref="Ritiri">
          <v-container>
            <v-row>
              <v-col col="12" sm="6">
                <v-select
                  menu-props="lazy"
                  v-model="TipoRitiroSelezionato"
                  v-bind:items="tipo_ritiri"
                  :label="$t('key068')"
                  @change="GetTipoRitiro"
                  outlined
                ></v-select>
              </v-col>
              <v-col col="12" sm="3">
                <v-btn
                  :disabled="noRubrica"
                  large
                  depressed
                  color="primary"
                  v-if="TipoRitiroSelezionato !== 1 && !disattivaRubrica"
                  @click="
                    RubricaRitiro = true;
                    RipristinaRubrica();
                  "
                  >{{ $t("key382") }}</v-btn
                >
              </v-col>
              <v-col col="12" sm="3">
                <v-btn large depressed color="primary" @click="ApriManuale()">{{
                  $t("key385")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <h2 class="mb-2">{{ $t("key072") }}</h2>
          <v-container>
            <v-row row wrap>
              <v-col cols="12" sm="6" class="py-1">
                <v-autocomplete
                  :background-color="RagioneSocialeRitiroColore"
                  v-model="RagioneSocialeRitiro"
                  ref="ragione_sociale_ritiro"
                  :label="$t('key073')"
                  :readonly="readonly"
                  maxlength="40"
                  :items="autocomplete"
                  :filter="FiltraAutocomplete"
                  outlined
                  return-object
                  @focus="reinizializzaListaAutocomplete"
                  :search-input.sync="selectedRitiro"
                  @blur="AddAutocompleteRitiro()"
                  @keypress="checkInvioRagioneSocialeRitiro()"
                  autocomplete="null"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                <v-text-field
                  :background-color="IndirizzoRitiroColore"
                  ref="indirizzo_ritiro"
                  :label="$t('key075')"
                  v-model="IndirizzoRitiro"
                  :readonly="readonly"
                  maxlength="40"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-select
                  v-if="!mostra"
                  :items="nazioni"
                  v-model="nazione"
                  :readonly="readonly"
                  :label="$t('key375')"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  :background-color="LocalitaRitiroColore"
                  :label="$t('key076')"
                  v-model="LocalitaRitiro"
                  :readonly="readonly"
                  maxlength="30"
                  return-object
                  outlined
                  @blur="
                    !readonly && (!ProvinciaValida || ProvinciaRitiro === '')
                      ? OpenCappario(LocalitaRitiro)
                      : CambioFocusLocalitaRitiro()
                  "
                  @keypress="checkInvio_Ritiro($event, LocalitaRitiro)"
                  autocomplete="null"
                ></v-text-field>
                <!-- @blur="ProvinciaRitiro === '' ? OpenCappario(LocalitaRitiro):function(){}"
                                  @keypress="ProvinciaRitiro === ''?checkInvio_Ritiro($event, LocalitaRitiro):function(){}" -->
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  :background-color="CAPRitiroColore"
                  ref="cap_ritiro"
                  :label="$t('key078')"
                  v-model="CAPRitiro"
                  :readonly="readonly"
                  maxlength="11"
                  outlined
                  @blur="
                    (ProvinciaRitiro === '' || !ProvinciaValida) && !readonly
                      ? OpenCappario(CAPRitiro)
                      : CambioFocusCapRitiro()
                  "
                  @keypress="
                    ProvinciaRitiro === ''
                      ? checkInvio_Ritiro($event, CAPRitiro)
                      : function () {}
                  "
                  autocomplete="null"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="1" class="py-1">
                <v-text-field
                  :background-color="ProvinciaRitiroColore"
                  ref="provincia_ritiro"
                  :label="$t('key077')"
                  v-model="ProvinciaRitiro"
                  maxlength="2"
                  outlined
                  :readonly="readonly"
                  @blur="Valida_Ritiro()"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-btn
                  large
                  depressed
                  color="primary"
                  :disabled="readonly"
                  @click="PulisciRitiro"
                  >{{ $t("key387") }}</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="!ProvinciaValida">
              <v-col cols="12" class="d-flex align-center justify-center py-1">
                <h4 style="color: red">
                  Attenzione: Località, cap e provincia non corrispondono.
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  :background-color="RiferimentoRitiroColore"
                  ref="riferimento_ritiro"
                  :label="$t('key079')"
                  v-model="RiferimentoRitiro"
                  maxlength="17"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  ref="preavvisare_ritiro"
                  v-model="preavvisare"
                  :label="$t('key080')"
                  @blur="FocusMail()"
                  outlined
                  autocomplete="null"
                ></v-checkbox>
                <!-- @change="CheckPreavviso" -->
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  ref="telefono_ritiro"
                  :background-color="TelefonoRitiroColore"
                  v-show="mostraPreavvisareRitiro"
                  :label="$t('key081')"
                  v-model="TelefonoRitiro"
                  maxlength="15"
                  :required="preavvisoObbligatorio"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  :background-color="emailColore"
                  ref="mail_ritiro"
                  v-show="mostraPreavvisareRitiro"
                  label="Email"
                  v-model="email"
                  maxlength="40"
                  :rules="[(v) => ValidaEmail(v) || $t('key459')]"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <!-- <v-col sm="6"></v-col> -->
            </v-row>
          </v-container>
          <h2 class="mb-2">{{ $t("key383") }}</h2>
          <v-container>
            <v-row>
              <v-col cols="12" sm="2" class="py-1">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="DataRitiro"
                  persistent
                  width="290px"
                  outlined
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="DataRitiro"
                      @click="modal = true"
                      :label="$t('key084')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    v-model="DataRitiro"
                    scrollable
                    :min="minPartenza"
                    :max="maxPartenza"
                    @input="SetData"
                    color="primary"
                  >
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="modal = false">
                      {{ $t("key335") }}
                    </v-btn>
                    <v-btn depressed color="primary" @click="SetData">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="10" class="py-1">
                <h4 style="margin-bottom: -10px">{{ $t("key090") }}</h4>
                <div class="d-flex flex-row">
                  <v-checkbox
                    ref="indicativa"
                    v-model="Indi"
                    :label="$t('key392')"
                  ></v-checkbox>
                  <v-checkbox
                    class="ml-4"
                    v-model="Tass"
                    :label="$t('key391')"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="MDMColore"
                  ref="mdmText"
                  outlined
                  v-model="MDM"
                  :label="$t('key085')"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckMDM"
                  autocomplete="null"
                  maxlength="5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="MAMColore"
                  ref="mamText"
                  outlined
                  v-model="MAM"
                  :label="$t('key086')"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckMAM"
                  autocomplete="null"
                  maxlength="5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="PDPColore"
                  ref="pdpText"
                  outlined
                  v-model="PDP"
                  :label="$t('key087')"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckPDP"
                  @focus="CheckMattino"
                  autocomplete="null"
                  maxlength="5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="PAPColore"
                  ref="papText"
                  outlined
                  v-model="PAP"
                  :label="$t('key088')"
                  @keypress="isIntegerDate($event)"
                  @blur="CheckPAP"
                  autocomplete="null"
                  maxlength="5"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-1">
                <div
                  style="color: red"
                  class="d-flex align-center justify-center"
                >
                  {{ $t("key393") }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="soloMattino"
                  :label="$t('key394')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="soloPomeriggio"
                  :label="$t('key395')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  style="font-weight: bold"
                  v-model="giorniChiusura"
                  :label="$t('key396')"
                ></v-checkbox>
              </v-col>
              <!-- <v-col cols="12" sm="3"> </v-col> -->
            </v-row>
            <v-row v-if="giorniChiusura">
              <v-col row>
                <h3 style="color: black">{{ $t("key021") }}:</h3>
                <v-radio-group v-model="lunedi" class="mt-1">
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                    class="mb-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col row>
                <h3 style="color: black">{{ $t("key022") }}:</h3>
                <v-radio-group v-model="martedi" class="mt-1">
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                    class="mb-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col row>
                <h3 style="color: black">{{ $t("key023") }}:</h3>
                <v-radio-group v-model="mercoledi" class="mt-1">
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                    class="mb-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col row>
                <h3 style="color: black">{{ $t("key024") }}:</h3>
                <v-radio-group v-model="giovedi" class="mt-1">
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                    class="mb-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col row>
                <h3 style="color: black">{{ $t("key025") }}:</h3>
                <v-radio-group v-model="venerdi" class="mt-1">
                  <v-radio
                    v-for="(n, i) in 4"
                    :key="i"
                    :label="Radio[i]"
                    :value="i"
                    class="mb-2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <h2 class="mb-2">{{ $t("key384") }}</h2>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="aperturaLaterale"
                  :label="$t('key398')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="facchinaggioRitiro"
                  :label="$t('key399')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="mezzoPiccolo"
                  :label="$t('key400')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="transpalletRitiro"
                  :label="$t('key401')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="pianoRitiro"
                  :label="$t('key402')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="spondaRitiro"
                  :label="$t('key403')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="mezzoCoibentatoRitiro"
                  :label="$t('key404')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="buonoPresaRitiro"
                  :label="$t('key405')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="verticaleRitiro"
                  :label="$t('key406')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="cintureSicureRitiro"
                  :label="$t('key407')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="flagRitiroContest"
                  :label="$t('key608')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  v-if="flagRitiroContest"
                  :label="$t('key609')"
                  v-model="spedDaRitirare"
                  maxlength="17"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <hr />
              </v-col>
            </v-row>
          </v-container>
          <h2 class="mb-2">{{ $t("key093") }}</h2>
          <v-container>
            <v-row row wrap>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="NumeroColliColore"
                  :label="$t('key094')"
                  v-model="NumeroColli"
                  maxlength="3"
                  outlined
                  @keypress="isInteger($event)"
                  autocomplete="null"
                  :rules="[(v) => CheckInteri(v) || $t('key439')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="NumeroBancaliColore"
                  :label="$t('key096')"
                  v-model="NumeroBancali"
                  maxlength="3"
                  outlined
                  @keypress="isInteger($event)"
                  :rules="[(v) => CheckInteri(v) || $t('key439')]"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="errorePeso"
                  :label="$t('key095')"
                  v-model="PesoMerce"
                  maxlength="7"
                  :rules="[
                    (v) => v >= 0.1 || $t('key589'),
                    (v) => CheckPunti(v) || $t('key462'),
                    (v) =>
                      CheckDecimali(v, 1) || 'Numero di decimali non valido',
                  ]"
                  outlined
                  @keypress="isFloat($event)"
                  autocomplete="null"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="VolumeColore"
                  ref="volume"
                  v-if="
                    obbligoVolume === '1' ||
                    obbligoVolume === 1 ||
                    obbligoVolumeInter === '1' ||
                    obbligoVolumeInter === 1
                  "
                  :label="$t('key097')"
                  v-model="Volume"
                  outlined
                  @keypress="isFloat($event)"
                  @blur="ControllaVolume"
                  :rules="[
                    (v) => CheckPunti(v) || $t('key462'),
                    (v) =>
                      CheckDecimali(v, 2) || 'Numero di decimali non valido',
                  ]"
                  :lazy-validation="true"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-1">
                <h4 v-if="stileAvvisoBancali === 'red'" style="color: red">
                  {{ errorePalletBancali }}
                </h4>
                <h4 v-if="stileAvvisoBancali === 'black'">
                  {{ errorePalletBancali }}
                </h4>
                <v-btn
                  large
                  depressed
                  color="primary"
                  @click="apriDialogMisure"
                  v-if="MisureBancali.length === 0"
                >
                  {{ $t("key409") }}
                </v-btn>
                <v-btn
                  large
                  depressed
                  color="primary"
                  @click="apriDialogMisureModifica"
                  v-if="MisureBancali.length > 0"
                >
                  {{ $t("key432") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="LunghezzaMerceColore"
                  :label="$t('key414')"
                  v-model="LunghezzaMerce"
                  outlined
                  @keypress="isInteger($event)"
                  :rules="[(v) => CheckInteri(v) || $t('key439')]"
                  @blur="ControllaLunghezza"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="py-1">
                <h4 style="margin-bottom: -10px">{{ $t("key415") }}</h4>
                <tr>
                  <td>
                    <v-checkbox
                      style="padding-top: 0px; padding-bottom: 0px"
                      :label="$t('key416')"
                      v-model="lunlar"
                    >
                    </v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      style="padding-top: 0px; padding-bottom: 0px"
                      :label="$t('key115')"
                      v-model="altez"
                    >
                    </v-checkbox>
                  </td>
                </tr>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :background-color="assicurataColore"
                  :label="$t('key417')"
                  v-model="assicurata"
                  maxlength="9"
                  outlined
                  @blur="checkAssicurata()"
                  @keypress="isFloat($event)"
                  :rules="[(v) => CheckPunti(v) || $t('key462')]"
                  autocomplete="null"
                  ref="assicurata"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" class="py-1">
                <v-checkbox
                  v-model="fragile"
                  :label="$t('key229')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="1" class="py-1">
                <v-checkbox v-model="adr" :label="$t('key418')"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" class="py-1">
                <v-checkbox
                  v-model="sovrapponibile"
                  :label="$t('key104')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="py-1">
                <v-text-field
                  :label="$t('key632')"
                  v-model="merce"
                  maxlength="15"
                  outlined
                  autocomplete="null"
                ></v-text-field>
              </v-col>
            </v-row>
            <hr />
          </v-container>
          <h2 class="mb-2">{{ $t("key433") }}</h2>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-model="Monodestinatario"
                  :label="$t('key419')"
                  :readonly="readonlyMono"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-btn
                  :disabled="noRubrica"
                  large
                  depressed
                  color="primary"
                  v-if="Monodestinatario && !disattivaRubrica2"
                  @click="
                    Rubrica = true;
                    RipristinaRubrica();
                  "
                  >{{ $t("key382") }}</v-btn
                >
              </v-col>
              <v-col cols="12" sm="6" class="py-1">
                <v-checkbox
                  v-if="MostraMultidestinatario"
                  v-model="Multidestinatario"
                  :label="$t('key420')"
                  :readonly="readonlyMono"
                ></v-checkbox>
              </v-col>

              <!-- <v-col v-bind:class="'sm'+(12 / divisioneSchermo)" > -->
              <v-col cols="12" :sm="6 / divisioneSchermo" class="py-1">
                <v-autocomplete
                  :background-color="RagioneSocialeDestinatarioColore"
                  v-if="mostraDestinatario"
                  ref="ragione_sociale_consegna"
                  outlined
                  :items="autocompleteConsegna"
                  :search-input.sync="selected"
                  return-object
                  :label="$t('key124')"
                  v-model="RagioneSocialeDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                  :filter="FiltraAutocomplete"
                  @focus="reinizializzaListaAutocompleteConsegna"
                  @blur="AddAutocompleteConsegna()"
                  @keypress="checkInvioRagioneSocialeDestinatario($event)"
                  autocomplete="null"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="6 / divisioneSchermo" class="py-1">
                <v-text-field
                  :background-color="IndirizzoDestinatarioColore"
                  ref="indirizzo_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key125')"
                  v-model="IndirizzoDestinatario"
                  maxlength="40"
                  :readonly="readonlyD"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col
                class="py-1"
                cols="12"
                sm="3"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'N'"
              >
                <v-text-field
                  :background-color="destinoFinaleColore"
                  v-if="
                    divisioneSchermo === 2 && corrispondenteSpeciale === 'N'
                  "
                  outlined
                  :label="$t('key567')"
                  autocomplete="null"
                  maxlength="15"
                  v-model="ragsocDestinoFinale"
                />
              </v-col>

              <v-col
                class="py-1"
                cols="12"
                sm="3"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'N'"
              >
                <v-text-field
                  :background-color="destinoFinaleColore"
                  v-if="
                    divisioneSchermo === 2 && corrispondenteSpeciale === 'N'
                  "
                  outlined
                  :label="$t('key568')"
                  autocomplete="null"
                  maxlength="15"
                  v-model="indirizzoDestinoFinale"
                />
              </v-col>
              <v-col
                cols="12"
                :sm="parseInt(3 / divisioneSchermo)"
                class="py-1"
              >
                <v-select
                  outlined
                  v-if="!mostra && mostraDestinatario"
                  :items="nazioni"
                  v-model="nazione_dest"
                  :readonly="readonlyD"
                  :label="$t('key375')"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                :sm="parseInt(3 / divisioneSchermo)"
                class="py-1"
              >
                <v-text-field
                  :background-color="LocalitaDestinatarioColore"
                  ref="localita_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key126')"
                  v-model="LocalitaDestinatario"
                  maxlength="30"
                  :readonly="readonlyD"
                  @blur="
                    !readonlyD &&
                    (!ProvinciaDestinatarioValida ||
                      ProvinciaDestinatario === '')
                      ? OpenCapparioDestino(LocalitaDestinatario)
                      : CambioFocusLocalitaConsegna()
                  "
                  @keypress="checkInvio_Destino($event, LocalitaDestinatario)"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                :sm="parseInt(3 / divisioneSchermo)"
                class="py-1"
              >
                <v-text-field
                  :background-color="CapDestinatarioColore"
                  ref="cap_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key128')"
                  v-model="CapDestinatario"
                  maxlength="11"
                  :readonly="readonlyD"
                  @blur="
                    (ProvinciaDestinatario === '' ||
                      !ProvinciaDestinatarioValida) &&
                    !readonlyD
                      ? OpenCapparioDestino(CapDestinatario)
                      : CambioFocusCapConsegna()
                  "
                  @keypress="checkInvio_Destino($event, CapDestinatario)"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" class="py-1">
                <v-text-field
                  :background-color="ProvinciaDestinatarioColore"
                  ref="provincia_consegna"
                  outlined
                  v-if="mostraDestinatario"
                  :label="$t('key127')"
                  v-model="ProvinciaDestinatario"
                  maxlength="2"
                  :readonly="readonlyD"
                  @blur="Valida_Consegna()"
                  autocomplete="null"
                ></v-text-field>
              </v-col>
              <v-col cols="12" :sm="2 / divisioneSchermo" class="py-1">
                <v-btn
                  large
                  depressed
                  color="primary"
                  :disabled="readonlyD"
                  v-if="mostraDestinatario"
                  @click="PulisciDestinatario"
                  >{{ $t("key387") }}</v-btn
                >
              </v-col>
              <v-spacer v-if="divisioneSchermo === 2"></v-spacer>
              <v-col
                class="py-1"
                cols="12"
                sm="3"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'N'"
              >
                <v-text-field
                  :background-color="destinoFinaleColore"
                  v-if="divisioneSchermo === 2"
                  outlined
                  :label="$t('key569')"
                  v-model="comuneDestinoFinale"
                  maxlength="20"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="12"
                sm="2"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'N'"
              >
                <v-text-field
                  :background-color="destinoFinaleColore"
                  v-if="divisioneSchermo === 2"
                  outlined
                  :label="$t('key570')"
                  v-model="capDestinoFinale"
                  maxlength="11"
                />
              </v-col>
              <v-col
                class="py-1"
                cols="12"
                sm="1"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'N'"
              >
                <v-text-field
                  :background-color="destinoFinaleColore"
                  v-if="divisioneSchermo === 2"
                  outlined
                  :label="$t('key571')"
                  v-model="provinciaDestinoFinale"
                  maxlength="10"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="d-flex align-center justify-center py_1"
                v-if="!ProvinciaDestinatarioValida"
              >
                <h4 style="color: red">
                  Attenzione: Località, cap e provincia non corrispondono.
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="py-1"
                cols="12"
                sm="3"
                v-if="divisioneSchermo === 2 && corrispondenteSpeciale === 'S'"
              ></v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  autocomplete="null"
                  :background-color="nominativoConsegnaColore"
                  ref="riferimento_consegna"
                  v-if="!mostra && mostraDestinatario"
                  outlined
                  v-model="nominativoConsegna"
                  :label="$t('key079')"
                  maxlength="30"
                />
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  autocomplete="null"
                  v-if="!mostra && mostraDestinatario"
                  v-model="preavvisoConsegna"
                  :label="$t('key080')"
                  @change="preavvisareConsegnaMet"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  autocomplete="null"
                  :background-color="telefonoConsegnaColore"
                  outlined
                  v-if="preavvisoConsegna && !mostra && mostraDestinatario"
                  v-model="telefonoConsegna"
                  :label="$t('key081')"
                  maxlength="20"
                />
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-text-field
                  autocomplete="null"
                  :background-color="mailConsegnaColore"
                  ref="mail_consegna"
                  outlined
                  v-if="preavvisoConsegna && !mostra && mostraDestinatario"
                  v-model="mailConsegna"
                  label="email"
                  maxlength="40"
                  :rules="[(v) => ValidaEmail(v) || $t('key549')]"
                />
              </v-col>
              <!-- </v-col> -->
              <!-- <v-col cols="12" sm="3"> </v-col>
              <v-col cols="12" sm="9"> </v-col> -->

              <v-col cols="10" sm="2" class="py-1">
                <v-text-field
                  autocomplete="null"
                  :background-color="riferimentoRitiroColore"
                  outlined
                  v-model="riferimentoRitiro"
                  :label="$t('key421')"
                  maxlength="17"
                />
              </v-col>
              <v-col cols="2" sm="1" class="py-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                  </template>
                  <span
                    >Questo dato è obbligatorio per i ritiri in porto assegnato
                    e le triangolazioni</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="10" sm="2" class="py-1">
                <v-text-field
                  autocomplete="null"
                  :background-color="rifclienteEsteroColore"
                  v-if="
                    obbligoRifEstero.trim() !== '' ||
                    (nazione_ritiro_selezionata !== 'I' &&
                      nazione_ritiro_selezionata !== 'V') ||
                    (TipoRitiro === 'A' &&
                      (isCorrisEstero.trim() === 'S' ||
                        obbligoRifEstero.trim() !== ''))
                  "
                  outlined
                  v-model="rifclienteEstero"
                  :label="$t('key422')"
                  maxlength="20"
                />
              </v-col>
              <v-col cols="2" sm="1" class="py-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="
                        (nazione_ritiro_selezionata !== 'I' &&
                          nazione_ritiro_selezionata !== 'V') ||
                        (TipoRitiro === 'A' &&
                          (isCorrisEstero.trim() === 'S' ||
                            obbligoRifEstero.trim() !== ''))
                      "
                      outlined
                      >help_outline</v-icon
                    >
                  </template>
                  <span>{{ $t("key463") }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="1" class="py-1"> </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="Monodestinatario && TipoRitiro !== 'A'"
                  v-model="fermoDeposito"
                  :label="$t('key218')"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" sm="3" class="py-1">
                <p
                  v-html="$t('key600')"
                  v-if="
                    divisioneSchermo !== 2 &&
                    corrispondenteSpeciale === 'S' &&
                    isCorrisEstero.trim() === 'S' &&
                    Monodestinatario
                  "
                >
                  {{ $t("key600") }}
                </p>
              </v-col>
              <v-col cols="6" sm="3" class="py-1">
                <v-autocomplete
                  v-if="
                    divisioneSchermo !== 2 &&
                    corrispondenteSpeciale === 'S' &&
                    isCorrisEstero.trim() === 'S' &&
                    Monodestinatario
                  "
                  :background-color="nazioneDestinoFinaleColore"
                  ref="nazione_destino_finale"
                  :label="$t('key375')"
                  :items="NazioniDestinoFinale"
                  v-model="NazioneDestinoFinaleScelta"
                  :search-input.sync="selectedNazione"
                  outlined
                  return-object
                  autocomplete="null"
                  :filter="FiltraAutocomplete"
                >
                </v-autocomplete>
                <!-- <v-select v-if="divisioneSchermo !== 2 && mostraDestinatario" v-model="NazioneDestinoFinaleScelta" :items="NazioniDestinoFinale" label="Nazione" outlined></v-select> -->
              </v-col>
              <v-col cols="12" sm="9" class="py-1"> </v-col>
              <v-col cols="12" class="py-1">
                <h2 v-if="mostraDestinatario">{{ $t("key433") }}</h2>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  style="font-weight: bold"
                  v-if="
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="espresso"
                  :label="$t('key423')"
                  :readonly="inibisciEspresso"
                  @change="
                    !inibisciEspresso && espresso
                      ? (dialogEspresso = true)
                      : (dialogEspresso = false)
                  "
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  style="font-weight: bold"
                  v-if="
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="concordataConsegnaTassativa"
                  :label="$t('key424')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-select
                  outlined
                  v-if="
                    concordataConsegnaTassativa &&
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="tassativaConsegnaSelezionata"
                  v-bind:items="tassativaConsegna"
                  :label="$t('key425')"
                  @change="GetTassativaConsegna"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-dialog
                  v-if="
                    concordataConsegnaTassativa &&
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  ref="dialog2"
                  v-model="dataP"
                  :return-value.sync="data_partenza"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      v-model="data_partenza"
                      :label="$t('key084')"
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      @click="dataP = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="it-IT"
                    @input="SetDataConsegna"
                    v-model="data_partenza"
                    scrollable
                    :min="minPartenzaConsegna"
                    :max="maxPartenzaConsegna"
                  >
                    <v-spacer></v-spacer>
                    <v-btn depressed color="primary" @click="dataP = false">{{
                      $t("key335")
                    }}</v-btn>
                    <v-btn depressed color="primary" @click="SetDataConsegna"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="facchinaggioConsegna"
                  :label="$t('key214')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="mostraDestinatario"
                  v-model="aperturaLateraleConsegna"
                  :label="$t('key426')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="mostraDestinatario"
                  v-model="transpalletConsegna"
                  :label="$t('key427')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="mostraDestinatario"
                  v-model="furgoneConsegna"
                  :label="$t('key428')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="servizioPianoConsegna"
                  :label="$t('key429')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="mostraDestinatario"
                  v-model="spondaConsegna"
                  :label="$t('key430')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1">
                <v-checkbox
                  v-if="
                    mostraDestinatario &&
                    (nazione_destino_selezionata == 'I' ||
                      nazione_destino_selezionata == 'V')
                  "
                  v-model="cantinaConsegna"
                  :label="$t('key431')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" class="py-1"> </v-col>
              <v-col cols="12" class="py-1">
                <hr />
              </v-col>
              <v-col cols="12" class="py-1">
                <div style="color: red">{{ $t("key129") }}</div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn large depressed color="primary" dark outlined @click="Reset">RESET</v-btn>
        <v-btn
          large
          depressed
          color="primary"
          @click="CalcolaDataeOra()"
          :disabled="!validForm || bloccoSalvataggio"
          >{{ $t("key133") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    noRubrica: false,
    dialogEspresso: false,
    dialogRitiroTassativo: false,
    erroreAnagrafica: false,
    flagRitiroContest: false,
    spedDaRitirare: "",
    dataDiOggi: "",
    ore: 0,
    minuti: 0,
    NazioneDestinoFinaleScelta: "",
    NazioneDestinoFinaleScelta: "",
    NazioniDestinoFinale: [],
    corrispondenteSpeciale: "",
    CEE: false,
    EXTRACEE: false,
    MostraMultidestinatario: false,
    messaggioMisure: false,
    link: "",
    destinoFinaleColore: "",
    divisioneSchermo: 1,
    numeroRitiroAggiunto: "",
    messaggioAssicurata: false,
    erroreAssicurata: false,
    mostraPreavvisareRitiro: false,
    RagioneSocialeRitiroColore: "",
    IndirizzoRitiroColore: "",
    LocalitaRitiroColore: "",
    CAPRitiroColore: "",
    ProvinciaRitiroColore: "",
    RiferimentoRitiroColore: "",
    TelefonoRitiroColore: "",
    emailColore: "",
    MDMColore: "",
    MAMColore: "",
    PDPColore: "",
    PAPColore: "",
    NumeroColliColore: "",
    NumeroBancaliColore: "",
    VolumeColore: "",
    LunghezzaMerceColore: "",
    assicurataColore: "",
    RagioneSocialeDestinatarioColore: "",
    IndirizzoDestinatarioColore: "",
    LocalitaDestinatarioColore: "",
    CapDestinatarioColore: "",
    ProvinciaDestinatarioColore: "",
    nominativoConsegnaColore: "",
    telefonoConsegnaColore: "",
    mailConsegnaColore: "",
    riferimentoRitiroColore: "",
    rifclienteEsteroColore: "",
    ProvinciaDestinatarioValida: true,
    ProvinciaValida: true,
    riferimentoConsegna: false,
    riferimentiRitiro: false,
    listaRiferimentiRitiro: [],
    rifer: [],
    riferCon: [],
    listaRiferimentiConsegna: [],
    headersRiferimenti: [
      { text: "Referente", align: "left", value: "ref" },
      { text: "Telefono", align: "left", value: "cell" },
      { text: "Email", align: "left", value: "mail" },
    ],
    stileAvvisoBancali: "red",
    ErroreOrarioMattino: "",
    errorePalletBancali: "",
    tempAutocomplete: {},
    nominativiRubrica: {},
    autocompleteRubrica: [],
    validMisure: false,
    dialogMisure: false,
    tempRagioneSocialeConsegna: "",
    tempRagioneSocialeRitiro: "",
    erroreCalendario: false,
    MisureBancali: [],
    RitiroAggiunto: false,
    snackbar: false,
    text: "",
    lunlar: false,
    altez: false,
    Indi: true,
    Tass: false,
    readonlyMono: false,
    Monodestinatario: false,
    Multidestinatario: false,
    facchinaggioConsegna: false,
    aperturaLateraleConsegna: false,
    transpalletConsegna: false,
    furgoneConsegna: false,
    servizioPianoConsegna: false,
    spondaConsegna: false,
    cantinaConsegna: false,
    lista_comuni_ritiro: [],
    localita_cappario_ritiro: {},
    errorePeso: "",
    DateOK: [],
    menu5: false,
    menu4: false,
    menu3: false,
    menu2: false,
    DialogErroreOrario: false,
    DialogErroreOrarioPAP: false,
    DialogErroreOrarioPDP: false,
    DialogErroreOrarioMAM: false,
    ErroreOrario: "",
    concordataTassativa: false,
    consegnaTassativa: -1,
    obbligoDettaglioMisure: 0,
    salvaRitiro: false,
    salvaConsegna: false,
    idModificaDestinatario: "AZIENDA",
    modificaDestinatario: false,
    idModificaRitiro: "AZIENDA",
    modificaRitiro: false,
    attivaDettagli: false,
    nominativoConsegna: "",
    preavvisoConsegnaObbligatorio: false,
    aperturaLaterale: false,
    facchinaggioRitiro: false,
    mezzoPiccolo: false,
    transpalletRitiro: false,
    pianoRitiro: false,
    spondaRitiro: false,
    mezzoCoibentatoRitiro: false,
    buonoPresaRitiro: false,
    verticaleRitiro: false,
    cintureSicureRitiro: false,
    rifclienteEstero: "",
    dataP: "",
    minPartenzaConsegna: "",
    maxPartenzaConsegna: "",
    data_partenza: "",
    tassativaConsegna_it: [
      { text: "CONSEGNA TASSATIVA ENTRO IL", value: 0 },
      { text: "CONSEGNA TASSATIVA SOLO IL", value: 1 },
      { text: "SCARICO PRENOTATO IL", value: 2 },
      // {text: "CONSEGNA TASSATIVA PER IL", value: 3},
      { text: "CONSEGNA TASSATIVA DOPO IL", value: 4 },
    ],
    tassativaConsegna_en: [
      { text: "DELIVERY NOT LATER THAN", value: 0 },
      { text: "DELIVERY FIX FOR", value: 1 },
      { text: "UNLOADING DAY FIXED FOR", value: 2 },
      // {text: "CONSEGNA TASSATIVA PER IL", value: 3},
      { text: "DELIVERY LATER THAN", value: 4 },
    ],
    tassativaConsegnaSelezionata: { value: -1 },
    concordataConsegnaTassativa: false,
    preavvisoConsegna: false,
    telefonoConsegna: "",
    mailConsegna: "",
    fragile: false,
    misuraScelta: 0,
    misuraSelezionata: { text: "NON IMPOSTATA", value: 0 },
    misuraMaggiore: [
      { text: "NON IMPOSTATA", value: 0 },
      { text: "LUNGHEZZA / LARGHEZZA", value: 1 },
      { text: "ALTEZZA", value: 2 },
    ],
    soloMattino: false,
    soloPomeriggio: false,
    fermoDeposito: false,
    assicurata: "",
    giorniChiusura: false,
    lunedi: 0,
    martedi: 0,
    mercoledi: 0,
    giovedi: 0,
    venerdi: 0,
    Radio_it: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    Radio_en: ["Never", "Morning", "Afternoon", "All day"],
    Radio: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
    email: "",
    selected: "",
    selectedRitiro: "",
    selectedNazione: "",
    mostra: false,
    riferimentoRitiro: "",
    destinoFinale: "",
    riferimentoEstero: "",
    nazione_destino_selezionata: "I",
    nazione_ritiro_selezionata: "I",
    lista_cappario: [],
    nazione: "I",
    nazione_dest: "I",
    nazioni: [],
    dialogNotSave: false,
    notsave: 0,
    validForm: false,
    erroreInserimento: "",
    dialogErrore: false,
    tipo_ritiri_it: [
      { text: "Ritiro presso mia sede (PORTO FRANCO)", value: 1 },
      { text: "Spedizione verso me (PORTO ASSEGNATO)", value: 2 },
      { text: "Ritiro e consegna presso terzi   (TRIANGOLAZIONE)", value: 3 },
    ],
    tipo_ritiri_en: [
      { text: "Pick up at my place (valid for DAP shipments)", value: 1 },
      {
        text: "Pick up in different place (valid for EXWORK shipments)",
        value: 2,
      },
      {
        text: "Pick and delivery to a third party (valid for XTRADE order)",
        value: 3,
      },
    ],
    tipo_ritiri: [],
    RagioneSocialeRitiro: "",
    IndirizzoRitiro: "",
    LocalitaRitiro: "",
    CAPRitiro: "",
    overlay: false,
    RiferimentoRitiro: "",
    TelefonoRitiro: "",
    DataRitiro: "",
    minPartenza: "",
    maxPartenza: "",
    readonlyD: false,
    maxPartenza: "",
    modal: false,
    preavvisare: false,
    MDM: "08:00",
    MAM: "13:00",
    PDP: "13:30",
    PAP: "18:00",
    Tassativo_it: [
      { text: "Data indicativa", value: 1 },
      { text: "Data tassativa", value: 2 },
    ],
    Tassativo_en: [
      { text: "Estimated delivery date", value: 1 },
      { text: "Fix delivery date", value: 2 },
    ],
    Tassativo: [],
    NumeroColli: "",
    Volume: "",
    NumeroBancali: "",
    TipologiaMerce: "",
    TipoServizio_it: [
      { text: "Servizio standard", value: 0 },
      { text: "Servizio espresso", value: 1 },
      { text: "Servizio urgente", value: 2 },
    ],
    TipoServizio_en: [
      { text: "Standard service", value: 0 },
      { text: "Express service", value: 1 },
      { text: "Urgent service", value: 2 },
    ],
    TipoServizio: [],
    adr: false,
    gdo: false,
    sovrapponibile: false,
    sponda: false,
    PesoMerce: "",
    LunghezzaMerce: "",
    Misure: [],
    Destinatario_it: [
      { text: "Selezionare destinatario", value: 0 },
      { text: "Monodestinatario", value: 1 },
      { text: "Multidestinatario", value: 2 },
    ],
    Destinatario_en: [
      { text: "Select one", value: 0 },
      { text: "Single consignee", value: 1 },
      { text: "Multiple consignee", value: 2 },
    ],
    Destinatario: [],
    mostraDestinatario: true,
    RagioneSocialeDestinatario: "",
    IndirizzoDestinatario: "",
    LocalitaDestinatario: "",
    CapDestinatario: "",
    NoteRitiro: "",
    DisposizioniConsegna: "",
    Rubrica: false,
    headers: [
      { text: "Identificativo", align: "left", value: "clientId" },
      { text: "Ragione sociale", align: "left", value: "ragioneSociale" },
      { text: "Indirizzo", align: "left", value: "indirizzo" },
      { text: "Comune", align: "left", value: "comune" },
      { text: "Cap", align: "left", value: "cap" },
      { text: "Provincia", align: "left", value: "provincia" },
      { text: "Telefono", align: "left", value: "telefono" },
    ],
    headersCappario: [
      { text: "Località", align: "left", value: "trploc" },
      { text: "Provincia", align: "left", value: "trpprv" },
      { text: "CAP", align: "left", value: "trpcap" },
    ],
    items: [],
    itemsBase: [],
    preavvisoObbligatorio: false,
    numeroDettagli: 0,
    search: "",
    readonly: false,
    RubricaRitiro: false,
    TipoRitiroSelezionato: 1,
    TassativaSelezionata: { value: 1 },
    TipoServizioSelezionato: { value: 0 },
    MisureSelezionate: { value: 0 },
    Errore: "",
    obbligoVolume: "0",
    headersBancali_it: [
      { text: "Numero colli / bancali", align: "left", value: "pallet" },
      { text: "Lunghezza", align: "left", value: "lunghezza" },
      { text: "Larghezza", align: "left", value: "larghezza" },
      { text: "Altezza", align: "left", value: "altezza" },
      { text: "Azioni", align: "left", value: "" },
    ],
    headersBancali_en: [
      { text: "Pallets number", align: "left", value: "pallet" },
      { text: "Length", align: "left", value: "lunghezza" },
      { text: "Width", align: "left", value: "larghezza" },
      { text: "Height", align: "left", value: "altezza" },
      { text: "Actions", align: "left", value: "" },
    ],
    headersBancali: [],
    DettaglioMisure: false,
    palletInseriti: 0,
    lunghezzaInserita: 0,
    larghezzaInserita: 0,
    altezzaInserita: 0,
    indiceModifica: "",
    modifica: false,
    valid: false,
    MostraDettagli: false,
    totalePallet: 0,
    erroreValidazione: false,
    destinatarioSelezionato: { value: 1 },
    ProvinciaRitiro: "",
    ProvinciaDestinatario: "",
    TipoRitiro: "F",
    Destinatari: 1,
    Tassativa: "I",
    TipoServizioScelto: 0,
    dialogRitiro: false,
    idRitiro: "",
    Cappario: false,
    searchCappario: "",
    searchCapparioDestino: "",
    CapparioDestino: false,
    disattivaRubrica: false,
    disattivaRubrica2: false,
    erroreCappario: false,
    autocomplete: [],
    autocompleteConsegna: [],
    autocompletamento: {},
    espresso: false,
    autocompletamentoStandard: {},
    erroreVolume: "",
    dialogErroreVolume: false,
    inibisciEspresso: false,
    messageEspresso: false,
    stopSubmit: false,
    // Campi aggiunti per i corrispondenti esteri
    // 01/10/2021
    ragsocDestinoFinale: "",
    indirizzoDestinoFinale: "",
    nazioneDestinoFinale: "",
    comuneDestinoFinale: "",
    capDestinoFinale: "",
    provinciaDestinoFinale: "",
    mostraDestinoFinale: false,
    isCorrisEstero: "",
    obbligoRifEstero: "",
    utenteBloccato: false,
    obbligoVolumeInter: "0",
    merce: "",
    dialogFattu: false,
    dialogText: "",
    bloccoSalvataggio: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
    this.$store.dispatch("getCodiciValidi", "ritiri");
    this.overlay = true;
    this.nazione = "I";
    this.nazione_ritiro_selezionata = "I";
    this.nazione_dest = "I";
    this.nazione_destino_selezionata = "I";

    // this.axios.get(this.$store.state.servicePath + "/api/Wbrit10f/CheckBloccato")
    // .then(res=>{
    //   if(res.data === 'S'){
    //     this.utenteBloccato = true;
    //   }
    //     else{
    this.axios
      .get(
        this.$store.state.servicePath + "/api/ArGruppiUtentis/GetGruppoUtente"
      )
      .then((res) => {
        if (res.data == 7) {
          this.noRubrica = true;
        }
        this.axios
          .get(this.$store.state.servicePath + "/api/ArCorrispondentis")
          .then((res) => {
            this.corrispondenteSpeciale = res.data;
            this.axios
              .get(this.$store.state.servicePath + "/api/Wbrit10f/Corris")
              .then((res) => {
                this.isCorrisEstero = res.data;
                this.axios
                  .get(
                    this.$store.state.servicePath +
                      "/api/Wbrit10f/ObbligoRifFat"
                  )
                  .then((res) => {
                    this.obbligoRifEstero = res.data;
                    this.GetNazioni();
                    this.GetTipoRitiro(1);
                    if (this.$store.state.codiciDaVisualizzare.length > 0) {
                      this.ControlloCodiceFatt(
                        this.$store.state.MasterSelezionato.value
                      );
                    } else {
                      this.ControlloCodiceFatt(
                        this.$store.state.CodiceOriginale
                      );
                    }
                  })
                  .catch((err) => {
                    this.erroreInserimento = "";
                    this.overlay = false;
                    this.dialogErrore = true;
                    this.erroreInserimento = this.$t("key506");
                    //this.erroreInserimento = "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
                  })
                  .catch((err) => {
                    this.erroreInserimento = "";
                    this.overlay = false;
                    this.dialogErrore = true;
                    this.erroreInserimento = this.$t("key506");
                  });
              });
          })
          .catch((err) => {
            this.erroreInserimento = "";
            this.overlay = false;
            this.dialogErrore = true;
            this.erroreInserimento = this.$t("key506");
          });
      })
      .catch((err) => {
        this.erroreInserimento = "";
        this.overlay = false;
        this.dialogErrore = true;
        this.erroreInserimento = this.$t("key506");
      });
    //   }
    // })
  },
  mounted() {
    this.CheckJWT();
    this.nazione_ritiro_selezionata = "I";
    this.nazione_destino_selezionata = "I";
    if (this.axios.defaults.headers.common["Language"] == "it-IT") {
      this.tassativaConsegna = this.tassativaConsegna_it;
      this.Radio = this.Radio_it;
      this.tipo_ritiri = this.tipo_ritiri_it;
      this.Tassativo = this.Tassativo_it;
      this.TipoServizio = this.TipoServizio_it;
      this.Misure = this.Misure_it;
      this.Destinatario = this.Destinatario_it;
      this.headersBancali = this.headersBancali_it;
      this.NazioneDestinoFinaleScelta = "";
    } else {
      this.Radio = this.Radio_en;
      this.tassativaConsegna = this.tassativaConsegna_en;
      this.tipo_ritiri = this.tipo_ritiri_en;
      this.Tassativo = this.Tassativo_en;
      this.TipoServizio = this.TipoServizio_en;
      this.Misure = this.Misure_en;
      this.Destinatario = this.Destinatario_en;
      this.headersBancali = this.headersBancali_en;
      this.NazioneDestinoFinaleScelta = "";
    }
    this.axios
      .get(this.$store.state.servicePath + "/api/Wbrit10f/GetDataOggi")
      .then((res) => {
        this.dataDiOggi = res.data.toString().substring(0, 8);
        this.ore = res.data.toString().substring(8, 10);
        this.minutes = res.data.toString().substring(10, 12);
      })
      .catch((err) => {
        var fil = "00";
        var giorno =
          fil.substring(
            0,
            fil.length - new Date().getUTCDate().toString().length
          ) + new Date().getUTCDate().toString();
        var mese =
          fil.substring(
            0,
            fil.length - (new Date().getUTCMonth() + 1).toString().length
          ) + (new Date().getUTCMonth() + 1).toString();
        this.dataDiOggi = new Date().getFullYear().toString() + mese + giorno;
        this.ore = new Date().getHours().toLocaleString("it-IT");
        this.minutes = new Date().getMinutes().toLocaleString("it-IT");
      });
    //
    // if (Number(ore) > 12 || (Number(ore) === 12 && Number(minutes) > 30)) {
    //   this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
    //     this.minPartenza = this.addDays(new Date(), 0)
    //     .toISOString()
    //     .substr(0, 10);
    //     this.minPartenzaConsegna = this.addDays(new Date(), 2)
    //     .toISOString()
    //     .substr(0, 10);
    //   }
    //  else {
    this.minPartenza = this.addDays(new Date(), 0).toISOString().substr(0, 10);
    this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
    this.minPartenzaConsegna = this.addDays(new Date(), 1)
      .toISOString()
      .substr(0, 10);
    // }
    this.maxPartenza = this.addDays(new Date(), 30).toISOString().substr(0, 10);
    this.maxPartenzaConsegna = this.addDays(new Date(), 30)
      .toISOString()
      .substr(0, 10);
  },
  methods: {
    close() {
      window.location.href = "https://clienti.arcologistica.it/";
    },
    RicreaPagina() {
      this.$store.dispatch("ImpostaScollegamento");
      this.$refs.Ritiri.resetValidation();
      this.TipoRitiroSelezionato = 1;
      this.mostraDestinatario = false;
      this.disattivaRubrica2 = true;
      this.TipoRitiro = "F";
      this.nazione = "I";
      this.nazione_ritiro_selezionata = "I";
      this.nazione_dest = "I";
      this.nazione_destino_selezionata = "I";
      this.merce = "";
      this.axios
        .get(this.$store.state.servicePath + "/api/ArCorrispondentis")
        .then((res) => {
          this.corrispondenteSpeciale = res.data;
          this.axios
            .get(this.$store.state.servicePath + "/api/Wbrit10f/Corris")
            .then((res) => {
              this.isCorrisEstero = res.data;
              this.axios
                .get(
                  this.$store.state.servicePath + "/api/Wbrit10f/ObbligoRifFat"
                )
                .then((res) => {
                  this.obbligoRifEstero = res.data;
                  this.GetNazioni();
                  this.GetTipoRitiro(1);
                  this.ControlloCodiceFatt(this.$store.state.MasterSelezionato);
                })
                .catch((err) => {
                  this.erroreInserimento = "";
                  this.overlay = false;
                  this.dialogErrore = true;
                  this.erroreInserimento = this.$t("key506");
                  //this.erroreInserimento = "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
                })
                .catch((err) => {
                  this.erroreInserimento = "";
                  this.overlay = false;
                  this.dialogErrore = true;
                  this.erroreInserimento = this.$t("key506");
                });
            });
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
      this.CheckJWT();
      this.nazione_ritiro_selezionata = "I";
      this.nazione_destino_selezionata = "I";
      if (this.axios.defaults.headers.common["Language"] == "it-IT") {
        this.tassativaConsegna = this.tassativaConsegna_it;
        this.Radio = this.Radio_it;
        this.tipo_ritiri = this.tipo_ritiri_it;
        this.Tassativo = this.Tassativo_it;
        this.TipoServizio = this.TipoServizio_it;
        this.Misure = this.Misure_it;
        this.Destinatario = this.Destinatario_it;
        this.headersBancali = this.headersBancali_it;
        this.NazioneDestinoFinaleScelta = "";
      } else {
        this.Radio = this.Radio_en;
        this.tassativaConsegna = this.tassativaConsegna_en;
        this.tipo_ritiri = this.tipo_ritiri_en;
        this.Tassativo = this.Tassativo_en;
        this.TipoServizio = this.TipoServizio_en;
        this.Misure = this.Misure_en;
        this.Destinatario = this.Destinatario_en;
        this.headersBancali = this.headersBancali_en;
        this.NazioneDestinoFinaleScelta = "";
      }
      this.axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/GetDataOggi")
        .then((res) => {
          this.dataDiOggi = res.data.toString().substring(0, 8);
          this.ore = res.data.toString().substring(8, 10);
          this.minutes = res.data.toString().substring(10, 12);
        })
        .catch((err) => {
          var fil = "00";
          var giorno =
            fil.substring(
              0,
              fil.length - new Date().getUTCDate().toString().length
            ) + new Date().getUTCDate().toString();
          var mese =
            fil.substring(
              0,
              fil.length - (new Date().getUTCMonth() + 1).toString().length
            ) + (new Date().getUTCMonth() + 1).toString();
          this.dataDiOggi = new Date().getFullYear().toString() + mese + giorno;
          this.ore = new Date().getHours().toLocaleString("it-IT");
          this.minutes = new Date().getMinutes().toLocaleString("it-IT");
        });
      this.minPartenza = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
      this.minPartenzaConsegna = this.addDays(new Date(), 1)
        .toISOString()
        .substr(0, 10);
      // }
      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.maxPartenzaConsegna = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
    },
    ControlloCodiceFatt(codice) {
      this.overlay = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/Ttrcd10f/Fattu/" + codice)
        .then((res) => {
          this.dialogText = "";
          this.overlay = false;
          if (res.data.trim() == "FATTU") {
            if (this.$root.$i18n.locale == "it") {
              this.dialogText = `Non è possibile inserire un RITIRO con il codice ${codice} in quanto risulta essere di Fatturazione e non Operativo.`;
              if (this.$store.state.selectCodici.length > 0) {
                this.dialogText +=
                  " Si prega di selezionare un codice diverso.";
              }
            } else {
              this.dialogText = `It is not possible to enter a PICK UP ORDER with the code ${codice} as it is Billing and not Operational.`;
              if (this.$store.state.selectCodici.length > 0) {
                this.dialogText += " Please select a different code.";
              }
            }
            this.dialogFattu = true;
            this.bloccoSalvataggio = true;
          } else {
            this.bloccoSalvataggio = false;
            this.overlay = false;
          }
        })
        .catch(() => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.erroreInserimento = this.$t("key630");
          this.dialogErrore = true;
        });
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          // localStorage.setItem("jwt", res.data);
          this.RicreaPagina();
          //this.overlay = false;
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },

    ChiudiErroreDettaglio() {
      this.messaggioMisure = false;
      this.MisureBancali = [];
      this.dialogMisure = false;
      this.Volume = 0;
      this.misuraScelta = 0;
      this.lunlar = false;
      this.altez = false;
      this.errorePalletBancali = "";
      this.stileAvvisoBancali = "black";
      this.LunghezzaMerce = 0;
    },
    checkAssicurata() {
      var check = 0;
      if (
        this.assicurata === undefined ||
        this.assicurata === null ||
        this.assicurata === "" ||
        isNaN(Number(this.assicurata))
      ) {
        check = 0;
      } else {
        check = this.assicurata;
      }
      if (Number(check) > 9999999) {
        this.erroreAssicurata = true;
      } else if (Number(check) > 200000) {
        this.messaggioAssicurata = true;
      }
    },
    CheckInteri(stringa) {
      var regexNum = /[^0-99]/g;
      var result = stringa.toString().match(regexNum);
      if (result !== null) {
        return false;
      } else {
        return true;
      }
    },
    ApriManuale() {
      var link = "";
      if (this.axios.defaults.headers.common["Language"] == "it-IT") {
        if (this.isCorrisEstero.trim() === "S") {
          link =
            "http://clienti.arcologistica.it/download/nuoviritiri_corrispondenti.pdf";
        } else {
          link = "http://clienti.arcologistica.it/download/nuoviritiri.pdf";
        }
      } else {
        if (this.isCorrisEstero.trim() === "S") {
          link =
            "http://clienti.arcologistica.it/download/nuoviritiri_corrispondenti_en.pdf";
        } else {
          link = "http://clienti.arcologistica.it/download/nuoviritiri_en.pdf";
        }
      }
      window.open(link);
    },
    CheckEspresso() {
      if (this.dataP !== "" && this.tassativaConsegnaSelezionata.value !== 4) {
        var ConsegnaEsclusiva = "T";
        if (this.soloMattino) ConsegnaEsclusiva = "M";
        else if (this.soloPomeriggio) ConsegnaEsclusiva = "P";
        var lunghezzaAltezza = "H";
        if (this.lunlar) lunghezzaAltezza = "L";
        var json_ritiro = {
          r1detr: this.TipoRitiro,
          r1rsmi: this.RagioneSocialeRitiro.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inmi: this.IndirizzoRitiro.replaceAll("'", "`").substr(0, 40),
          r1lcmi: this.LocalitaRitiro.replaceAll("'", "`").substr(0, 30),
          r1cpmi: this.CAPRitiro,
          r1prmi: this.ProvinciaRitiro,
          r1nzmi: this.nazione_ritiro_selezionata,
          r1rimi: this.RiferimentoRitiro,
          r1ptmi: this.preavvisare ? 1 : 0,
          r1tpmi: this.TelefonoRitiro,
          r1epmi: this.email,
          r1dtri: this.DataRitiro.replaceAll("-", ""),
          r1ttri: this.Indi ? "I" : "T",
          r1oima: this.MDM.replace(":", ""),
          r1ofma: this.MAM.replace(":", ""),
          r1oipm: this.PDP.replace(":", ""),
          r1ofpm: this.PAP.replace(":", ""),
          r1pgri: ConsegnaEsclusiva,
          r1gclu: this.lunedi,
          r1gcma: this.martedi,
          r1gcme: this.mercoledi,
          r1gcgi: this.giovedi,
          r1gcve: this.venerdi,
          r1nuco: 0,
          r1nuba: 0,
          r1peto: 0,
          r1assi: 0,
          r1frag: this.fragile ? 1 : 0,
          r1radr: this.adr ? 1 : 0,
          r1nsov: this.sovrapponibile ? 1 : 0,
          r1l200: 0,
          r1mmag: lunghezzaAltezza,
          r1tdes: this.Monodestinatario ? 1 : 2,
          r1rsde: this.RagioneSocialeDestinatario.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inde: this.IndirizzoDestinatario.replaceAll("'", "`").substr(0, 40),
          r1lcde: this.LocalitaDestinatario.replaceAll("'", "`").substr(0, 30),
          r1cpde: this.CapDestinatario,
          r1prde: this.ProvinciaDestinatario,
          r1nzde: this.nazione_destino_selezionata,
          r1ride: this.nominativoConsegna,
          r1ptde: this.preavvisoConsegna ? 1 : 0,
          r1tpde: this.telefonoConsegna,
          r1epde: this.mailConsegna,
          r1fede: this.fermoDeposito ? 1 : 0,
          r1rrit: this.riferimentoRitiro,
          r1rcle: this.rifclienteEstero,
          r1tcon: this.concordataTassativa ? 1 : 0,
          r1tdco: this.consegnaTassativa,
          r1dtco:
            this.data_partenza != null && this.data_partenza != ""
              ? this.data_partenza.replaceAll("-", "")
              : 0,
          r1volm: this.Volume,
        };
        this.Errore = "";
        var Disposizioni = [];
        Disposizioni.push({ r3tdis: 0, r3cdis: "  " });
        var json = {
          ritiro: json_ritiro,
          misure: [],
          disposizioni: Disposizioni,
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/Wbrit10f/tempiResa", json)
          .then((res) => {
            if (!res.data.includes("ESPRESSO")) {
              this.espresso = false;
              this.inibisciEspresso = true;
              this.messageEspresso = true;
            } else {
              this.inibisciEspresso = false;
            }
          });
      } else {
        this.inibisciEspresso = false;
      }
    },
    ControllaLunghezza() {
      var tempMaggiore = 0;
      var tempMisura = 0;
      this.erroreVolume = "";
      if (this.MisureBancali.length !== 0) {
        this.MisureBancali.forEach((el) => {
          if (Number(el.lunghezza) > tempMaggiore) {
            tempMaggiore = Number(el.lunghezza);
            tempMisura = 1;
          }
          if (Number(el.larghezza) > tempMaggiore) {
            tempMaggiore = Number(el.larghezza);
            tempMisura = 1;
          }
          if (Number(el.altezza) > tempMaggiore) {
            tempMaggiore = Number(el.altezza);
            tempMisura = 2;
          }
        });
      }
      if (tempMaggiore > this.LunghezzaMerce) {
        //this.erroreVolume = "La lunghezza merce inserita non può essere minore rispetto a quanto indicato nel dettaglio misure. Verrà ripristinato il valore precedentemente calcolato. (0 se minore di 200cm.)<br>";
        this.erroreVolume = this.$t("key465");
        this.dialogErroreVolume = true;
        if (tempMaggiore >= 200) {
          this.LunghezzaMerce = tempMaggiore;
          if (tempMisura === 1) {
            this.lunlar = true;
            this.altez = false;
          }
          if (tempMisura === 2) {
            this.lunlar = false;
            this.altez = true;
          }
        } else {
          this.LunghezzaMerce = 0;
        }
      }
    },
    ControllaVolume() {
      this.erroreVolume = "";
      var vol = 0;
      if (this.MisureBancali.length !== 0) {
        this.MisureBancali.forEach((el) => {
          vol +=
            Number(el.numeroPallet) *
            Number(el.lunghezza) *
            Number(el.larghezza) *
            Number(el.altezza);
        });
        var volumeMisure = vol / 1000000;
        // if(volumeMisure < 0.01 && volumeMisure !== 0)
        //   volumeMisure = 0.01;
        volumeMisure = Math.round(volumeMisure * 100) / 100;
        if (volumeMisure > this.Volume) {
          this.erroreVolume = this.$t("key466");
          this.Volume = volumeMisure;
          this.dialogErroreVolume = true;
        }
      }
    },
    ValidaEmail(email) {
      if (email === "") return true;
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail = email.trim().match(regexEmail);
      if (resultMail === null) {
        return false;
      } else {
        return true;
      }
    },
    logout() {
      this.dialogErrore = false;
      // window.location.href = "/#/";
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    CambioFocusLocalitaConsegna() {
      this.$refs.provincia_consegna.focus();
      this.$refs.cap_consegna.focus();
    },
    CambioFocusCapConsegna() {
      this.$refs.provincia_consegna.focus();
      this.$ref.cap_consegna.focus();
      this.$refs.provincia_consegna.focus();
    },
    CambioFocusLocalitaRitiro() {
      this.$refs.provincia_ritiro.focus();
      this.$refs.cap_ritiro.focus();
    },
    CambioFocusCapRitiro() {
      this.$refs.provincia_ritiro.focus();
      this.$refs.riferimento_ritiro.focus();
      this.$refs.provincia_ritiro.focus();
    },
    RipristinaRubrica() {
      this.search = "";
      this.items = [];
      this.itemsBase.forEach((el) => {
        this.items.push(el);
      });
    },
    customFilter() {
      if (this.search !== "") {
        this.items = [];
        this.itemsBase.forEach((el) => {
          if (
            el.ragioneSociale
              .toLocaleLowerCase()
              .startsWith(this.search.toLocaleLowerCase())
          ) {
            this.items.push(el);
          }
        });
      } else {
        this.items = [];
        this.itemsBase.forEach((el) => {
          this.items.push(el);
        });
      }
    },
    selezionaRiferimentoConsegna(item) {
      this.nominativoConsegna = item ? item.ref : "";
      this.telefonoConsegna = item ? item.cell : "";
      this.mailConsegna = item ? item.mail : "";
      this.riferimentoConsegna = false;
      this.$refs.mail_consegna.focus();
      this.$refs.mail_consegna.blur();
    },
    selezionaRiferimentoRitiro(item) {
      this.RiferimentoRitiro = item ? item.ref : "";
      this.TelefonoRitiro = item ? item.cell : "";
      this.email = item ? item.mail : "";
      this.FocusMail();
      this.riferimentiRitiro = false;
    },
    CalcolaDataeOra() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/GetDataOggi")
        .then((res) => {
          this.dataDiOggi = res.data.toString().substring(0, 8);
          this.ore = res.data.toString().substring(8, 10);
          this.minutes = res.data.toString().substring(10, 12);
          //this.ControllaCodice();
          //this.NormalizzaNumerici();
          this.ControllaAnagrafica();
        })
        .catch((err) => {
          var fil = "00";
          var giorno =
            fil.substring(
              0,
              fil.length - new Date().getUTCDate().toString().length
            ) + new Date().getUTCDate().toString();
          var mese =
            fil.substring(
              0,
              fil.length - (new Date().getUTCMonth() + 1).toString().length
            ) + (new Date().getUTCMonth() + 1).toString();
          this.dataDiOggi = new Date().getFullYear().toString() + mese + giorno;
          this.ore = new Date().getHours().toLocaleString("it-IT");
          this.minutes = new Date().getMinutes().toLocaleString("it-IT");
          //this.ControllaCodice();
          //this.NormalizzaNumerici();
          this.ControllaAnagrafica();
        });
    },
    ControllaAnagrafica() {
      //14-04-2022 Aggiunto controllo per ritiri in porto franco e assegnato con anagrafica
      //Normalizzo i campi che devo confrontare
      if (this.CAPRitiro === null || this.CAPRitiro === undefined) {
        this.CAPRitiro = "";
      }
      if (this.LocalitaRitiro === null || this.LocalitaRitiro === undefined) {
        this.LocalitaRitiro = "";
      }
      if (this.IndirizzoRitiro === null || this.IndirizzoRitiro === undefined) {
        this.IndirizzoRitiro = "";
      }
      if (this.ProvinciaRitiro === null || this.ProvinciaRitiro === undefined) {
        this.ProvinciaRitiro = "";
      }
      if (
        this.RagioneSocialeRitiro.text === null ||
        this.RagioneSocialeRitiro.text === undefined
      ) {
        this.RagioneSocialeRitiro.text = "";
      }
      if (this.CapDestinatario === null || this.CapDestinatario === undefined) {
        this.CapDestinatario = "";
      }
      if (
        this.LocalitaDestinatario === null ||
        this.LocalitaDestinatario === undefined
      ) {
        this.LocalitaDestinatario = "";
      }
      if (
        this.IndirizzoDestinatario === null ||
        this.IndirizzoDestinatario === undefined
      ) {
        this.IndirizzoDestinatario = "";
      }
      if (
        this.ProvinciaDestinatario === null ||
        this.ProvinciaDestinatario === undefined
      ) {
        this.ProvinciaDestinatario = "";
      }
      if (
        this.RagioneSocialeDestinatario.text === null ||
        this.RagioneSocialeDestinatario.text === undefined
      ) {
        this.RagioneSocialeDestinatario.text = "";
      }
      //faccio le chiamate ai servizi per i controlli
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArGestioneUtenzas/GetAnagrafica"
        )
        .then((res) => {
          if (this.TipoRitiro === "F") {
            if (
              !(
                this.CAPRitiro.trim() === res.data.trccap.trim() &&
                this.LocalitaRitiro.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcloc.trim().toUpperCase().replaceAll("'", "`") &&
                this.IndirizzoRitiro.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcind.trim().toUpperCase().replaceAll("'", "`") &&
                this.ProvinciaRitiro.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcprv.trim().toUpperCase().replaceAll("'", "`") &&
                this.RagioneSocialeRitiro.text
                  .trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcrgs.trim().toUpperCase().replaceAll("'", "`")
              )
            ) {
              this.overlay = false;
              this.erroreAnagrafica = true;
            } else {
              this.NormalizzaNumerici();
            }
          } else if (this.TipoRitiro === "A") {
            if (
              !(
                this.CapDestinatario.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trccap.trim().toUpperCase().replaceAll("'", "`") &&
                this.LocalitaDestinatario.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcloc.trim().toUpperCase().replaceAll("'", "`") &&
                this.IndirizzoDestinatario.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcind.trim().toUpperCase().replaceAll("'", "`") &&
                this.ProvinciaDestinatario.trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcprv.trim().toUpperCase().replaceAll("'", "`") &&
                this.RagioneSocialeDestinatario.text
                  .trim()
                  .toUpperCase()
                  .replaceAll("'", "`") ===
                  res.data.trcrgs.trim().toUpperCase().replaceAll("'", "`")
              )
            ) {
              this.overlay = false;
              this.erroreAnagrafica = true;
            } else {
              this.NormalizzaNumerici();
            }
          } else {
            this.NormalizzaNumerici();
          }
        })
        .catch(() => {
          this.NormalizzaNumerici();
        });
    },
    ControllaCodice() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/CheckBloccato")
        .then((res) => {
          if (res.data === "S") {
            this.utenteBloccato = true;
          } else {
            this.NormalizzaNumerici();
          }
        });
    },
    NormalizzaNumerici() {
      if (!this.stopSubmit) {
        this.stopSubmit = true;
      } else {
        return;
      }
      if (
        this.assicurata === null ||
        this.assicurata === undefined ||
        this.assicurata === "" ||
        isNaN(Number(this.assicurata))
      ) {
        this.assicurata = 0;
      } else {
        this.assicurata = Number(this.assicurata);
      }
      if (
        this.NumeroColli === null ||
        this.NumeroColli === undefined ||
        this.NumeroColli === ""
      ) {
        this.NumeroColli = 0;
      } else {
        this.NumeroColli = Number(this.NumeroColli);
      }
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === "" ||
        this.Volume === NaN
      ) {
        this.Volume = 0;
      } else {
        this.Volume = Number(this.Volume);
        if (isNaN(this.Volume)) {
          this.Volume = 0;
        }
      }
      if (
        this.NumeroBancali === null ||
        this.NumeroBancali === undefined ||
        this.NumeroBancali === ""
      ) {
        this.NumeroBancali = 0;
      } else {
        this.NumeroBancali = Number(this.NumeroBancali);
      }
      if (
        this.PesoMerce === null ||
        this.PesoMerce === undefined ||
        this.PesoMerce === ""
      ) {
        this.PesoMerce = 0;
      } else {
        this.PesoMerce = Number(this.PesoMerce);
      }
      if (
        this.LunghezzaMerce === null ||
        this.LunghezzaMerce === undefined ||
        this.LunghezzaMerce === ""
      ) {
        this.LunghezzaMerce = 0;
      } else {
        this.LunghezzaMerce = Number(this.LunghezzaMerce);
      }
      this.AzzeraColoreCampi();
    },
    AzzeraColoreCampi() {
      this.destinoFinaleColore = "";
      this.RagioneSocialeRitiroColore = "";
      this.IndirizzoRitiroColore = "";
      this.LocalitaRitiroColore = "";
      this.CAPRitiroColore = "";
      this.ProvinciaRitiroColore = "";
      this.RiferimentoRitiroColore = "";
      this.TelefonoRitiroColore = "";
      this.emailColore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      this.NumeroColliColore = "";
      this.NumeroBancaliColore = "";
      this.errorePeso = "";
      this.VolumeColore = "";
      this.LunghezzaMerceColore = "";
      this.assicurataColore = "";
      this.RagioneSocialeDestinatarioColore = "";
      this.IndirizzoDestinatarioColore = "";
      this.LocalitaDestinatarioColore = "";
      this.CapDestinatarioColore = "";
      this.ProvinciaDestinatarioColore = "";
      this.nominativoConsegnaColore = "";
      this.telefonoConsegnaColore = "";
      this.mailConsegnaColore = "";
      this.riferimentoRitiroColore = "";
      this.rifclienteEsteroColore = "";
      this.nazioneDestinoFinaleColore = "";
      this.Validate();
    },
    reinizializzaListaAutocomplete() {
      this.autocomplete = [];
      if (!this.readonly) {
        this.tempRagioneSocialeRitiro = "";
      }
      this.autocompleteRubrica.forEach((item) => {
        this.autocomplete.push({ text: item.text, value: item.value });
      });
    },
    reinizializzaListaAutocompleteConsegna() {
      this.autocompleteConsegna = [];
      if (!this.readonlyD) {
        this.tempRagioneSocialeConsegna = "";
      }
      this.autocompleteRubrica.forEach((item) => {
        this.autocompleteConsegna.push({ text: item.text, value: item.value });
      });
    },
    FiltraAutocomplete(item, queryText, itemText) {
      return itemText
        .toLocaleLowerCase()
        .startsWith(queryText.toLocaleLowerCase());
    },
    checkTotalePallet() {
      this.errorePalletBancali = "";
      this.dialogMisure = false;
      var totalePallet = 0;
      this.MisureBancali.forEach((el) => {
        if (
          el.lunghezza === null ||
          el.lunghezza === undefined ||
          el.lunghezza === ""
        )
          el.lunghezza = 0;
        if (
          el.larghezza === null ||
          el.larghezza === undefined ||
          el.larghezza === ""
        )
          el.larghezza = 0;
        if (
          el.altezza === null ||
          el.altezza === undefined ||
          el.altezza === ""
        )
          el.altezza = 0;
        if (
          el.numeroPallet === null ||
          el.numeroPallet === undefined ||
          el.numeroPallet === ""
        )
          el.numeroPallet = 0;
        if (
          Number(el.numeroPallet) === 0 &&
          Number(el.lunghezza) === 0 &&
          Number(el.larghezza) === 0 &&
          Number(el.altezza) === 0
        ) {
          var index = this.MisureBancali.indexOf(el);
          this.MisureBancali.splice(index);
        }
        totalePallet += Number(el.numeroPallet);
      });
      if (
        totalePallet !== 0 &&
        totalePallet > Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali = this.$t("key467");
        this.stileAvvisoBancali = "red";
      } else if (
        totalePallet !== 0 &&
        totalePallet < Number(this.NumeroColli) + Number(this.NumeroBancali)
      ) {
        this.errorePalletBancali = this.$t("key468");
        this.stileAvvisoBancali = "black";
      }
    },
    chiudiDialogMisure() {
      var vol = 0;
      this.MisureBancali.forEach((el) => {
        vol +=
          Number(el.numeroPallet) *
          Number(el.lunghezza) *
          Number(el.larghezza) *
          Number(el.altezza);
      });
      var volume = vol / 1000000;
      volume = Math.round(volume * 100) / 100;
      if (
        (Number(volume) > 0.01 && Number(volume) <= 99) ||
        Number(vol) === 0
      ) {
        this.MisureBancali.forEach((el) => {
          if (
            Number(el.numeroPallet) === 0 &&
            Number(el.lunghezza) === 0 &&
            Number(el.larghezza) === 0 &&
            Number(el.altezza) === 0
          ) {
            var index = this.MisureBancali.indexOf(el);
            this.MisureBancali.splice(index);
          }
          this.ControlloMisure();
          this.errorePalletBancali = "";
          var totalePallet = 0;
          this.MisureBancali.forEach((el) => {
            if (
              el.lunghezza === null ||
              el.lunghezza === undefined ||
              el.lunghezza === ""
            )
              el.lunghezza = 0;
            if (
              el.larghezza === null ||
              el.larghezza === undefined ||
              el.larghezza === ""
            )
              el.larghezza = 0;
            if (
              el.altezza === null ||
              el.altezza === undefined ||
              el.altezza === ""
            )
              el.altezza = 0;
            if (
              el.numeroPallet === null ||
              el.numeroPallet === undefined ||
              el.numeroPallet === ""
            )
              el.numeroPallet = 0;
            totalePallet += Number(el.numeroPallet);
          });
          if (
            totalePallet !== 0 &&
            totalePallet > Number(this.NumeroColli) + Number(this.NumeroBancali)
          ) {
            this.errorePalletBancali = this.$t("key467");
            this.stileAvvisoBancali = "red";
          } else if (
            totalePallet !== 0 &&
            totalePallet < Number(this.NumeroColli) + Number(this.NumeroBancali)
          ) {
            this.errorePalletBancali = this.$t("key468");
            this.stileAvvisoBancali = "black";
          }
          this.dialogMisure = false;
        });
      } else {
        this.messaggioMisure = true;
      }
    },
    apriDialogMisureModifica() {
      if (this.MisureBancali.length < 4) {
        var lunghezza = this.MisureBancali.length;
        for (var i = 0; i < 3 - lunghezza; i++) {
          this.MisureBancali.push({
            numeroPallet: "",
            lunghezza: "",
            larghezza: "",
            altezza: "",
          });
        }
      }
      this.dialogMisure = true;
      this.validMisure = true;
    },
    apriDialogMisure() {
      for (var i = 0; i < 3; i++) {
        this.MisureBancali.push({
          numeroPallet: "",
          lunghezza: "",
          larghezza: "",
          altezza: "",
        });
      }
      this.dialogMisure = true;
    },
    PulisciRitiro() {
      this.LocalitaRitiro = "";
      this.CAPRitiro = "";
      this.ProvinciaRitiro = "";
      this.RagioneSocialeRitiro = "";
      this.IndirizzoRitiro = "";
      this.nazione_ritiro_selezionata = "I";
      this.nazione = { value: "I" };
      this.RiferimentoRitiro = "";
      this.TelefonoRitiro = "";
      this.email = "";
      this.preavvisare = false;
      this.preavvisoObbligatorio = false;
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.giorniChiusura = false;
      this.soloMattino = false;
      this.soloPomeriggio = false;
      this.Tass = false;
      this.Indi = true;
      this.MDM = "08:00";
      this.MAM = "13:00";
      this.PDP = "13:30";
      this.PAP = "18:00";
      this.axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/GetDataOggi")
        .then((res) => {
          this.dataDiOggi = res.data.toString().substring(0, 8);
          this.ore = res.data.toString().substring(8, 10);
          this.minutes = res.data.toString().substring(10, 12);
        })
        .catch((err) => {
          var fil = "00";
          var giorno =
            fil.substring(
              0,
              fil.length - new Date().getUTCDate().toString().length
            ) + new Date().getUTCDate().toString();
          var mese =
            fil.substring(
              0,
              fil.length - (new Date().getUTCMonth() + 1).toString().length
            ) + (new Date().getUTCMonth() + 1).toString();
          this.dataDiOggi = new Date().getFullYear().toString() + mese + giorno;
          this.ore = new Date().getHours().toLocaleString("it-IT");
          this.minutes = new Date().getMinutes().toLocaleString("it-IT");
        });
      // if (Number(ore) > 12 || (Number(ore) === 12 && Number(minutes) > 30) ) {
      //   this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
      //     this.minPartenza = this.addDays(new Date(), 0)
      //     .toISOString()
      //     .substr(0, 10);
      //     this.minPartenzaConsegna = this.addDays(new Date(), 2)
      //     .toISOString()
      //     .substr(0, 10);
      //   }
      // else {
      this.minPartenza = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
      this.minPartenzaConsegna = this.addDays(new Date(), 1)
        .toISOString()
        .substr(0, 10);
      // }
      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.maxPartenzaConsegna = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
    },
    PulisciDestinatario() {
      this.RagioneSocialeDestinatario = "";
      this.IndirizzoDestinatario = "";
      this.nazione_dest = { value: "I" };
      this.nazione_destino_selezionata = "I";
      this.LocalitaDestinatario = "";
      this.CapDestinatario = "";
      this.ProvinciaDestinatario = "";
      this.nominativoConsegna = "";
      this.telefonoConsegna = "";
      this.mailConsegna = "";
    },
    checkGiorno(data) {
      var dataControllo = data.toISOString().substr(0, 10);
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/PrimaDataUtile/" +
            dataControllo
        )
        .then((res) => {
          this.DataRitiro = res.data;
        });
    },
    preavvisareConsegnaMet() {
      this.preavvisoConsegnaObbligatorio = this.preavvisoConsegna;
      if (this.preavvisoConsegnaObbligatorio) {
        if (this.listaRiferimentiConsegna.length === 2) {
          this.riferimentoConsegna = true;
        }
        if (this.listaRiferimentiConsegna.length === 1) {
          this.nominativoConsegna = this.listaRiferimentiConsegna[0].ref;
          this.telefonoConsegna = this.listaRiferimentiConsegna[0].cell;
          this.mailConsegna = this.listaRiferimentiConsegna[0].mail;
          // this.$refs.mail_consegna.focus();
          // this.$refs.mail_consegna.blur();
        }
      }
    },
    selectedLocalitaRitiro() {
      var value = this.LocalitaRitiro;
      if (value === null) value = "";
      if (value in this.localita_cappario_ritiro) {
        this.LocalitaRitiro = value;
        this.CAPRitiro = this.localita_cappario_ritiro[value].trpcap;
        this.ProvinciaRitiro = this.localita_cappario_ritiro[value].trpprv;
        this.nazione_ritiro_selezionata =
          this.localita_cappario_ritiro[value].nazione;
        this.nazione = this.nazione_ritiro_selezionata;
      } else {
        this.LocalitaRitiro = value;
      }
    },
    CheckMDM() {
      this.ErroreOrario = "";
      var erroreMDM = "";
      var vuoto = false;
      var vuotoMAM = false;
      if (this.MDM === null || this.MDM === "" || this.MDM === "00:00") {
        erroreMDM += "";
        vuoto = true;
      }
      if (this.MAM === "" || this.MAM === "00:00") {
        vuotoMAM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      if (resultMDM != null) {
        erroreMDM += this.$t("key585");
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      )
        erroreMDM += this.$t("key471");
      else {
        if (!this.MDM.includes(":")) erroreMDM += this.$t("key471");
        if (this.MDM[2] != ":") erroreMDM += this.$t("key471");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMDM += this.$t("key473");
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        )
          erroreMDM += this.$t("key474");
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        )
          erroreMDM += this.$t("key475");
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMDM += this.$t("key473");
        if (this.MDM == this.MAM) erroreMDM += this.$t("key477");
        if (Number(this.MDM.substring(3, 5)) > 59)
          erroreMDM += this.$t("key471");
      }
      if (erroreMDM != "" && vuoto === false && vuotoMAM === false) {
        this.DialogErroreOrario = true;
        this.ErroreOrario = erroreMDM;
      }
      if (vuoto) {
        this.MDM = "00:00";
      }
    },
    CheckMattino() {
      this.ErroreOrarioMattino = "";
      var erroreMAM = "";

      if (
        (this.MAM === null || this.MAM === "" || this.MAM === "00:00") &&
        this.MDM !== "" &&
        this.MDM !== "00:00"
      ) {
        erroreMAM += this.$t("key479");
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrarioMattino = erroreMAM;
      } else {
        if (
          this.MAM !== "" &&
          this.MAM !== "00:00" &&
          (this.MDM === "00:00" || this.MDM === "")
        ) {
          erroreMAM += this.$t("key480");
          this.DialogErroreOrario = true;
          this.ErroreOrarioMattino = erroreMAM;
        }
      }
    },
    CheckMAM() {
      this.ErroreOrario = "";
      var erroreMAM = "";
      var vuoto = false;
      var vuotoMDM = false;

      if (this.MAM === null || this.MAM === "" || this.MAM === "00:00") {
        // erroreMAM += "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura<br>"
        vuoto = true;
        this.MAM = "00:00";
      }
      if (this.MDM === "" || this.MDM === "00:00") {
        vuotoMDM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      if (resultMAM != null) {
        erroreMAM += this.$t("key585");
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      )
        erroreMAM += this.$t("key482");
      else {
        if (!this.MAM.includes(":")) erroreMAM += this.$t("key482");
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        )
          erroreMAM += this.$t("key474");
        if (
          Number(this.MAM.substring(0, 2)) < 6 ||
          (Number(this.MAM.substring(0, 2)) === 6 &&
            Number(this.MAM.substring(3, 5)) < 30)
        )
          erroreMAM += this.$t("key475");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMAM += this.$t("key485");
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMAM += this.$t("key485");
        if (this.MDM == this.MAM) erroreMAM += this.$t("key487");
        if (Number(this.MAM.substring(3, 5)) > 59)
          erroreMAM += this.$t("key488");
      }
      if (erroreMAM != "" && vuoto === false && vuotoMDM === false) {
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrario = erroreMAM;
      }
    },
    CheckPDP() {
      this.ErroreOrario = "";
      var errorePDP = "";
      var vuoto = false;
      if (this.PDP === null || this.PDP === "" || this.PDP === "00:00") {
        vuoto = true;
        this.PDP = "00:00";
      }
      if (this.PDP == null || this.PDP == "") errorePDP += this.$t("key489");
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP += this.$t("key587");
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePDP += this.$t("key490");
      else {
        if (!this.PDP.includes(":")) errorePDP += this.$t("key491");
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        )
          errorePDP += this.$t("key492");
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        )
          errorePDP += this.$t("key493");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (Number(this.PDP.substring(3, 5)) > 59)
          errorePDP += this.$t("key495");
        if (this.PDP == this.PAP) errorePDP += this.$t("key496");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePDP += this.$t("key494");
      }
      if (errorePDP != "" && vuoto === false) {
        this.DialogErroreOrarioPDP = true;
        this.ErroreOrario = errorePDP;
      }
    },
    CheckPAP() {
      this.ErroreOrario = "";
      var errorePAP = "";
      var vuoto = false;
      if (this.PAP === null || this.PAP === "" || this.PAP === "00:00") {
        vuoto = true;
        this.PAP = "00:00";
      }
      if (this.PAP == null || this.PAP == "") errorePAP += this.$t("key498");
      var regexNum = /[^0-99]/g;
      var resultPAP = this.PAP.replace(":", "").match(regexNum);
      if (resultPAP != null) {
        errorePAP += this.$t("key588");
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      )
        errorePAP += this.$t("key495");
      else {
        if (!this.PAP.includes(":")) errorePAP += this.$t("key495");
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        )
          errorePAP += this.$t("key492");
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        )
          errorePAP += this.$t("key493");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePAP += this.$t("key503");
        if (Number(this.PAP.substring(3, 5)) > 59)
          errorePAP += this.$t("key495");
        if (this.PDP == this.PAP) errorePAP += this.$t("key505");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePAP += this.$t("key503");
      }
      if (errorePAP != "" && vuoto === false) {
        this.DialogErroreOrarioPAP = true;
        this.ErroreOrario = errorePAP;
      }
    },
    CreaListaGiorni() {
      for (var i = 0; i < 30; i++) {
        var temp = this.addDays(new Date(), i);
        var day = temp.getDay();
        if (day != 0) {
          let date = moment()
            .month(val.split("-")[1] - 1)
            .date(i)
            .format("YYYY-MM-DD");
          this.DateOK.push(date);
        }
      }
    },
    GetTassativaConsegna(sel) {
      this.tassativaConsegnaSelezionata = { value: sel };
      this.consegnaTassativa = sel;
      this.CheckEspresso();
    },
    CambiaTassSelezionata(value) {
      if (value) {
        this.tassativaConsegnaSelezionata = { value: 0 };
        this.consegnaTassativa = 0;
        this.data_partenza = "";
      } else if (!value) {
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.inibisciEspresso = false;
      }
    },
    GetAutocomplete() {
      if (!this.noRubrica) {
        this.axios
          .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
          .then((res) => {
            res.data.forEach((el) => {
              var element = {};
              element = el.ragioneSociale.replace("''", "'");
              this.nominativiRubrica[el.id] = element;
              this.tempAutocomplete[element + " -- " + el.comune] = el;
              this.autocompletamentoStandard[
                element + " -- " + el.comune + " -- " + el.id
              ] = el;
              this.autocompletamento[
                element + " -- " + el.comune + " -- " + el.id
              ] = el;
              this.autocomplete.push({
                text: element + " -- " + el.comune,
                value: el.id,
              });
              this.autocompleteConsegna.push({
                text: element + " -- " + el.comune,
                value: el.id,
              });
              this.autocompleteRubrica.push({
                text: element + " -- " + el.comune,
                value: el.id,
              });
            });

            this.checkPermessi();
          })
          .catch((err) => {
            this.erroreInserimento = "";
            this.overlay = false;
            this.dialogErrore = true;
            this.erroreInserimento = this.$t("key506");
          });
      }
    },
    GetNazioni() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArVwNazionis")
        .then((res) => {
          res.data.forEach((el) => {
            var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
            this.nazioni.push(element);
          });
          this.axios
            .get(this.$store.state.servicePath + "/api/ArNazioneRitiris")
            .then((res) => {
              res.data.forEach((el) => {
                this.NazioniDestinoFinale.push(el.nazione);
              });
              this.GetAutocomplete();
            })
            .catch((err) => {
              this.erroreInserimento = "";
              this.overlay = false;
              this.dialogErrore = true;
              this.erroreInserimento = this.$t("key506");
            });
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
    },
    checkPermessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/NOT-SAVE"
        )
        .then((res) => {
          this.notsave = res.data;
          this.getDataFromService();
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
    },
    checkInvioRagioneSocialeRitiro(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.$refs.ragione_sociale_ritiro.blur();
        this.$refs.indirizzo_ritiro.focus();
      }
    },
    checkInvioRagioneSocialeDestinatario(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.$refs.ragione_sociale_consegna.blur();
        this.$refs.indirizzo_consegna.focus();
      }
    },
    checkInvio_Ritiro(evt, ricerca) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.OpenCappario(ricerca);
      }
    },
    checkInvio_Destino(evt, ricerca) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 13) {
        this.OpenCapparioDestino(ricerca);
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isIntegerDate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isIntegerTelefono(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 43) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 44) return true;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    Valida_Ritiro() {
      if (this.ProvinciaRitiro.length < 2) {
        this.ProvinciaValida = false;
      }
      if (this.CAPRitiro === null) this.CAPRitiro = "";
      if (this.LocalitaRitiro === null) this.LocalitaRitiro = "";
      if (this.CAPRitiro.length < 5 || this.CAPRitiro.trim().length === 0) {
        this.ProvinciaValida = false;
      }
      if (this.LocalitaRitiro.trim() === "") {
        this.ProvinciaValida = false;
      } else {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArVwNazionis/ValidaCappario/" +
              this.nazione_ritiro_selezionata +
              "/" +
              this.LocalitaRitiro +
              "/" +
              this.CAPRitiro +
              "/" +
              this.ProvinciaRitiro
          )
          .then((res) => {
            if (res.data) {
              this.ProvinciaValida = true;
            } else {
              this.ProvinciaValida = false;
            }
          });
      }
    },
    Valida_Consegna() {
      if (this.ProvinciaDestinatario.length < 2) {
        this.ProvinciaDestinatarioValida = false;
      }
      if (this.CapDestinatario === null) this.CapDestinatario = "";
      if (this.LocalitaDestinatario === null) this.LocalitaDestinatario = "";
      if (
        this.CapDestinatario.length < 5 ||
        this.CapDestinatario.trim().length === 0
      ) {
        this.ProvinciaDestinatarioValida = false;
      }
      if (this.LocalitaDestinatario.trim() === "") {
        this.ProvinciaDestinatarioValida = false;
      }
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArVwNazionis/ValidaCappario/" +
            this.nazione_destino_selezionata +
            "/" +
            this.LocalitaDestinatario +
            "/" +
            this.CapDestinatario +
            "/" +
            this.ProvinciaDestinatario
        )
        .then((res) => {
          this.ProvinciaDestinatarioValida = res.data;
        });
    },
    Valida_Cappario() {
      if (
        this.nazione_ritiro_selezionata == null ||
        this.nazione_ritiro_selezionata == undefined
      )
        this.nazione_ritiro_selezionata = "I";
      if (this.LocalitaRitiro == null || this.LocalitaRitiro == undefined)
        this.LocalitaRitiro = "";
      if (this.CAPRitiro == null || this.CAPRitiro == undefined)
        this.CAPRitiro = "";
      if (this.ProvinciaRitiro == null || this.ProvinciaRitiro == undefined)
        this.ProvinciaRitiro = "";
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArVwNazionis/ValidaCappario/" +
            this.nazione_ritiro_selezionata +
            "/" +
            this.LocalitaRitiro +
            "/" +
            this.CAPRitiro +
            "/" +
            this.ProvinciaRitiro
        )
        .then((res) => {
          if (res.data) {
            if (this.Monodestinatario) {
              if (
                this.nazione_destino_selezionata == null ||
                this.nazione_destino_selezionata == undefined
              )
                this.nazione_destino_selezionata = "I";
              if (
                this.LocalitaDestinatario == null ||
                this.LocalitaDestinatario == undefined
              )
                this.LocalitaDestinatario = "";
              if (
                this.CapDestinatario == null ||
                this.CapDestinatario == undefined
              )
                this.CapDestinatario = "";
              if (
                this.ProvinciaDestinatario == null ||
                this.ProvinciaDestinatario == undefined
              )
                this.ProvinciaDestinatario = "";
              this.axios
                .get(
                  this.$store.state.servicePath +
                    "/api/ArVwNazionis/ValidaCappario/" +
                    this.nazione_destino_selezionata +
                    "/" +
                    this.LocalitaDestinatario +
                    "/" +
                    this.CapDestinatario +
                    "/" +
                    this.ProvinciaDestinatario
                )
                .then((res) => {
                  if (!res.data) {
                    this.Errore += this.$t("key509");
                    this.overlay = false;
                    this.erroreValidazione = true;
                  } else {
                    this.Submit();
                  }
                })
                .catch((err) => {
                  this.Errore += this.$t("key510");
                  this.overlay = false;
                  this.erroreValidazione = true;
                });
            } else {
              this.Submit();
            }
          } else {
            this.Errore += this.$t("key511");
            this.overlay = false;
            this.erroreValidazione = true;
          }
        })
        .catch((err) => {
          this.Errore += this.$t("key510");
          this.overlay = false;
          this.erroreValidazione = true;
        });
    },
    CheckResa() {
      if (this.data_partenza !== "" && this.consegnaTassativa !== 4) {
        var ConsegnaEsclusiva = "T";
        if (this.soloMattino) ConsegnaEsclusiva = "M";
        else if (this.soloPomeriggio) ConsegnaEsclusiva = "P";
        var lunghezzaAltezza = "H";
        if (this.lunlar) lunghezzaAltezza = "L";
        var json_ritiro = {
          r1detr: this.TipoRitiro,
          r1rsmi: this.RagioneSocialeRitiro.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inmi: this.IndirizzoRitiro.replaceAll("'", "`").substr(0, 40),
          r1lcmi: this.LocalitaRitiro.replaceAll("'", "`").substr(0, 30),
          r1cpmi: this.CAPRitiro,
          r1prmi: this.ProvinciaRitiro,
          r1nzmi: this.nazione_ritiro_selezionata,
          r1rimi: this.RiferimentoRitiro,
          r1ptmi: this.preavvisare ? 1 : 0,
          r1tpmi: this.TelefonoRitiro,
          r1epmi: this.email,
          r1dtri: this.DataRitiro.replaceAll("-", ""),
          r1ttri: this.Indi ? "I" : "T",
          r1oima: this.MDM.replace(":", ""),
          r1ofma: this.MAM.replace(":", ""),
          r1oipm: this.PDP.replace(":", ""),
          r1ofpm: this.PAP.replace(":", ""),
          r1pgri: ConsegnaEsclusiva,
          r1gclu: this.lunedi,
          r1gcma: this.martedi,
          r1gcme: this.mercoledi,
          r1gcgi: this.giovedi,
          r1gcve: this.venerdi,
          r1nuco: this.NumeroColli,
          r1nuba: this.NumeroBancali,
          r1peto: this.PesoMerce,
          r1assi: this.assicurata,
          r1frag: this.fragile ? 1 : 0,
          r1radr: this.adr ? 1 : 0,
          r1nsov: this.sovrapponibile ? 1 : 0,
          r1l200: this.LunghezzaMerce,
          r1mmag: lunghezzaAltezza,
          r1tdes: this.Monodestinatario ? 1 : 2,
          r1rsde: this.RagioneSocialeDestinatario.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inde: this.IndirizzoDestinatario.replaceAll("'", "`").substr(0, 40),
          r1lcde: this.LocalitaDestinatario.replaceAll("'", "`").substr(0, 30),
          r1cpde: this.CapDestinatario,
          r1prde: this.ProvinciaDestinatario,
          r1nzde: this.nazione_destino_selezionata,
          r1ride: this.nominativoConsegna,
          r1ptde: this.preavvisoConsegna ? 1 : 0,
          r1tpde: this.telefonoConsegna,
          r1epde: this.mailConsegna,
          r1fede: this.fermoDeposito ? 1 : 0,
          r1rrit: this.riferimentoRitiro,
          r1rcle: this.rifclienteEstero,
          r1tcon: this.concordataTassativa ? 1 : 0,
          r1tdco: this.consegnaTassativa,
          r1dtco:
            this.data_partenza != null && this.data_partenza != ""
              ? this.data_partenza.replaceAll("-", "")
              : 0,
          r1volm: this.Volume,
        };
        this.Errore = "";
        var Disposizioni = [];
        if (this.espresso) Disposizioni.push({ r3tdis: 2, r3cdis: "E " });
        else Disposizioni.push({ r3tdis: 0, r3cdis: "  " });
        var json = {
          ritiro: json_ritiro,
          misure: [],
          disposizioni: Disposizioni,
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/Wbrit10f/tempiResa", json)
          .then((res) => {
            if (res.data !== "") {
              this.Errore += res.data;
              this.overlay = false;
              this.erroreValidazione = true;
            } else {
              this.Valida_Cappario();
            }
          })
          .catch((err) => {
            this.erroreInserimento = "";
            this.overlay = false;
            this.dialogErrore = true;
            this.erroreInserimento = this.$t("key506");
          });
      } else {
        this.Valida_Cappario();
      }
    },
    Validate() {
      if (this.spedDaRitirare === undefined || this.spedDaRitirare === null)
        this.spedDaRitirare = "";
      if (
        this.ragsocDestinoFinale === undefined ||
        this.ragsocDestinoFinale === null
      ) {
        this.ragsocDestinoFinale = "";
      }
      if (
        this.indirizzoDestinoFinale === undefined ||
        this.indirizzoDestinoFinale === null
      ) {
        this.indirizzoDestinoFinale = "";
      }
      if (
        this.comuneDestinoFinale === undefined ||
        this.comuneDestinoFinale === null
      ) {
        this.comuneDestinoFinale = "";
      }
      if (
        this.capDestinoFinale === undefined ||
        this.capDestinoFinale === null
      ) {
        this.capDestinoFinale = "";
      }
      if (
        this.provinciaDestinoFinale === undefined ||
        this.provinciaDestinoFinale === null
      ) {
        this.provinciaDestinoFinale = "";
      }
      if (this.lunlar === false && this.altez === false) {
        this.misuraScelta = 0;
      }
      if (this.preavvisoObbligatorio === false) {
        this.TelefonoRitiro = "";
        this.email = "";
      }
      if (this.preavvisoConsegnaObbligatorio === false) {
        this.telefonoConsegna = "";
        this.mailConsegna = "";
      }
      if (
        this.rifclienteEstero === null ||
        this.rifclienteEstero === undefined
      ) {
        this.rifclienteEstero = "";
      }
      if (
        this.TipoRitiroSelezionato === 2 &&
        this.nazione_destino_selezionata !== "I" &&
        this.nazione_destino_selezionata !== "V" &&
        this.obbligoRifEstero.trim() !== "S" &&
        this.rifclienteEstero.trim() === ""
      ) {
        this.rifclienteEstero = ".";
      }
      if (
        this.LunghezzaMerce == "" ||
        this.LunghezzaMerce == undefined ||
        this.LunghezzaMerce == null
      ) {
        this.misuraScelta = 0;
        this.LunghezzaMerce = 0;
        this.lunlar = false;
        this.altez = false;
      }
      if (Number(this.LunghezzaMerce) < 200) {
        this.misuraScelta = 0;
        this.LunghezzaMerce = 0;
        this.lunlar = false;
        this.altez = false;
      }
      if (this.merce === null || this.merce === undefined) {
        this.merce = "";
      }
      if (this.data_partenza == null || this.data_partenza == undefined)
        this.data_partenza = "";
      this.Errore = "";
      this.assicurata = Math.round(Number(this.assicurata) * 100) / 100;
      var ore = this.ore;
      var minutes = this.minutes;
      if (
        this.DataRitiro.replaceAll("-", "") == this.dataDiOggi &&
        (Number(ore) > 12 || (Number(ore) === 12 && Number(minutes) > 30))
      ) {
        if (this.Tassativa == "T") {
          this.Errore += this.$t("key572");
        }
      }
      var DataRiferimento = Number(this.dataDiOggi);
      var dRitiro = Number(this.DataRitiro.replaceAll("-", ""));
      if (dRitiro < DataRiferimento) {
        this.Errore += this.$t("key573"); //nuovo
      }
      //   this.Errore += "- La data ritiro non può essere quella odierna se l'orario inserimento è successivo alle ore 12:30 </br>";
      if (this.data_partenza !== "") {
        var ritiro = Number(this.DataRitiro.replaceAll("-", ""));
        var consegna = Number(this.data_partenza.replaceAll("-", ""));
        if (consegna < ritiro || consegna === ritiro)
          this.Errore += this.$t("key515");
      }
      if (
        (this.obbligoVolume === "0" || this.obbligoVolume === 0) &&
        (this.obbligoVolumeInter === "0" || this.obbligoVolumeInter === 0)
      ) {
        this.Volume = 0.0;
      }
      if (this.Destinatari === null || this.Destinatari === undefined)
        this.Destinatari = 0;
      if (this.Destinatari === 0) this.Errore += this.$t("key516");
      if (
        this.Destinatari === 2 &&
        Number(this.NumeroColli) === 1 &&
        Number(this.NumeroBancali) === 0
      )
        this.Errore += this.$t("key517");
      if (this.Destinatari === 2 && this.flagRitiroContest)
        this.Errore += this.$t("key610");
      if (this.data_partenza === null || this.data_partenza === undefined)
        this.data_partenza = "";
      if (this.riferimentoRitiro == null || this.riferimentoRitiro == undefined)
        this.riferimentoRitiro = "";
      if (this.destinoFinale == null || this.destinoFinale == undefined)
        this.destinoFinale = "";
      if (this.riferimentoEstero == null || this.riferimentoEstero == undefined)
        this.riferimentoEstero = "";
      this.overlay = true;
      this.totalePallet = 0;
      this.MisureBancali.forEach((el) => {
        this.totalePallet += Number(el.numeroPallet);
      });
      if (this.MisureBancali.length > 0) {
        var MisureBancaliControllo = this.MisureBancali;
        this.MisureBancali.forEach((el) => {
          if (
            Number(el.numeroPallet) == 0 &&
            Number(el.lunghezza) == 0 &&
            Number(el.altezza) == 0 &&
            Number(el.larghezza) == 0
          ) {
            const index = this.MisureBancali.indexOf(el);
            MisureBancaliControllo.splice(index);
          }
        });
        var zero = false;
        var misuraMaggioreTemp = 0;
        var maggiore = false;
        var lunghezzaTemp = 0;
        MisureBancaliControllo.forEach((el) => {
          if (
            el.numeroPallet === null ||
            el.numeroPallet === undefined ||
            el.numeroPallet === "" ||
            el.numeroPallet === "0"
          ) {
            el.numeroPallet = 0;
          }
          if (
            el.lunghezza === null ||
            el.lunghezza === undefined ||
            el.lunghezza === "" ||
            el.lunghezza === "0"
          ) {
            el.lunghezza = 0;
          }
          if (
            el.altezza === null ||
            el.altezza === undefined ||
            el.altezza === "" ||
            el.altezza === "0"
          ) {
            el.altezza = 0;
          }
          if (
            el.larghezza === null ||
            el.larghezza === undefined ||
            el.larghezza === "" ||
            el.larghezza === "0"
          ) {
            el.larghezza = 0;
          }
          if (
            Number(el.numeroPallet) == 0 ||
            Number(el.lunghezza) == 0 ||
            Number(el.larghezza) == 0 ||
            Number(el.altezza) == 0
          )
            zero = true;
          if (Number(el.lunghezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.lunghezza;
            if (Number(el.lunghezza) >= 200) maggiore = true;
          }
          if (Number(el.altezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 2;
            lunghezzaTemp = el.altezza;
            if (Number(el.altezza) >= 200) maggiore = true;
          }
          if (Number(el.larghezza) > Number(lunghezzaTemp)) {
            misuraMaggioreTemp = 1;
            lunghezzaTemp = el.larghezza;
            if (Number(el.larghezza) >= 200) maggiore = true;
          }
        });
        if (zero) this.Errore += this.$t("key518");
        //Controllo il preavviso di consegna
        if (maggiore == false && this.misuraScelta != 0) {
          if (
            misuraMaggioreTemp != this.misuraScelta &&
            lunghezzaTemp > this.LunghezzaMerce
          ) {
            this.Errore += this.$t("key519");
          }
        } else if (
          maggiore &&
          this.misuraScelta != misuraMaggioreTemp &&
          lunghezzaTemp >= this.LunghezzaMerce
        ) {
          r;
          this.Errore += this.$t("key519");
        } else if (maggiore && this.misuraScelta === 0) {
          this.Errore += this.$t("key520");
        }
      }

      if (
        Number(this.misuraScelta) == 0 &&
        Number(this.LunghezzaMerce) >= 200
      ) {
        this.Errore += this.$t("key521");
        this.LunghezzaMerceColore = "red lighten-4";
      }
      //Qui faccio il controllo contrario
      if (
        this.telefonoConsegna === null ||
        this.telefonoConsegna === undefined
      ) {
        this.telefonoConsegna = "";
      }
      if (
        this.nominativoConsegna === null ||
        this.nominativoConsegna === undefined
      ) {
        this.nominativoConsegna = "";
      }

      //Aggiunto il controllo dei valori validi PRENOT.MAIL, PRENOT.PORTALE il 19/09
      var ErroreTelefonoConsegna = false;
      if (
        this.telefonoConsegna.trim().toUpperCase() != "PRENOT.MAIL" &&
        this.telefonoConsegna.trim().toUpperCase() != "PRENOT.PORTALE"
      ) {
        var regexNum = /[^0-99]/g;
        var resultTelefonoc = this.telefonoConsegna.match(regexNum);
        //////////
        if (resultTelefonoc !== null) {
          if (resultTelefonoc.length > 1) {
            ErroreTelefonoConsegna = true;
          } else {
            if (!this.telefonoConsegna.startsWith("+")) {
              ErroreTelefonoConsegna = true;
            } else {
              if (
                !this.telefonoConsegna.startsWith("3") &&
                !this.telefonoConsegna.startsWith("0") &&
                !this.telefonoConsegna.startsWith("+")
              ) {
                ErroreTelefonoConsegna = true;
              }
            }
          }
        } else {
          if (
            !this.telefonoConsegna.startsWith("3") &&
            !this.telefonoConsegna.startsWith("0") &&
            !this.telefonoConsegna.startsWith("+")
          ) {
            ErroreTelefonoConsegna = true;
          }
        }
        //////////
        // if(resultTelefonoc !== null){
        //   if(resultTelefonoc.length > 1){
        //     ErroreTelefonoConsegna = true;
        //   }
        //   else if(resultTelefonoc[0] === "+"){
        //     if(!this.telefonoConsegna.startsWith("+")){
        //       ErroreTelefonoConsegna = true;
        //     }
        //   }
        // }
        // else{
        //   if(!this.telefonoConsegna.startsWith("3") && !this.telefonoConsegna.startsWith("0")){
        //     ErroreTelefonoConsegna = true;
        //   }
        // }
        if (
          this.preavvisoConsegnaObbligatorio &&
          (this.telefonoConsegna === "" ||
            this.telefonoConsegna.length < 6 ||
            ErroreTelefonoConsegna)
        ) {
          this.Errore += this.$t("key522");
          if (
            this.telefonoConsegna === "" ||
            this.telefonoConsegna.length < 6
          ) {
            this.telefonoConsegnaColore = "red lighten-4";
          }
        }
      } else {
        this.telefonoConsegna = this.telefonoConsegna.trim().toUpperCase();
      }

      if (
        this.preavvisoConsegnaObbligatorio &&
        this.nominativoConsegna.trim() === ""
      ) {
        this.Errore += this.$t("key523");
        this.nominativoConsegnaColore = "red lighten-4";
      }
      //ar regexEmail2 = /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.mailConsegna === null || this.mailConsegna === undefined)
        this.mailConsegna = "";
      if (this.mailConsegna.trim() !== "") {
        var resultMail2 = this.mailConsegna.trim().match(regexEmail);
        if (resultMail2 === null) {
          this.Errore += this.$t("key524");
          this.mailConsegnaColore = "red lighten-4";
        }
      }
      if (this.misuraScelta == 2 && this.LunghezzaMerce > 235) {
        this.Errore += this.$t("key525");
        this.LunghezzaMerceColore = "red lighten-4";
      }
      if (this.misuraScelta == 1 && this.LunghezzaMerce > 600) {
        this.Errore += this.$t("key526");
        this.LunghezzaMerceColore = "red lighten-4";
      }
      if (this.misuraScelta == 0 && this.LunghezzaMerce >= 200) {
        this.Errore += this.$t("key527");
        this.LunghezzaMerceColore = "red lighten-4";
      }
      if (
        this.Destinatari === 2 &&
        (this.TipoServizioScelto === 1 || this.TipoServizioSelezionato === 2)
      ) {
        this.Errore += this.$t("key528");
      }
      if (
        this.NumeroBancali === 0 ||
        this.NumeroBancali === null ||
        this.NumeroBancali === undefined ||
        this.NumeroBancali === ""
      )
        this.NumeroBancali = 0;
      if (
        this.NumeroColli === 0 ||
        this.NumeroColli === null ||
        this.NumeroColli === undefined ||
        this.NumeroColli === ""
      )
        this.NumeroColli = 0;
      if (
        this.MisureSelezionate === 1 &&
        this.NumeroBancali === 0 &&
        this.NumeroColli === 0
      ) {
        this.Errore += this.$t("key529");
        this.NumeroColliColore = "red lighten-4";
        this.NumeroBancaliColore = "red lighten-4";
      }
      if (Number(this.NumeroColli) + Number(this.NumeroBancali) > 99) {
        //this.Errore += "- Il numero massimo di colli/bancali accettato è 99 <br>";
        this.Errore += this.$t("key438");
        this.NumeroColliColore = "red lighten-4";
        this.NumeroBancaliColore = "red lighten-4";
      }
      if (
        Number(this.NumeroColli) + Number(this.NumeroBancali) <
        this.totalePallet
      )
        this.Errore += this.$t("key531");
      if (
        this.riferimentoRitiro === null ||
        this.riferimentoRitiro === undefined
      )
        this.riferimentoRitiro = "";
      if (
        (this.TipoRitiro === "A" || this.TipoRitiro === "T") &&
        this.riferimentoRitiro === "" &&
        (this.nazione_destino_selezionata === "I" ||
          this.nazione_destino_selezionata === "V") &&
        (this.nazione_ritiro_selezionata === "I" ||
          this.nazione_ritiro_selezionata === "V")
      ) {
        this.Errore += this.$t("key532");
        this.riferimentoRitiroColore = "red lighten-4";
      }

      //Controllo tolto in data 07/10/2021 su indicazione specifica. il controllo è da prevedersi solo se corrispondente estero o obbligo riferimento fattura E ritiro di tipo assegnato.
      //Ripristinato dopo consultazione con Marco Scarabelli.
      if (this.rifclienteEstero === null || this.rifclienteEstero === undefined)
        this.rifclienteEstero = "";

      if (
        this.PesoMerce === undefined ||
        this.PesoMerce === null ||
        this.PesoMerce === "" ||
        this.PesoMerce === "0"
      )
        this.PesoMerce = 0;
      if (
        this.PesoMerce === 0 ||
        this.PesoMerce === undefined ||
        this.PesoMerce === null ||
        this.PesoMerce === ""
      ) {
        this.Errore += this.$t("key534");
        this.errorePeso = "red lighten-4";
      }
      if (
        this.RagioneSocialeRitiro.text === null ||
        this.RagioneSocialeRitiro.text === undefined
      ) {
        this.RagioneSocialeRitiro = { value: "", text: "" };
        this.idModificaRitiro = "AZIENDA";
      }
      //Verifico che non siano presenti sequenze di numeri più lunghe di 7
      var patt = /[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]/g;
      var result_rags = this.RagioneSocialeRitiro.text.match(patt);
      var result_ind = this.IndirizzoRitiro.match(patt);
      if (result_rags != null) {
        this.Errore += this.$t("key535");
        this.RagioneSocialeRitiroColore = "red lighten-4";
      }
      if (result_ind != null) {
        this.Errore += this.$t("key535");
        this.IndirizzoRitiroColore = "red lighten-4";
      }
      if (this.RagioneSocialeRitiro.text.trim().length < 5) {
        this.Errore += this.$t("key537");
        this.RagioneSocialeRitiroColore = "red lighten-4";
      }
      if (this.IndirizzoRitiro.trim().length < 5) {
        this.Errore += this.$t("key538");
        this.IndirizzoRitiroColore = "red lighten-4";
      }
      if (this.LocalitaRitiro.trim().length < 2) {
        this.Errore += this.$t("key539");
        this.LocalitaRitiroColore = "red lighten-4";
      }
      if (this.CAPRitiro == null || this.CAPRitiro == undefined)
        this.CAPRitiro = "";
      if (
        this.CAPRitiro.trim().length > 11 ||
        this.CAPRitiro.trim().length === 0
      ) {
        this.Errore += this.$t("key540");
        this.CAPRitiroColore = "red lighten-4";
      }
      if (this.ProvinciaRitiro === undefined || this.ProvinciaRitiro === null) {
        this.ProvinciaRitiro = "";
      }
      if (this.ProvinciaRitiro == "") {
        this.Errore += this.$t("key541");
        this.ProvinciaRitiroColore = "red lighten-4";
      }

      // Faccio un controllo sugli orari di ritiro e i relativi flag
      if (this.MDM === null || this.MDM === "") this.MDM = "00:00";
      if (this.MAM === null || this.MAM === "") this.MAM = "00:00";
      if (this.PDP === null || this.PDP === "") this.PDP = "00:00";
      if (this.PAP === null || this.PAP === "") this.PAP = "00:00";
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      var resultPAP = this.PAP.replace(":", "").match(regexNum);

      if (resultMDM != null) {
        this.Errore += this.$t("key577"); //nuovo
        this.MDMColore = "red lighten-4";
      }
      if (resultMAM != null) {
        this.Errore += this.$t("key578");
        this.MAMColore = "red lighten-4";
      }
      if (resultPDP != null) {
        this.Errore += this.$t("key579");
        this.PDPColore = "red lighten-4";
      }
      if (resultPAP != null) {
        this.Errore += this.$t("key580"); //nuovo
        this.PAPColore = "red lighten-4";
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key471");
        this.MDMColore = "red lighten-4";
      } else if (this.MDM !== "00:00") {
        if (!this.MDM.includes(":")) {
          this.Errore += this.$t("key471");
          this.MDMColore = "rred lighten-4ed";
        }
        if (this.MDM[2] != ":") {
          this.Errore += this.$t("key471");
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key473");
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key475");
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key476");
          this.MDMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key477");
          this.MDMColore = "red lighten-4";
        }
        if (Number(this.MDM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key478");
          this.MDMColore = "red lighten-4";
        }
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key483");
        this.MAMColore = "red lighten-4";
      } else if (this.MAM !== "00:00") {
        if (!this.MAM.includes(":")) {
          this.Errore += this.$t("key482");
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key484");
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key487");
          this.MAMColore = "red lighten-4";
        }
        if (Number(this.MAM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key488");
          this.MAMColore = "red lighten-4";
        }
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key490");
        this.PDPColore = "red lighten-4";
      } else if (this.PDP !== "00:00") {
        if (!this.PDP.includes(":")) {
          this.Errore += this.$t("key491");
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
        }
        if (Number(this.PDP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PDPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key496");
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
        }
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key495");
        this.PAPColore = "red lighten-4";
      } else if (this.PAP !== "00:00") {
        if (!this.PAP.includes(":")) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
        }
        if (Number(this.PAP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key505");
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PDP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
        }
      }
      if (this.soloMattino && (this.MDM === "00:00" || this.MAM === "00:00"))
        this.Errore += this.$t("key544");

      if (this.soloPomeriggio && (this.PDP === "00:00" || this.PAP === "00:00"))
        this.Errore += this.$t("key545");
      if (this.MAM === "00:00" && this.MDM !== "00:00") {
        this.Errore += this.$t("key479");
      } else {
        if (this.MAM !== "00:00" && this.MDM === "00:00") {
          this.Errore += this.$t("key480");
        }
      }
      if (this.PDP === "00:00" && this.PAP !== "00:00") {
        this.Errore += this.$t("key581"); //nuovo
      } else {
        if (this.PDP !== "00:00" && this.PAP === "00:00") {
          this.Errore += this.$t("key582"); //nuovo
        }
      }
      if (this.MDM === "00:00" && this.MAM === "00:00") {
        if (this.PAP === "00:00" && this.PDP === "00:00") {
          this.Errore += this.$t("key546");
        } else if (this.PAP !== "00:00" && this.PDP !== "00:00") {
          this.soloPomeriggio = true;
          this.soloMattino = false;
        }
      }
      if (this.PAP === "00:00" && this.PDP === "00:00") {
        if (this.MDM !== "00:00" && this.MAM !== "00:00") {
          this.soloMattino = true;
          this.soloPomeriggio = false;
        }
      }
      if (this.soloMattino) {
        this.PDP = "00:00";
        this.PAP = "00:00";
      }
      if (this.soloPomeriggio) {
        this.MAM = "00:00";
        this.MDM = "00:00";
      }

      // controllo i campi del destinatario
      if (
        this.RagioneSocialeDestinatario.text === null ||
        this.RagioneSocialeDestinatario.text === undefined
      ) {
        this.RagioneSocialeDestinatario = { value: "", text: "" };
        this.idModificaDestinatario = "AZIENDA";
      }
      if (
        this.Destinatari === 1 &&
        this.RagioneSocialeDestinatario.text.trim().length < 5
      ) {
        this.Errore += this.$t("key547");
        this.RagioneSocialeDestinatarioColore = "red lighten-4";
      }
      if (this.Destinatari === 1) {
        var patt = /[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]/g;
        var result_rags = this.RagioneSocialeDestinatario.text.match(patt);
        var result_ind = this.IndirizzoDestinatario.match(patt);
        if (result_rags != null) {
          this.Errore += this.$t("key548");
          this.RagioneSocialeDestinatarioColore = "red lighten-4";
        }
        if (result_ind != null) {
          this.Errore += this.$t("key549");
          this.IndirizzoDestinatarioColore = "red lighten-4";
        }
      }
      if (
        this.Destinatari === 1 &&
        this.IndirizzoDestinatario.trim().length < 5
      ) {
        this.Errore += this.$t("key550");
        this.IndirizzoDestinatarioColore = "red lighten-4";
      }
      if (
        this.Destinatari === 1 &&
        this.LocalitaDestinatario.trim().length < 2
      ) {
        this.Errore += this.$t("key551");
        this.LocalitaDestinatarioColore = "red lighten-4";
      }
      if (this.CapDestinatario === null || this.CapDestinatario === undefined) {
        this.CapDestinatario = "";
      }
      if (
        this.Destinatari === 1 &&
        (this.CapDestinatario.trim().length > 11 ||
          this.CapDestinatario.trim().length === 0)
      ) {
        this.Errore += this.$t("key552");
        this.CapDestinatarioColore = "red lighten-4";
      }
      if (
        this.ProvinciaDestinatario === undefined ||
        this.ProvinciaDestinatario === null
      ) {
        this.ProvinciaDestinatario = "";
      }
      if (this.Destinatari === 1 && this.ProvinciaDestinatario == "") {
        this.Errore += this.$t("key553");
        this.ProvinciaDestinatarioColore = "red lighten-4";
      }
      if (
        this.nazione_ritiro_selezionata !== "I" &&
        this.nazione_ritiro_selezionata !== "V" &&
        this.preavvisare === false
      ) {
        this.Errore += this.$t("key554");
      }
      //Aggiunto il controllo dei valori validi PRENOT.MAIL, PRENOT.PORTALE il 19/09
      var ErroreTelefonoRitiro = false;
      if (
        this.TelefonoRitiro.trim().toUpperCase() != "PRENOT.MAIL" &&
        this.TelefonoRitiro.trim().toUpperCase() != "PRENOT.PORTALE"
      ) {
        var regexNum = /[^0-99]/g;
        var resultTelefono = this.TelefonoRitiro.match(regexNum);
        if (resultTelefono !== null) {
          if (resultTelefono.length > 1) {
            ErroreTelefonoRitiro = true;
          } else {
            if (!this.TelefonoRitiro.startsWith("+")) {
              ErroreTelefonoRitiro = true;
            } else {
              if (
                !this.TelefonoRitiro.startsWith("3") &&
                !this.TelefonoRitiro.startsWith("0") &&
                !this.TelefonoRitiro.startsWith("+")
              ) {
                ErroreTelefonoRitiro = true;
              }
            }
          }
        } else {
          if (
            !this.TelefonoRitiro.startsWith("3") &&
            !this.TelefonoRitiro.startsWith("0") &&
            !this.TelefonoRitiro.startsWith("+")
          ) {
            ErroreTelefonoRitiro = true;
          }
        }
        if (
          (this.preavvisoObbligatorio === true && this.TelefonoRitiro === "") ||
          (this.preavvisoObbligatorio === true &&
            this.TelefonoRitiro.replace("+", "").trim().length < 6) ||
          (this.preavvisoObbligatorio === true && ErroreTelefonoRitiro)
        ) {
          this.Errore += this.$t("key555");
          this.TelefonoRitiroColore = "red lighten-4";
        }
      } else {
        this.TelefonoRitiro = this.TelefonoRitiro.trim().toUpperCase();
      }

      if (
        this.RiferimentoRitiro === null ||
        this.RiferimentoRitiro === undefined
      )
        this.RiferimentoRitiro = "";
      if (
        this.preavvisoObbligatorio === true &&
        this.RiferimentoRitiro.trim() === ""
      ) {
        this.Errore += this.$t("key556");
        this.RiferimentoRitiroColore = "red lighten-4";
      }
      if (this.email === null || this.email === undefined) this.email = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.email.trim() !== "") {
        var resultMail = this.email.trim().match(regexEmail);
        if (resultMail === null) {
          this.Errore += this.$t("key557");
          this.emailColore = "red lighten-4";
        }
      }
      if (
        this.NumeroColli === undefined ||
        this.NumeroColli === null ||
        this.NumeroColli === "" ||
        this.NumeroColli === "0"
      ) {
        this.NumeroColli = 0;
      }
      if (
        this.NumeroBancali === undefined ||
        this.NumeroBancali === null ||
        this.NumeroBancali === "" ||
        this.NumeroBancali === "0"
      ) {
        this.NumeroBancali = 0;
      }
      if (this.NumeroColli === 0 && this.NumeroBancali === 0) {
        this.Errore += this.$t("key558");
        this.NumeroColliColore = "red lighten-4";
        this.NumeroBancaliColore = "red lighten-4";
      }
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === "0" ||
        this.Volume === "" ||
        this.Volume === NaN
      )
        this.Volume = 0;
      this.Volume = Math.round(this.Volume * 100) / 100;
      // if(this.Volume !== 0.00){
      //   if(this.Volume < 0.01)
      //     this.Volume = 0.01;
      // }

      if (
        (this.obbligoVolume === "1" || this.obbligoVolume === 1) &&
        Number(this.Volume) < 0.01
      ) {
        this.Errore += this.$t("key583"); //nuovo
        this.VolumeColore = "red lighten-4";
      }
      // Volume e misure obbligatori quando la nazione di ritiro è diversa da I o V ovvero per esteri
      if (
        this.nazione_ritiro_selezionata != "I" &&
        this.nazione_ritiro_selezionata != "V" &&
        this.nazione_ritiro_selezionata != "" &&
        this.nazione_ritiro_selezionata != undefined &&
        this.nazione_ritiro_selezionata != null
      ) {
        this.obbligoVolumeInter = 1;
        if (Number(this.Volume) < 0.01) {
          this.Errore += this.$t("key626"); //nuovo
          this.VolumeColore = "red lighten-4";
        }
        if (this.MisureBancali.length == 0) {
          this.Errore += this.$t("key627"); //nuovo
        }
      } else {
        this.obbligoVolumeInter = 0;
        if (this.obbligoVolume === "0" || this.obbligoVolume === 0) {
          this.VolumeColore = "";
          this.Volume = 0;
        }
      }

      if (Number(this.Volume) > 99) {
        this.Errore +=
          "- Il volume non può essere maggiore di 99 metri cubi </br>"; //nuovo
        this.VolumeColore = "red lighten-4";
      }
      if (Number(this.PesoMerce) > 33000) {
        this.Errore += this.$t("key561");
        this.errorePeso = "red lighten-4";
      }
      // Forza la tassativa ad I
      if (
        this.Tassativa === 0 ||
        this.Tassativa === "0" ||
        this.Tassativa === null ||
        this.Tassativa === undefined
      ) {
        this.Tassativa = "I";
      }
      if (
        this.concordataTassativa === null ||
        this.concordataTassativa === undefined
      ) {
        this.concordataTassativa = false;
      }
      if (
        this.tassativaConsegnaSelezionata.value === null ||
        this.tassativaConsegnaSelezionata.value === undefined
      ) {
        this.tassativaConsegnaSelezionata.value = null;
      }
      if (this.data_partenza === null || this.data_partenza === undefined) {
        this.data_partenza = "";
      }
      if (
        this.concordataConsegnaTassativa &&
        (this.tassativaConsegnaSelezionata.value === null ||
          this.data_partenza === "")
      ) {
        this.Errore += this.$t("key562");
      }
      if (
        this.nazione_ritiro_selezionata != "I" &&
        this.nazione_ritiro_selezionata !== "V" &&
        this.rifclienteEstero.trim().length < 2
      ) {
        this.Errore += this.$t("key574"); //nuovo
        this.rifclienteEsteroColore = "red lighten-4";
      }
      if (
        this.obbligoRifEstero.trim() !== "" &&
        this.rifclienteEstero.trim().length < 2
      ) {
        this.Errore += this.$t("key575"); //nuovo
        this.rifclienteEsteroColore = "red lighten-4";
      }
      if (
        this.NazioneDestinoFinaleScelta === null ||
        this.NazioneDestinoFinaleScelta === undefined
      ) {
        this.NazioneDestinoFinaleScelta = "";
      }
      if (
        this.isCorrisEstero.trim() === "S" &&
        this.corrispondenteSpeciale === "S" &&
        this.Monodestinatario &&
        this.NazioneDestinoFinaleScelta.trim() === ""
      ) {
        this.Errore += this.$t("key601"); //nuovo
        this.nazioneDestinoFinaleColore = "red lighten-4";
      }
      if (this.NazioneDestinoFinaleScelta.trim() !== "") {
        this.comuneDestinoFinale = this.NazioneDestinoFinaleScelta;
      }
      if (
        this.TipoRitiro === "A" &&
        (this.isCorrisEstero.trim() === "S" ||
          this.obbligoRifEstero.trim() !== "") &&
        this.rifclienteEstero.trim().length < 2
      ) {
        this.Errore += this.$t("key575"); //nuovo
        this.rifclienteEsteroColore = "red lighten-4";
      }
      if (this.rifclienteEstero.trim() === "" && this.flagRitiroContest) {
        this.rifclienteEstero = this.spedDaRitirare;
      }
      if (
        this.isCorrisEstero.trim() === "S" &&
        this.corrispondenteSpeciale === "N" &&
        this.TipoRitiro === "A" &&
        ((this.ragsocDestinoFinale.trim() === "" &&
          this.comuneDestinoFinale.trim() === "") ||
          (this.ragsocDestinoFinale.trim().length < 3 &&
            this.comuneDestinoFinale.trim().length < 2))
      ) {
        this.Errore += this.$t("key576");
        this.destinoFinaleColore = "red lighten-4";
      }
      if (Number(this.Volume) > 0) {
        var checkPeso = Number(this.PesoMerce) / 6.65;
        if (checkPeso < Number(this.Volume)) {
          this.Errore +=
            " - Il rapporto tra il peso e il volume non è corretto </br>";
          this.VolumeColore = "red lighten-4";
        }
      }
      // if (
      //   this.Volume === null ||
      //   this.Volume === undefined ||
      //   this.Volume === "0" ||
      //   this.Volume === "" ||
      //   this.Volume === NaN
      // )
      //   this.Volume = 0;
      // if (
      //   (this.obbligoVolume === "1" || this.obbligoVolume === 1) &&
      //   Number(this.Volume) < 0.01
      // ) {
      //   this.Errore += this.$t("key583"); //nuovo
      //   this.VolumeColore = "red";
      // }
      this.stopSubmit = false;
      if (this.Errore !== "") {
        this.overlay = false;
        this.erroreValidazione = true;
      } else {
        this.CheckResa();
      }
    },
    GetTipoRitiro(event) {
      if (event === 1) {
        this.fermoDeposito = false;
        this.spedDaRitirare = "";
        this.flagRitiroContest = false;
        this.MDM = "08:00";
        this.MAM = "13:00";
        this.PDP = "13:30";
        this.PAP = "18:00";
        this.NazioneDestinoFinaleScelta = "";
        this.MostraMultidestinatario = true;
        this.divisioneSchermo = 1;
        this.CheckPermessoVolume(1);
        this.TipoRitiro = "F";
        this.RagioneSocialeDestinatario = { text: "", value: 0 };
        this.tempRagioneSocialeConsegna = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.lunedi = 0;
        this.martedi = 0;
        this.mercoledi = 0;
        this.giovedi = 0;
        this.venerdi = 0;
        this.destinatarioSelezionato = { value: 0 };
        this.Destinatari = 0;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.Monodestinatario = false;
        this.Multidestinatario = false;
        this.readonlyMono = false;
        this.ProvinciaValida = true;
        this.ragsocDestinoFinale = "";
        this.indirizzoDestinoFinale = "";
        this.nazioneDestinoFinale = "";
        this.comuneDestinoFinale = "";
        this.capDestinoFinale = "";
        this.provinciaDestinoFinale = "";
        this.merce = "";
        if (this.axios.defaults.headers.common["Language"] == "it-IT") {
          this.Destinatario = this.Destinatario_it;
        } else {
          this.Destinatario = this.Destinatario_en;
        }
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArGestioneUtenzas/GetAnagrafica",
            {}
          )
          .then((res) => {
            this.IndirizzoRitiro = res.data.trcind
              .substr(0, 40)
              .replace("''", "'");
            this.LocalitaRitiro = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaRitiro = res.data.trcprv
              .replace("''", "'")
              .substr(0, 2);
            this.CAPRitiro = res.data.trccap.replace("''", "'").substr(0, 5);
            this.MAM =
              res.data.oraChiusuraMattino.substr(0, 2) +
              ":" +
              res.data.oraChiusuraMattino.substr(2, 2);
            this.MDM =
              res.data.oraAperturaMattino.substr(0, 2) +
              ":" +
              res.data.oraAperturaMattino.substr(2, 2);
            this.PAP =
              res.data.oraChiusuraPomeriggio.substr(0, 2) +
              ":" +
              res.data.oraChiusuraPomeriggio.substr(2, 2);
            this.PDP =
              res.data.oraAperturaPomeriggio.substr(0, 2) +
              ":" +
              res.data.oraAperturaPomeriggio.substr(2, 2);
            var giorni = res.data.giochi;
            this.lunedi =
              giorni.substring(0, 1) === " "
                ? 0
                : giorni.substr(0, 1) === "M"
                ? 1
                : giorni.substr(0, 1) === "P"
                ? 2
                : 3;
            this.martedi =
              giorni.substr(1, 1) === " "
                ? 0
                : giorni.substr(1, 1) === "M"
                ? 1
                : giorni.substr(1, 1) === "P"
                ? 2
                : 3;
            this.mercoledi =
              giorni.substr(2, 1) === " "
                ? 0
                : giorni.substr(2, 1) === "M"
                ? 1
                : giorni.substr(2, 1) === "P"
                ? 2
                : 3;
            this.giovedi =
              giorni.substr(3, 1) === " "
                ? 0
                : giorni.substr(3, 1) === "M"
                ? 1
                : giorni.substr(3, 1) === "P"
                ? 2
                : 3;
            this.venerdi =
              giorni.substr(4, 1) === " "
                ? 0
                : giorni.substr(4, 1) === "M"
                ? 1
                : giorni.substr(4, 1) === "P"
                ? 2
                : 3;
            if (giorni.trim() !== "") {
              this.giorniChiusura = true;
            }
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
            this.preavvisare = false;
            this.nazione_ritiro_selezionata = res.data.trcnaz;
            this.nazione = { value: res.data.trcnaz };
            this.readonlyD = false;
            this.disattivaRubrica = true;
            this.disattivaRubrica2 = false;
            this.readonly = true;
            this.facchinaggioConsegna = false;
            this.aperturaLateraleConsegna = false;
            this.transpalletConsegna = false;
            this.furgoneConsegna = false;
            this.servizioPianoConsegna = false;
            this.spondaConsegna = false;
            this.cantinaConsegna = false;
            var naz = res.data.trcnaz != "" ? res.data.trcnaz : "I";
            var val = res.data.trcrgs.substr(0, 40).replace("''", "'");
            var element = {
              ragioneSociale: res.data.trcrgs.substr(0, 40).replace("''", "'"),
              indirizzo: res.data.trcind.substr(0, 40).replace("''", "'"),
              numciv: "",
              comune: res.data.trcloc.substr(0, 30).replace("''", "'"),
              provincia: res.data.trcprv.replace("''", "'").substr(0, 2),
              capestero: res.data.trccap.replace("''", "'").substr(0, 5),
              cap: res.data.trccap.replace("''", "'").substr(0, 5),
              nazione: naz,
            };
            this.autocompletamento[val] = element;
            this.autocomplete.push({ value: val, text: val });
            this.RagioneSocialeRitiro = { value: val, text: val };
            this.idModificaRitiro = "AZIENDA";
            this.$refs.provincia_ritiro.focus();
            this.$refs.riferimento_ritiro.focus();
          });
        this.overlay = false;
      } else if (event === 2) {
        this.fermoDeposito = false;
        this.spedDaRitirare = "";
        this.flagRitiroContest = false;
        this.MDM = "08:00";
        this.MAM = "13:00";
        this.PDP = "13:30";
        this.PAP = "18:00";
        this.giorniChiusura = false;
        this.NazioneDestinoFinaleScelta = "";
        this.MostraMultidestinatario = false;
        if (
          this.isCorrisEstero.trim() === "S" &&
          this.corrispondenteSpeciale === "N"
        ) {
          this.divisioneSchermo = 2;
        }
        this.CheckPermessoVolume(2);
        this.TipoRitiro = "A";
        this.RagioneSocialeRitiro = { text: "", value: 0 };
        this.tempRagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.ProvinciaRitiro = "";
        this.nazione_ritiro_selezionata = "I";
        this.nazione = { value: "I" };
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.lunedi = 0;
        this.martedi = 0;
        this.mercoledi = 0;
        this.giovedi = 0;
        this.venerdi = 0;
        this.destinatarioSelezionato = { value: 1 };
        this.mostraDestinatario = true;
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = true;
        this.readonlyD = true;
        this.Monodestinatario = true;
        this.readonlyMono = true;
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.ProvinciaDestinatarioValida = true;
        this.ragsocDestinoFinale = "";
        this.indirizzoDestinoFinale = "";
        this.nazioneDestinoFinale = "";
        this.comuneDestinoFinale = "";
        this.capDestinoFinale = "";
        this.provinciaDestinoFinale = "";
        this.merce = "";
        if (this.axios.defaults.headers.common["Language"] == "it-IT") {
          this.Destinatario = [{ text: "Monodestinatario", value: 1 }];
        } else {
          this.Destinatario = [{ text: "Single consignee", value: 1 }];
        }
        this.Destinatari = 1;
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/ArGestioneUtenzas/GetAnagrafica",
            {}
          )
          .then((res) => {
            this.IndirizzoDestinatario = res.data.trcind
              .substr(0, 40)
              .replace("''", "'");
            this.LocalitaDestinatario = res.data.trcloc
              .substr(0, 30)
              .replace("''", "'");
            this.ProvinciaDestinatario = res.data.trcprv
              .substr(0, 2)
              .replace("''", "'");
            this.CapDestinatario = res.data.trccap
              .replace("''", "'")
              .substr(0, 5);
            this.nazione_destino_selezionata = res.data.trcnaz;
            this.nazione_dest = { value: res.data.trcnaz };
            this.RiferimentoRitiro = "";
            this.TelefonoRitiro = "";
            var val = res.data.trcrgs.substr(0, 40).replace("''", "'");
            var naz = res.data.trcnaz != "" ? res.data.trcnaz : "I";
            var element = {
              ragioneSociale: res.data.trcrgs.substr(0, 40).replace("''", "'"),
              indirizzo: res.data.trcind.substr(0, 40).replace("''", "'"),
              numciv: "",
              comune: res.data.trcloc.substr(0, 30).replace("''", "'"),
              provincia: res.data.trcprv.replace("''", "'").substr(0, 2),
              capestero: res.data.trccap.replace("''", "'").substr(0, 5),
              cap: res.data.trccap.replace("''", "'").substr(0, 5),
              nazione: naz,
            };
            this.autocompletamento[val] = element;
            this.autocompleteConsegna.push({ value: val, text: val });
            this.RagioneSocialeDestinatario = { value: val, text: val };
            this.idModificaDestinatario = "AZIENDA";
            this.$refs.provincia_consegna.focus();
            this.$refs.ragione_sociale_ritiro.focus();
          });
      } else if (event === 3) {
        this.fermoDeposito = false;
        this.spedDaRitirare = "";
        this.flagRitiroContest = false;
        this.MDM = "08:00";
        this.MAM = "13:00";
        this.PDP = "13:30";
        this.PAP = "18:00";
        this.giorniChiusura = false;
        this.NazioneDestinoFinaleScelta = "";
        this.divisioneSchermo = 1;
        this.CheckPermessoVolume(3);
        this.TipoRitiro = "T";
        this.RagioneSocialeRitiro = { text: "", value: 0 };
        this.tempRagioneSocialeRitiro = "";
        this.IndirizzoRitiro = "";
        this.LocalitaRitiro = "";
        this.ProvinciaRitiro = "";
        this.CAPRitiro = "";
        this.lunedi = 0;
        this.martedi = 0;
        this.mercoledi = 0;
        this.giovedi = 0;
        this.venerdi = 0;
        this.nazione_ritiro_selezionata = "I";
        this.nazione = { value: "I" };
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.readonly = false;
        this.RagioneSocialeDestinatario = { text: "", value: 0 };
        this.tempRagioneSocialeConsegna = "";
        this.IndirizzoDestinatario = "";
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.CapDestinatario = "";
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.readonly = false;
        this.disattivaRubrica = false;
        this.disattivaRubrica2 = false;
        this.readonlyD = false;
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.ragsocDestinoFinale = "";
        this.indirizzoDestinoFinale = "";
        this.nazioneDestinoFinale = "";
        this.comuneDestinoFinale = "";
        this.capDestinoFinale = "";
        this.provinciaDestinoFinale = "";
        this.merce = "";

        // Controllo se possibile selezionare destinatari multipli per triangolazione 17/10
        this.axios
          .get(this.$store.state.servicePath + "/api/Wbrit10f/DestVarie")
          .then((res) => {
            if (res.data == "S") {
              this.MostraMultidestinatario = true;
              this.mostraDestinatario = false;
              this.destinatarioSelezionato = { value: 0 };
              this.Destinatari = 0;
              this.Monodestinatario = false;
              this.Multidestinatario = false;
              this.readonlyMono = false;
              if (this.axios.defaults.headers.common["Language"] == "it-IT") {
                this.Destinatario = this.Destinatario_it;
              } else {
                this.Destinatario = this.Destinatario_en;
              }
            } else {
              this.MostraMultidestinatario = false;
              this.mostraDestinatario = true;
              this.destinatarioSelezionato = { value: 1 };
              this.Destinatari = 1;
              this.Monodestinatario = true;
              this.readonlyMono = true;
              if (this.axios.defaults.headers.common["Language"] == "it-IT") {
                this.Destinatario = [{ text: "Monodestinatario", value: 1 }];
              } else {
                this.Destinatario = [{ text: "Single consignee", value: 1 }];
              }
            }
            this.overlay = false;
          })
          .catch((err) => {
            this.erroreInserimento = "";
            this.overlay = false;
            this.dialogErrore = true;
            this.erroreInserimento = this.$t("key506");
          });
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    SetData() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/Festivo/" +
            this.DataRitiro
        )
        .then((res) => {
          if (res.data == true) {
            this.text = this.$t("key563");
            this.erroreCalendario = true;
          } else {
            this.$refs.dialog.save(this.DataRitiro);
            this.modal = false;
            this.$refs.mdmText.focus();
          }
        });
    },
    SetDataConsegna() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArRitiriWebs/Festivo/" +
            this.data_partenza
        )
        .then((res) => {
          if (res.data == true) {
            this.text = this.$t("key563");
            this.erroreCalendario = true;
          } else {
            this.$refs.dialog2.save(this.data_partenza);
            this.modal = false;
            this.CheckEspresso();
          }
        });
    },
    GetOraMattinoDalle(event) {
      this.MattinoDalle = event;
    },
    GetOraMattinoAlle(event) {
      this.MattinoAlle = event;
    },
    GetOraPomeriggioDalle(event) {
      this.PomeriggioDalle = event;
    },
    GetOraPomeriggioAlle(event) {
      this.PomeriggioAlle = event;
    },
    GetTassativa(event) {
      if (event === 1) {
        this.Tassativa = "I";
      } else if (event === 2) {
        this.Tassativa = "T";
      }
    },
    GetTipoServizio(event) {
      this.TipoServizioScelto = event;
    },
    GetMisure(event) {
      if (event === 1) {
        this.numeroDettagli = parseInt(this.NumeroBancali);
        this.MostraDettagli = true;
      } else {
        this.numeroDettagli = 0;
        this.MostraDettagli = false;
      }
    },
    GetDestinatario(event) {
      if (event === 2) {
        this.Destinatari = 2;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.preavvisoConsegnaObbligatorio = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        //togliere le flag ai servizi di consegna
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.espresso = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.RagioneSocialeDestinatario = { text: "", value: "" };
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.CapDestinatario = "";
        this.NazioneDestinoFinaleScelta = "";
        this.Multidestinatario = true;
      } else if (event === 1) {
        this.Destinatari = 1;
        this.Monodestinatario = true;
        this.mostraDestinatario = true;
        if (this.TipoRitiro !== "A") this.disattivaRubrica2 = false;
      } else if (event === 0) {
        this.Monodestinatario = false;
        this.Multidestinatario = false;
        this.mostraDestinatario = false;
        this.disattivaRubrica2 = true;
        this.Destinatari = 0;
        this.nazione_destino_selezionata = "I";
        this.nazione_dest = { value: "I" };
        this.nominativoConsegna = "";
        this.preavvisoConsegna = false;
        this.telefonoConsegna = "";
        this.mailConsegna = "";
        this.fermoDeposito = false;
        this.riferimentoRitiro = "";
        this.rifclienteEstero = "";
        //togliere le flag ai servizi di consegna
        this.facchinaggioConsegna = false;
        this.aperturaLateraleConsegna = false;
        this.transpalletConsegna = false;
        this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        this.spondaConsegna = false;
        this.cantinaConsegna = false;
        this.espresso = false;
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.RagioneSocialeDestinatario = { text: "", value: "" };
        this.LocalitaDestinatario = "";
        this.ProvinciaDestinatario = "";
        this.IndirizzoDestinatario = "";
        this.CapDestinatario = "";
        this.NazioneDestinoFinaleScelta = "";
      }
    },
    getDataFromService() {
      this.loading = true;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArRubricaClientis")
        .then((res) => {
          this.items = [];
          this.itemsBase = [];
          res.data.forEach((el) => {
            el.ragioneSociale = el.ragioneSociale.replace("''", "'");
            el.indirizzo = el.indirizzo.replace("''", "'");
            el.cap = el.cap.replace("''", "'");
            el.comune = el.comune.replace("''", "'");
            el.provincia = el.provincia.replace("''", "'");
            this.items.push(el);
            this.itemsBase.push(el);
          });
          //this.CheckPermessoVolume(1);
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
    },
    CheckPreavviso() {
      this.preavvisoObbligatorio = this.preavvisare;
      if (this.preavvisoObbligatorio) {
        if (this.listaRiferimentiRitiro.length === 2) {
          this.riferimentiRitiro = true;
        }
        if (this.listaRiferimentiRitiro.length === 1) {
          this.RiferimentoRitiro = this.listaRiferimentiRitiro[0].ref;
          this.TelefonoRitiro = this.listaRiferimentiRitiro[0].cell;
          if (
            this.TelefonoRitiro === null ||
            this.TelefonoRitiro === undefined
          ) {
            this.TelefonoRitiro = "";
          }
          this.email = this.listaRiferimentiRitiro[0].mail;
          if (this.email === null || this.email === undefined) {
            this.email = "";
          }
          if (this.email !== "") {
            this.FocusMail();
          } else {
            this.$refs.telefono_ritiro.focus();
          }
        }
      }
    },
    FocusMail() {
      this.$refs.mail_ritiro.focus();
      this.$refs.telefono_ritiro.focus();
    },
    selezionaRecord(item) {
      var id = item.id;
      var codiceAzienda = item.codiceAzienda;
      this.nominativoConsegna = "";
      this.telefonoConsegna = "";
      this.preavvisoConsegnaObbligatorio = false;
      this.preavvisoConsegna = false;
      this.mailConsegna = "";
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.idModificaDestinatario = res.data.id;
          this.RagioneSocialeDestinatario = {
            text: res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase(),
            value: res.data.id,
          };
          this.autocompleteConsegna = [];
          this.autocompleteConsegna.push(this.RagioneSocialeDestinatario);
          this.autocompletamento[
            res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase()
          ] = res.data;
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          var numciv =
            res.data.numciv != null &&
            res.data.numciv != undefined &&
            res.data.numciv != "null"
              ? res.data.numciv
              : "";
          this.IndirizzoDestinatario =
            res.data.indirizzo.replace("''", "'").trim() + " " + numciv.trim();
          this.IndirizzoDestinatario = this.IndirizzoDestinatario.substring(
            0,
            40
          ).trim();
          this.LocalitaDestinatario = res.data.comune
            .replace("''", "'")
            .substring(0, 30);
          this.ProvinciaDestinatario = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CapDestinatario = res.data.cap
            .replace("''", "'")
            .substring(0, 5);
          this.destinatarioSelezionato = { value: 1 };
          this.mostraDestinatario = true;
          this.nazione_dest = { value: naz };
          this.nazione_destino_selezionata = naz;
          if (res.data.referente !== "" && res.data.referente !== null) {
            this.nominativoConsegna = res.data.referente
              .substr(0, 17)
              .replace("''", "'");
          } else if (
            res.data.referente2 !== "" &&
            res.data.referente2 != null
          ) {
            this.nominativoConsegna = res.data.referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          this.telefonoConsegna = res.data.telefono.replace("''", "'");
          this.listaRiferimentiConsegna = [];
          if (
            (res.data.referente !== "" && res.data.referente !== null) ||
            (res.data.telefono !== "" && res.data.telefono !== null) ||
            (res.data.email !== "" && res.data.email !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";
            if (res.data.referente === null) referente = "";
            else {
              referente = res.data.referente.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono === null) telefono = "";
            else {
              telefono = res.data.telefono.replace("''", "'");
            }
            if (res.data.email === null) email = "";
            else {
              email = res.data.email.replace("''", "'");
            }
            this.listaRiferimentiConsegna.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          if (
            (res.data.referente2 !== "" && res.data.referente2 !== null) ||
            (res.data.telefono2 !== "" && res.data.telefono2 !== null) ||
            (res.data.email2 !== "" && res.data.email2 !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";
            if (res.data.referente2 === null) referente = "";
            else {
              referente = res.data.referente2.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono2 === null) telefono = "";
            else {
              telefono = res.data.telefono2.replace("''", "'");
            }
            if (res.data.email2 === null) email = "";
            else {
              email = res.data.email2.replace("''", "'");
            }
            this.listaRiferimentiConsegna.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          this.$refs.provincia_consegna.focus();
          this.$refs.indirizzo_consegna.focus();
          this.Rubrica = false;
        });
    },
    selezionaRecordRitiro(item) {
      var id = item.id;
      var codiceAzienda = item.codiceAzienda;
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.giorniChiusura = false;
      this.preavvisare = false;
      this.preavvisoObbligatorio = false;
      this.RiferimentoRitiro = "";
      this.TelefonoRitiro = "";
      this.email = "";
      this.axios
        .post(
          this.$store.state.servicePath + "/api/ArRubricaClientis/Cerca/" + id
        )
        .then((res) => {
          this.idModificaRitiro = res.data.id;
          this.RagioneSocialeRitiro = {
            text: res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase(),
            value: res.data.id,
          };
          this.autocomplete = [];
          this.autocomplete.push(this.RagioneSocialeRitiro);
          this.autocompletamento[
            res.data.ragioneSociale
              .replace("''", "'")
              .substring(0, 40)
              .toUpperCase()
          ] = res.data;
          var naz = res.data.nazione;
          if (naz == null || naz == undefined || naz == "") naz = "I";
          this.nazione_ritiro_selezionata = naz;
          this.nazione = { value: naz };
          var numciv =
            res.data.numciv != null &&
            res.data.numciv != undefined &&
            res.data.numciv != "null"
              ? res.data.numciv
              : "";
          this.IndirizzoRitiro =
            res.data.indirizzo.replace("''", "'").trim() + " " + numciv;
          this.IndirizzoRitiro = this.IndirizzoRitiro.substring(0, 40).trim();
          this.LocalitaRitiro = res.data.comune
            .replace("''", "'")
            .substring(0, 30)
            .trim();
          this.ProvinciaRitiro = res.data.provincia
            .replace("''", "'")
            .substring(0, 2);
          this.CAPRitiro = res.data.cap.replace("''", "'").substring(0, 5);
          var giorni = res.data.giorniAperturaSettimanali;
          if (giorni.trim() != "") {
            this.giorniChiusura = true;
            this.lunedi =
              giorni.substring(0, 1) === " "
                ? 0
                : giorni.substr(0, 1) === "M"
                ? 1
                : giorni.substr(0, 1) === "P"
                ? 2
                : 3;
            this.martedi =
              giorni.substr(1, 1) === " "
                ? 0
                : giorni.substr(1, 1) === "M"
                ? 1
                : giorni.substr(1, 1) === "P"
                ? 2
                : 3;
            this.mercoledi =
              giorni.substr(2, 1) === " "
                ? 0
                : giorni.substr(2, 1) === "M"
                ? 1
                : giorni.substr(2, 1) === "P"
                ? 2
                : 3;
            this.giovedi =
              giorni.substr(3, 1) === " "
                ? 0
                : giorni.substr(3, 1) === "M"
                ? 1
                : giorni.substr(3, 1) === "P"
                ? 2
                : 3;
            this.venerdi =
              giorni.substr(4, 1) === " "
                ? 0
                : giorni.substr(4, 1) === "M"
                ? 1
                : giorni.substr(4, 1) === "P"
                ? 2
                : 3;
          }
          if (res.data.referente !== "" && res.data.referente !== null) {
            this.RiferimentoRitiro = res.data.referente
              .substr(0, 17)
              .replace("''", "'");
          } else if (
            res.data.referente2 !== "" &&
            res.data.referente2 != null
          ) {
            this.RiferimentoRitiro = res.data.referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (res.data.telefono !== "" && res.data.telefono !== null) {
            this.TelefonoRitiro = res.data.telefono.replace("''", "'");
          } else if (res.data.telefono2 !== "" && res.data.telefono2 !== null) {
            this.TelefonoRitiro = res.data.telefono2.replace("''", "'");
          }
          var el = res.data;
          if (
            el.oraAperturaMattino != null &&
            el.oraAperturaMattino != undefined &&
            el.oraAperturaMattino != ""
          )
            this.MDM =
              el.oraAperturaMattino.substring(0, 2) +
              ":" +
              el.oraAperturaMattino.substring(2, 4);
          else this.MDM = "08:00";
          if (
            el.oraChiusuraMattino != null &&
            el.oraChiusuraMattino != undefined &&
            el.oraChiusuraMattino != ""
          )
            this.MAM =
              el.oraChiusuraMattino.substring(0, 2) +
              ":" +
              el.oraChiusuraMattino.substring(2, 4);
          else this.MAM = "13:00";
          if (
            el.oraAperturaPomeriggio != null &&
            el.oraAperturaPomeriggio != undefined &&
            el.oraAperturaPomeriggio != ""
          )
            this.PDP =
              el.oraAperturaPomeriggio.substring(0, 2) +
              ":" +
              el.oraAperturaPomeriggio.substring(2, 4);
          else this.PDP = "13:30";
          if (
            el.oraChiusuraPomeriggio != null &&
            el.oraChiusuraPomeriggio != undefined &&
            el.oraChiusuraPomeriggio != ""
          )
            this.PAP =
              el.oraChiusuraPomeriggio.substring(0, 2) +
              ":" +
              el.oraChiusuraPomeriggio.substring(2, 4);
          else this.PAP = "18:00";
          this.listaRiferimentiRitiro = [];
          if (
            (res.data.referente !== "" && res.data.referente !== null) ||
            (res.data.telefono !== "" && res.data.telefono !== null) ||
            (res.data.email !== "" && res.data.email !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";
            if (res.data.referente === null) referente = "";
            else {
              referente = res.data.referente.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono === null) telefono = "";
            else {
              telefono = res.data.telefono.replace("''", "'");
            }
            if (res.data.email === null) email = "";
            else {
              email = res.data.email.replace("''", "'");
            }
            this.listaRiferimentiRitiro.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          if (
            (res.data.referente2 !== "" && res.data.referente2 !== null) ||
            (res.data.telefono2 !== "" && res.data.telefono2 !== null) ||
            (res.data.email2 !== "" && res.data.email2 !== null)
          ) {
            var referente = "";
            var telefono = "";
            var email = "";
            if (res.data.referente2 === null) referente = "";
            else {
              referente = res.data.referente2.substr(0, 17).replace("''", "'");
            }
            if (res.data.telefono2 === null) telefono = "";
            else {
              telefono = res.data.telefono2.replace("''", "'");
            }
            if (res.data.email2 === null) email = "";
            else {
              email = res.data.email2.replace("''", "'");
            }
            this.listaRiferimentiRitiro.push({
              ref: referente,
              cell: telefono,
              mail: email,
            });
          }
          this.RubricaRitiro = false;
          this.$refs.provincia_ritiro.focus();
          this.$refs.indirizzo_ritiro.focus();
        });
    },
    Reset() {
      //azzero i colori della validazione sui campi
      this.spedDaRitirare = "";
      this.flagRitiroContest = false;
      this.nazioneDestinoFinaleColore = "";
      this.corrispondenteSpeciale = "N";
      this.NazioneDestinoFinaleScelta = "";
      this.destinoFinaleColore = "";
      this.ragsocDestinoFinale = "";
      this.indirizzoDestinoFinale = "";
      this.nazioneDestinoFinale = "";
      this.comuneDestinoFinale = "";
      this.capDestinoFinale = "";
      this.provinciaDestinoFinale = "";
      this.RagioneSocialeRitiroColore = "";
      this.IndirizzoRitiroColore = "";
      this.LocalitaRitiroColore = "";
      this.CAPRitiroColore = "";
      this.ProvinciaRitiroColore = "";
      this.RiferimentoRitiroColore = "";
      this.TelefonoRitiroColore = "";
      this.emailColore = "";
      this.MDMColore = "";
      this.MAMColore = "";
      this.PDPColore = "";
      this.PAPColore = "";
      this.NumeroColliColore = "";
      this.NumeroBancaliColore = "";
      this.VolumeColore = "";
      this.errorePeso = "";
      this.LunghezzaMerceColore = "";
      this.assicurataColore = "";
      this.RagioneSocialeDestinatarioColore = "";
      this.IndirizzoDestinatarioColore = "";
      this.LocalitaDestinatarioColore = "";
      this.CapDestinatarioColore = "";
      this.ProvinciaDestinatarioColore = "";
      this.nominativoConsegnaColore = "";
      this.telefonoConsegnaColore = "";
      this.mailConsegnaColore = "";
      this.riferimentoRitiroColore = "";
      this.rifclienteEsteroColore = "";
      this.destinoFinaleColore = "";
      //
      this.divisioneSchermo = 1;
      this.isCorrisEstero = "";
      this.obbligoRifEstero = "";
      this.obbligoVolumeInter = 0;
      this.axios
        .get(this.$store.state.servicePath + "/api/ArCorrispondentis")
        .then((res) => {
          this.corrispondenteSpeciale = res.data;
          this.axios
            .get(this.$store.state.servicePath + "/api/Wbrit10f/Corris")
            .then((res) => {
              this.isCorrisEstero = res.data;
              this.axios
                .get(
                  this.$store.state.servicePath + "/api/Wbrit10f/ObbligoRifFat"
                )
                .then((res) => {
                  this.obbligoRifEstero = res.data;
                  this.GetNazioni();
                  this.GetTipoRitiro(1);
                })
                .catch((err) => {
                  this.erroreInserimento = "";
                  this.overlay = false;
                  this.dialogErrore = true;
                  this.erroreInserimento = this.$t("key506");
                  //this.erroreInserimento = "Non è stato possibile scaricare i dati per inizializzare la form. Si prega di effettuare nuovamente il login e riprovare.";
                })
                .catch((err) => {
                  this.erroreInserimento = "";
                  this.overlay = false;
                  this.dialogErrore = true;
                  this.erroreInserimento = this.$t("key506");
                });
            });
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
      this.ragsocDestinoFinale = "";
      this.indirizzoDestinoFinale = "";
      this.comuneDestinoFinale = "";
      this.capDestinoFinale = "";
      this.provinciaDestinoFinale = "";
      this.numeroRitiroAggiunto = "";
      this.ProvinciaValida = true;
      this.ProvinciaDestinatarioValida = true;
      this.$refs.Ritiri.resetValidation();
      this.TipoRitiroSelezionato = 1;
      this.mostraDestinatario = false;
      this.disattivaRubrica2 = true;
      this.TipoRitiro = "F";
      this.GetTipoRitiro(1);
      this.RiferimentoRitiro = "";
      this.preavvisare = false;
      this.TelefonoRitiro = "";
      this.email = "";
      this.axios
        .get(this.$store.state.servicePath + "/api/Wbrit10f/GetDataOggi")
        .then((res) => {
          this.dataDiOggi = res.data.toString().substring(0, 8);
          this.ore = res.data.toString().substring(8, 10);
          this.minutes = res.data.toString().substring(10, 12);
        })
        .catch((err) => {
          var fil = "00";
          var giorno =
            fil.substring(
              0,
              fil.length - new Date().getUTCDate().toString().length
            ) + new Date().getUTCDate().toString();
          var mese =
            fil.substring(
              0,
              fil.length - (new Date().getUTCMonth() + 1).toString().length
            ) + (new Date().getUTCMonth() + 1).toString();
          this.dataDiOggi = new Date().getFullYear().toString() + mese + giorno;
          this.ore = new Date().getHours().toLocaleString("it-IT");
          this.minutes = new Date().getMinutes().toLocaleString("it-IT");
        });
      //   if (Number(ore) > 12 || (Number(ore) === 12 && Number(minutes) > 30)) {
      //   this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 1));
      //     this.minPartenza = this.addDays(new Date(), 0)
      //     .toISOString()
      //     .substr(0, 10);
      //     this.minPartenzaConsegna = this.addDays(new Date(), 2)
      //     .toISOString()
      //     .substr(0, 10);
      //   }
      //  else {
      this.minPartenza = this.addDays(new Date(), 0)
        .toISOString()
        .substr(0, 10);
      this.DataRitiro = this.checkGiorno(this.addDays(new Date(), 0));
      this.minPartenzaConsegna = this.addDays(new Date(), 1)
        .toISOString()
        .substr(0, 10);
      // }
      this.maxPartenza = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.maxPartenzaConsegna = this.addDays(new Date(), 30)
        .toISOString()
        .substr(0, 10);
      this.Indi = true;
      this.Tass = false;
      this.MDM = "08:00";
      this.MAM = "13:00";
      this.PDP = "13:30";
      this.PAP = "18:00";
      this.soloMattino = false;
      this.soloPomeriggio = false;
      this.giorniChiusura = false;
      this.lunedi = 0;
      this.martedi = 0;
      this.mercoledi = 0;
      this.giovedi = 0;
      this.venerdi = 0;
      this.aperturaLaterale = false;
      this.facchinaggioRitiro = false;
      this.mezzoPiccolo = false;
      this.transpalletRitiro = false;
      this.pianoRitiro = false;
      this.spondaRitiro = false;
      this.mezzoCoibentatoRitiro = false;
      this.buonoPresaRitiro = false;
      this.verticaleRitiro = false;
      this.cintureSicureRitiro = false;
      this.NumeroColli = "";
      this.PesoMerce = "";
      this.NumeroBancali = "";
      this.Volume = "";
      this.assicurata = "";
      this.TipoServizioSelezionato = { value: 0 };
      this.TipoServizioScelto = 0;
      this.fragile = false;
      this.adr = false;
      this.sovrapponibile = false;
      this.LunghezzaMerce = "";
      this.misuraScelta = 0;
      this.lunlar = false;
      this.altez = false;
      this.numeroDettagli = 0;
      this.MostraDettagli = false;
      this.destinatarioSelezionato = { value: 0 };
      this.Destinatari = 0;
      this.MisureSelezionate = { value: 1 };
      this.Monodestinatario = false;
      this.Multidestinatario = false;
      this.RagioneSocialeDestinatario = "";
      this.IndirizzoDestinatario = "";
      this.LocalitaDestinatario = "";
      this.CapDestinatario = "";
      this.ProvinciaDestinatario = "";
      this.nazione_destino_selezionata = "I";
      this.nazione_dest = { value: "I" };
      this.nominativoConsegna = "";
      this.preavvisoConsegna = false;
      this.telefonoConsegna = "";
      this.mailConsegna = "";
      this.fermoDeposito = false;
      this.riferimentoRitiro = "";
      this.rifclienteEstero = "";
      this.facchinaggioConsegna = false;
      this.aperturaLateraleConsegna = false;
      this.transpalletConsegna = false;
      this.furgoneConsegna = false;
      this.espresso = false;
      this.servizioPianoConsegna = false;
      this.spondaConsegna = false;
      this.cantinaConsegna = false;
      this.concordataConsegnaTassativa = false;
      this.tassativaConsegnaSelezionata = { value: -1 };
      this.consegnaTassativa = -1;
      this.data_partenza = "";
      this.MisureBancali = [];
      this.idModificaDestinatario = "AZIENDA";
      this.idModificaRitiro = "AZIENDA";
      this.modificaDestinatario = false;
      this.modificaRitiro = false;
      this.rifer = [];
      this.riferCon = [];
      this.overlay = true;
      this.GetNazioni();
      this.GetTipoRitiro(1);
      this.getDataFromService();
      this.stopSubmit = false;
      this.merce = "";
      // this.CheckPermessoVolume();
    },
    CheckPermessoVolume(tipoRitiro) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Wbrit10f/GetObbligoVolume/" +
            tipoRitiro
        )
        .then((res) => {
          this.obbligoVolume = res.data;
          this.overlay = false;
        })
        .catch((err) => {
          this.erroreInserimento = "";
          this.overlay = false;
          this.dialogErrore = true;
          this.erroreInserimento = this.$t("key506");
        });
    },
    controllaModifiche() {
      if (this.idModificaRitiro === 0) {
        this.modificaRitiro = true;
      }
      if (this.idModificaDestinatario === 0 && this.Monodestinatario) {
        this.modificaDestinatario = true;
      }

      if (this.idModificaRitiro !== 0 && this.idModificaRitiro !== "AZIENDA") {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/Cerca/" +
              this.idModificaRitiro
          )
          .then((res) => {
            var civico = "";
            if (res.data.numciv !== null) {
              if (res.data.numciv.trim() !== "") {
                civico = " " + res.data.numciv.trim();
              }
            }
            var id = res.data.id;
            var ragSoc =
              res.data.ragioneSociale !== null
                ? res.data.ragioneSociale.trim().replace("''", "'")
                : "";
            var indirizzo =
              res.data.indirizzo !== null
                ? res.data.indirizzo.trim().replace("''", "'")
                : "";
            indirizzo += civico.replace("''", "'");
            var cap =
              res.data.cap !== null
                ? res.data.cap.trim().replace("''", "'")
                : "";
            var localita =
              res.data.comune !== null
                ? res.data.comune.trim().replace("''", "'")
                : "";
            // aggiungo il salvataggio degli orari se è la prima volta che lo carico dalla Rubrica
            if (res.data.status !== 1 && res.data.status !== "1") {
              j = {
                id: res.data.id,
                clientId: res.data.clientId,
                ragioneSociale: res.data.ragioneSociale,
                indirizzo: res.data.indirizzo,
                numciv: res.data.numciv,
                cap: res.data.cap,
                comune: res.data.comune,
                provincia: res.data.provincia,
                referente: res.data.referente,
                email: res.data.email,
                telefono: res.data.telefono,
                oraAperturaMattino: this.MDM.replace(":", ""),
                oraChiusuraMattino: this.MAM.replace(":", ""),
                oraAperturaPomeriggio: this.PDP.replace(":", ""),
                oraChiusuraPomeriggio: this.PAP.replace(":", ""),
                giorniAperturaSettimanali: res.data.giorniAperturaSettimanali,
                referente2: res.data.referente2,
                email2: res.data.email2,
                telefono2: res.data.telefono2,
                capEstero: res.data.capEstero,
                nazione: res.data.nazione,
                status: 1,
                lastAction: "",
                codiceAzienda: "",
              };
              this.axios.put(
                this.$store.state.servicePath + "/api/ArRubricaClientis/",
                j
              );
            }
            if (
              this.RagioneSocialeRitiro.text.trim().toUpperCase() !==
                ragSoc.toUpperCase() ||
              this.IndirizzoRitiro.trim().toUpperCase() !==
                indirizzo.toUpperCase() ||
              this.CAPRitiro.trim().toUpperCase() !== cap.toUpperCase() ||
              this.LocalitaRitiro.trim().toUpperCase() !==
                localita.toUpperCase()
            ) {
              this.modificaRitiro = true;
            } else {
              if (
                this.listaRiferimentiRitiro.length < 2 &&
                this.RiferimentoRitiro.trim() !== ""
              ) {
                var j = {};
                this.rifer.push({
                  ref: this.RiferimentoRitiro.trim(),
                  cell: this.TelefonoRitiro.trim(),
                  mail: this.email.trim(),
                });
                if (this.listaRiferimentiRitiro.length === 0) {
                  var giochi = "";
                  giochi +=
                    this.lunedi === 0
                      ? " "
                      : this.lunedi === 1
                      ? "M"
                      : this.lunedi === 2
                      ? "P"
                      : "T";
                  giochi +=
                    this.martedi === 0
                      ? " "
                      : this.martedi === 1
                      ? "M"
                      : this.martedi === 2
                      ? "P"
                      : "T";
                  giochi +=
                    this.mercoledi === 0
                      ? " "
                      : this.mercoledi === 1
                      ? "M"
                      : this.mercoledi === 2
                      ? "P"
                      : "T";
                  giochi +=
                    this.giovedi === 0
                      ? " "
                      : this.giovedi === 1
                      ? "M"
                      : this.giovedi === 2
                      ? "P"
                      : "T";
                  giochi +=
                    this.venerdi === 0
                      ? " "
                      : this.venerdi === 1
                      ? "M"
                      : this.venerdi === 2
                      ? "P"
                      : "T";
                  j = {
                    id: res.data.id,
                    clientId: res.data.clientId,
                    ragioneSociale: res.data.ragioneSociale,
                    indirizzo: res.data.indirizzo,
                    numciv: res.data.numciv,
                    cap: res.data.cap,
                    comune: res.data.comune,
                    provincia: res.data.provincia,
                    referente: this.RiferimentoRitiro.replace("'", "''").substr(
                      0,
                      17
                    ),
                    email: this.email.replace("'", "''"),
                    telefono: this.TelefonoRitiro.replace("'", "''"),
                    oraAperturaMattino: res.data.oraAperturaMattino,
                    oraChiusuraMattino: res.data.oraChiusuraMattino,
                    oraAperturaPomeriggio: res.data.oraAperturaPomeriggio,
                    oraChiusuraPomeriggio: res.data.oraChiusuraPomeriggio,
                    giorniAperturaSettimanali: giochi,
                    referente2: "",
                    email2: "",
                    telefono2: "",
                    capEstero: res.data.capEstero,
                    nazione: res.data.nazione,
                    satus: res.data.status,
                    lastAction: "",
                    codiceAzienda: "",
                  };
                  this.axios.put(
                    this.$store.state.servicePath + "/api/ArRubricaClientis/",
                    j
                  );
                } else if (this.listaRiferimentiRitiro.length === 1) {
                  if (
                    !(
                      this.listaRiferimentiRitiro[0].cell ===
                        this.rifer[0].cell &&
                      this.listaRiferimentiRitiro[0].ref ===
                        this.rifer[0].ref &&
                      this.listaRiferimentiRitiro[0].mail === this.rifer[0].mail
                    )
                  ) {
                    var giochi = "";
                    giochi +=
                      this.lunedi === 0
                        ? " "
                        : this.lunedi === 1
                        ? "M"
                        : this.lunedi === 2
                        ? "P"
                        : "T";
                    giochi +=
                      this.martedi === 0
                        ? " "
                        : this.martedi === 1
                        ? "M"
                        : this.martedi === 2
                        ? "P"
                        : "T";
                    giochi +=
                      this.mercoledi === 0
                        ? " "
                        : this.mercoledi === 1
                        ? "M"
                        : this.mercoledi === 2
                        ? "P"
                        : "T";
                    giochi +=
                      this.giovedi === 0
                        ? " "
                        : this.giovedi === 1
                        ? "M"
                        : this.giovedi === 2
                        ? "P"
                        : "T";
                    giochi +=
                      this.venerdi === 0
                        ? " "
                        : this.venerdi === 1
                        ? "M"
                        : this.venerdi === 2
                        ? "P"
                        : "T";
                    j = {
                      id: res.data.id,
                      clientId: res.data.clientId,
                      ragioneSociale: res.data.ragioneSociale,
                      indirizzo: res.data.indirizzo,
                      numciv: res.data.numciv,
                      cap: res.data.cap,
                      comune: res.data.comune,
                      provincia: res.data.provincia,
                      referente: this.listaRiferimentiRitiro[0].ref,
                      email: this.listaRiferimentiRitiro[0].mail,
                      telefono: this.listaRiferimentiRitiro[0].cell,
                      oraAperturaMattino: res.data.oraAperturaMattino,
                      oraChiusuraMattino: res.data.oraChiusuraMattino,
                      oraAperturaPomeriggio: res.data.oraAperturaPomeriggio,
                      oraChiusuraPomeriggio: res.data.oraChiusuraPomeriggio,
                      giorniAperturaSettimanali: giochi,
                      referente2: this.RiferimentoRitiro.replace(
                        "'",
                        "''"
                      ).substr(0, 17),
                      email2: this.email.replace("'", "''"),
                      telefono2: this.TelefonoRitiro.replace("'", "''"),
                      capEstero: res.data.capEstero,
                      nazione: res.data.nazione,
                      satus: res.data.status,
                      lastAction: "",
                      codiceAzienda: "",
                    };
                    this.axios.put(
                      this.$store.state.servicePath + "/api/ArRubricaClientis/",
                      j
                    );
                  }
                }
              }
            }

            if (
              this.idModificaDestinatario !== 0 &&
              this.idModificaDestinatario !== "AZIENDA"
            ) {
              this.axios
                .post(
                  this.$store.state.servicePath +
                    "/api/ArRubricaClientis/Cerca/" +
                    this.idModificaDestinatario
                )
                .then((res) => {
                  var civico = "";
                  if (res.data.numciv !== null) {
                    if (res.data.numciv.trim() !== "") {
                      civico = " " + res.data.numciv.trim();
                    }
                  }
                  var ragSoc =
                    res.data.ragioneSociale !== null
                      ? res.data.ragioneSociale.trim().replace("''", "'")
                      : "";
                  var indirizzo =
                    res.data.indirizzo !== null
                      ? res.data.indirizzo.trim().replace("''", "'")
                      : "";
                  indirizzo += civico.replace("''", "'");
                  var cap =
                    res.data.cap !== null
                      ? res.data.cap.trim().replace("''", "'")
                      : "";
                  var localita =
                    res.data.comune !== null
                      ? res.data.comune.trim().replace("''", "'")
                      : "";

                  if (
                    this.RagioneSocialeDestinatario.text
                      .trim()
                      .toUpperCase() !== ragSoc.toUpperCase() ||
                    this.IndirizzoDestinatario.trim().toUpperCase() !==
                      indirizzo.toUpperCase() ||
                    this.CapDestinatario.trim().toUpperCase() !==
                      cap.toUpperCase() ||
                    this.LocalitaDestinatario.trim().toUpperCase() !==
                      localita.toUpperCase()
                  ) {
                    this.modificaDestinatario = true;
                  } else {
                    if (
                      this.listaRiferimentiConsegna.length < 2 &&
                      this.nominativoConsegna.trim() !== ""
                    ) {
                      //if(this.listaRiferimentiConsegna.length < 2){
                      var j = {};
                      //this.riferCon.push({ref: this.riferimentoConsegna.trim(), cell: this.telefonoConsegna.trim(), mail: this.mailConsegna.trim()});
                      this.riferCon.push({
                        ref: this.nominativoConsegna.trim(),
                        cell: this.telefonoConsegna.trim(),
                        mail: this.mailConsegna.trim(),
                      });
                      if (this.listaRiferimentiConsegna.length === 0) {
                        j = {
                          id: res.data.id,
                          clientId: res.data.clientId,
                          ragioneSociale: res.data.ragioneSociale,
                          indirizzo: res.data.indirizzo,
                          numciv: res.data.numciv,
                          cap: res.data.cap,
                          comune: res.data.comune,
                          provincia: res.data.provincia,
                          referente: this.nominativoConsegna
                            .replace("'", "''")
                            .substr(0, 17),
                          email: this.mailConsegna.replace("'", "''"),
                          telefono: this.telefonoConsegna.replace("'", "''"),
                          oraAperturaMattino: res.data.oraAperturaMattino,
                          oraChiusuraMattino: res.data.oraChiusuraMattino,
                          oraAperturaPomeriggio: res.data.oraAperturaPomeriggio,
                          oraChiusuraPomeriggio: res.data.oraChiusuraPomeriggio,
                          giorniAperturaSettimanali:
                            res.data.giorniAperturaSettimanali,
                          referente2: "",
                          email2: "",
                          telefono2: "",
                          capEstero: res.data.capEstero,
                          nazione: res.data.nazione,
                          satus: res.data.status,
                          lastAction: "",
                          codiceAzienda: "",
                        };
                        this.axios.put(
                          this.$store.state.servicePath +
                            "/api/ArRubricaClientis/",
                          j
                        );
                      } else if (this.listaRiferimentiConsegna.length === 1) {
                        if (
                          !(
                            this.listaRiferimentiConsegna[0].cell ===
                              this.riferCon[0].cell &&
                            this.listaRiferimentiConsegna[0].ref ===
                              this.riferCon[0].ref &&
                            this.listaRiferimentiConsegna[0].mail ===
                              this.riferCon[0].mail
                          )
                        ) {
                          j = {
                            id: res.data.id,
                            clientId: res.data.clientId,
                            ragioneSociale: res.data.ragioneSociale,
                            indirizzo: res.data.indirizzo,
                            numciv: res.data.numciv,
                            cap: res.data.cap,
                            comune: res.data.comune,
                            provincia: res.data.provincia,
                            referente: this.listaRiferimentiConsegna[0].ref,
                            email: this.listaRiferimentiConsegna[0].mail,
                            telefono: this.listaRiferimentiConsegna[0].cell,
                            oraAperturaMattino: res.data.oraAperturaMattino,
                            oraChiusuraMattino: res.data.oraChiusuraMattino,
                            oraAperturaPomeriggio:
                              res.data.oraAperturaPomeriggio,
                            oraChiusuraPomeriggio:
                              res.data.oraChiusuraPomeriggio,
                            giorniAperturaSettimanali: giochi,
                            referente2: this.nominativoConsegna
                              .replace("'", "''")
                              .substr(0, 17),
                            email2: this.mailConsegna.replace("'", "''"),
                            telefono2: this.telefonoConsegna.replace("'", "''"),
                            // referente2 : '',
                            // email2: '',
                            // telefono2: '',
                            capEstero: res.data.capEstero,
                            nazione: res.data.nazione,
                            satus: res.data.status,
                            lastAction: "",
                            codiceAzienda: "",
                          };
                          this.axios.put(
                            this.$store.state.servicePath +
                              "/api/ArRubricaClientis/",
                            j
                          );
                        }
                      }
                    }
                  }
                  if (this.modificaRitiro || this.modificaDestinatario) {
                    this.overlay = false;
                    this.salvaRitiro = true;
                  } else {
                    this.overlay = false;
                    this.RitiroAggiunto = true;
                  }
                });
            } else {
              if (this.modificaRitiro || this.modificaDestinatario) {
                this.overlay = false;
                this.salvaRitiro = true;
              } else {
                this.overlay = false;
                this.RitiroAggiunto = true;
              }
            }
          })
          .catch((err) => {
            this.overlay = false;
            this.RitiroAggiunto = true;
          });
      } else if (
        this.idModificaDestinatario !== 0 &&
        this.idModificaDestinatario !== "AZIENDA"
      ) {
        this.axios
          .post(
            this.$store.state.servicePath +
              "/api/ArRubricaClientis/Cerca/" +
              this.idModificaDestinatario
          )
          .then((res) => {
            var civico = "";
            if (res.data.numciv !== null) {
              if (res.data.numciv.trim() !== "") {
                civico = " " + res.data.numciv.trim();
              }
            }
            var ragSoc =
              res.data.ragioneSociale !== null
                ? res.data.ragioneSociale.trim().replace("''", "'")
                : "";
            var indirizzo =
              res.data.indirizzo !== null
                ? res.data.indirizzo.trim().replace("''", "'")
                : "";
            indirizzo += civico;
            var cap =
              res.data.cap !== null
                ? res.data.cap.trim().replace("''", "'")
                : "";
            var localita =
              res.data.comune !== null
                ? res.data.comune.trim().replace("''", "'")
                : "";
            if (
              this.RagioneSocialeDestinatario.text.trim().toUpperCase() !==
                ragSoc.toUpperCase() ||
              this.IndirizzoDestinatario.trim().toUpperCase() !==
                indirizzo.toUpperCase() ||
              this.CapDestinatario.trim().toUpperCase() !== cap.toUpperCase() ||
              this.LocalitaDestinatario.trim().toUpperCase() !==
                localita.toUpperCase()
            ) {
              this.modificaDestinatario = true;
            }
            if (this.modificaRitiro || this.modificaDestinatario) {
              this.overlay = false;
              this.salvaRitiro = true;
            } else {
              this.overlay = false;
              this.RitiroAggiunto = true;
            }
          });
      } else if (this.modificaRitiro || this.modificaDestinatario) {
        this.overlay = false;
        this.salvaRitiro = true;
      }
    },
    Submit() {
      if (!this.stopSubmit) {
        this.stopSubmit = true;
      } else {
        return;
      }
      if (this.notsave === 1) {
        this.overlay = false;
        this.dialogNotSave = true;
      } else {
        var ConsegnaEsclusiva = "T";
        if (this.soloMattino) ConsegnaEsclusiva = "M";
        else if (this.soloPomeriggio) ConsegnaEsclusiva = "P";
        var lunghezzaAltezza = " ";
        if (this.lunlar) lunghezzaAltezza = "L";
        else if (this.altez) lunghezzaAltezza = "H";
        var json_ritiro = {
          r1detr: this.TipoRitiro,
          r1rsmi: this.RagioneSocialeRitiro.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inmi: this.IndirizzoRitiro.replaceAll("'", "`").substr(0, 40),
          r1lcmi: this.LocalitaRitiro.replaceAll("'", "`").substr(0, 30).trim(),
          r1cpmi: this.CAPRitiro,
          r1prmi: this.ProvinciaRitiro,
          r1nzmi: this.nazione_ritiro_selezionata,
          r1rimi: this.RiferimentoRitiro.substr(0, 30),
          r1ptmi: this.preavvisare ? 1 : 0,
          r1tpmi: this.TelefonoRitiro,
          r1epmi: this.email,
          r1dtri: this.DataRitiro.replaceAll("-", ""),
          r1ttri: this.Indi ? "I" : "T",
          r1oima: this.MDM.replace(":", ""),
          r1ofma: this.MAM.replace(":", ""),
          r1oipm: this.PDP.replace(":", ""),
          r1ofpm: this.PAP.replace(":", ""),
          r1pgri: ConsegnaEsclusiva,
          r1gclu: this.lunedi,
          r1gcma: this.martedi,
          r1gcme: this.mercoledi,
          r1gcgi: this.giovedi,
          r1gcve: this.venerdi,
          r1nuco: this.NumeroColli,
          r1nuba: this.NumeroBancali,
          r1peto: this.PesoMerce,
          r1assi: this.assicurata,
          r1frag: this.fragile ? 1 : 0,
          r1radr: this.adr ? 1 : 0,
          r1nsov: this.sovrapponibile ? 1 : 0,
          r1l200: this.LunghezzaMerce,
          r1mmag: lunghezzaAltezza,
          r1tdes: this.Monodestinatario ? "1" : "2", //deve essere stringa per far funzionare la chiamata
          r1rsde: this.RagioneSocialeDestinatario.text
            .replaceAll("'", "`")
            .substr(0, 40),
          r1inde: this.IndirizzoDestinatario.replaceAll("'", "`").substr(0, 40),
          r1lcde: this.LocalitaDestinatario.replaceAll("'", "`")
            .substr(0, 30)
            .trim(),
          r1cpde: this.CapDestinatario,
          r1prde: this.ProvinciaDestinatario,
          r1nzde: this.nazione_destino_selezionata,
          r1ride: this.nominativoConsegna,
          r1ptde: this.preavvisoConsegna ? 1 : 0,
          r1tpde: this.telefonoConsegna,
          r1epde: this.mailConsegna,
          r1fede: this.fermoDeposito ? "1" : "0", //deve essere strgina per far funzionare la chiamata
          r1rrit: this.riferimentoRitiro,
          r1rcle: this.rifclienteEstero,
          r1tcon: this.concordataTassativa ? "1" : "0", //deve essere strgina per far funzionare la chiamata
          r1tdco: this.consegnaTassativa.toString(), //deve essere strgina per far funzionare la chiamata
          r1dtco:
            this.data_partenza != null && this.data_partenza != ""
              ? this.data_partenza.replaceAll("-", "")
              : 0,
          r1volm: this.Volume,
          r1ccli: "", //serve per far funzionare la chiamata
          // r1nrit: 0,
          // r1drit: 0,
          // r1nrde: 0,
          // r1nrdi: 0,
          // r1hare: "",
          // r1orin: "",
          // r1idus: 0
        };
        var json_campi_estero = {
          ragione_sociale_destino: this.ragsocDestinoFinale.replaceAll(
            "'",
            "`"
          ),
          indirizzo_destino: this.indirizzoDestinoFinale.replaceAll("'", "`"),
          comune_destino: this.comuneDestinoFinale.replaceAll("'", "`"),
          cap_destino: this.capDestinoFinale.replaceAll("'", "`"),
          provincia_destino: this.provinciaDestinoFinale.replaceAll("'", "`"),
          //aggiunto sotto estero in quanto qui vengono gestiti i valori da inserire nella Wbrix 22/02/2024
          descrizione_merce: this.merce.replaceAll("'", "`"),
        };
        var MiusreDaScrivere = [];
        this.MisureBancali.forEach((el) => {
          var json_misure = {
            r2ncol: el.numeroPallet,
            r2dlun: el.lunghezza,
            r2dlar: el.larghezza,
            r2dalt: el.altezza,
          };
          MiusreDaScrivere.push(json_misure);
        });
        var Disposizioni = [];
        if (this.aperturaLaterale)
          Disposizioni.push({ r3tdis: 1, r3cdis: "AR" });
        if (this.facchinaggioRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "RQ" });
        if (this.mezzoPiccolo) Disposizioni.push({ r3tdis: 1, r3cdis: "RK" });
        if (this.transpalletRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "RI" });
        if (this.pianoRitiro) Disposizioni.push({ r3tdis: 1, r3cdis: "RP" });
        if (this.spondaRitiro) Disposizioni.push({ r3tdis: 1, r3cdis: "77" });
        if (this.mezzoCoibentatoRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "95" });
        if (this.buonoPresaRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "BP" });
        if (this.verticaleRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "99" });
        if (this.cintureSicureRitiro)
          Disposizioni.push({ r3tdis: 1, r3cdis: "63" });
        if (this.flagRitiroContest)
          Disposizioni.push({ r3tdis: 1, r3cdis: "RC" });
        if (this.facchinaggioConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A3" });
        if (this.aperturaLateraleConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "73" });
        if (this.transpalletConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "65" });
        if (this.furgoneConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "B8" });
        if (this.servizioPianoConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A6" });
        if (this.spondaConsegna) Disposizioni.push({ r3tdis: 2, r3cdis: "43" });
        if (this.cantinaConsegna)
          Disposizioni.push({ r3tdis: 2, r3cdis: "A7" });
        if (this.espresso) Disposizioni.push({ r3tdis: 2, r3cdis: "E " });
        var json = {
          ritiro: json_ritiro,
          misure: MiusreDaScrivere,
          disposizioni: Disposizioni,
          estero: json_campi_estero,
        };
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRitiriWebs", json)
          .then((res) => {
            if (res.data != "ko") {
              this.numeroRitiroAggiunto = res.data;
              this.axios.post(
                this.$store.state.servicePath + "/api/Wbrit10f/" + res.data
              );
              if (
                this.idModificaRitiro !== "AZIENDA" ||
                this.idModificaDestinatario !== "AZIENDA"
              ) {
                this.controllaModifiche();
              } else {
                if (
                  this.idModificaRitiro === "AZIENDA" &&
                  this.idModificaDestinatario === "AZIENDA"
                ) {
                  this.overlay = false;
                  this.RitiroAggiunto = true;
                } else {
                  this.controllaModifiche();
                }
              }
            } else {
              this.stopSubmit = false;
              this.overlay = false;
              this.Errore = "";
              this.Errore = this.$t("key565");
              this.erroreValidazione = true;
            }
          })
          .catch((err) => {
            this.stopSubmit = false;
            this.overlay = false;
            this.Errore = "";
            this.Errore = this.$t("key565");
            this.erroreValidazione = true;
          });
      }
    },
    OpenCappario(cerca) {
      if (cerca !== null && cerca !== undefined) {
        if (cerca.trim() !== "") {
          this.overlay = true;
          if (cerca !== "ERRORE") {
            this.searchCappario = cerca.trim();
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/ArVwNazionis/" +
                  this.nazione_ritiro_selezionata +
                  "/" +
                  this.searchCappario
              )
              .then((res) => {
                if (res.data.length === 1) {
                  this.LocalitaRitiro = res.data[0].trploc
                    .trim()
                    .replace("''", "'");
                  this.ProvinciaRitiro = res.data[0].trpprv
                    .trim()
                    .replace("''", "'");
                  this.CAPRitiro = res.data[0].trpcap.trim().replace("''", "'");
                  this.$refs.provincia_ritiro.focus();
                  this.$refs.riferimento_ritiro.focus();
                  this.overlay = false;
                } else if (res.data.length > 1) {
                  this.lista_cappario = res.data;
                  this.Cappario = true;
                  this.overlay = false;
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
              });
          } else {
            this.erroreCappario = true;
            this.overlay = false;
          }
        } else {
          this.overlay = false;
        }
      } else {
        this.overlay = false;
      }
    },
    SelezionaCapparioRitiro(item) {
      var localita = item.trploc;
      var provincia = item.trpprv;
      var cap = item.trpcap;
      this.Cappario = false;
      if (!this.lista_comuni_ritiro.includes(localita))
        this.lista_comuni_ritiro.push(localita.trim().replace("''", "'"));
      this.LocalitaRitiro = localita.trim().replace("''", "'");
      this.ProvinciaRitiro = provincia.trim().replace("''", "'");
      this.CAPRitiro = cap.trim().replace("''", "'");
      this.$refs.provincia_ritiro.focus();
      this.$refs.riferimento_ritiro.focus();
      this.searchCappario = "";
    },
    ChiudiCappario() {
      this.Cappario = false;
      this.searchCappario = "";
      this.focusNextTick(this.$refs.cap_ritiro);
    },
    OpenCapparioDestino(cerca) {
      this.overlay = true;
      if (cerca !== null && cerca !== undefined) {
        if (cerca.trim() !== "") {
          if (cerca !== "ERRORE") {
            this.searchCapparioDestino = cerca.trim();
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/ArVwNazionis/" +
                  this.nazione_destino_selezionata +
                  "/" +
                  this.searchCapparioDestino
              )
              .then((res) => {
                if (res.data.length === 1) {
                  this.LocalitaDestinatario = res.data[0].trploc
                    .trim()
                    .replace("''", "'");
                  this.ProvinciaDestinatario = res.data[0].trpprv
                    .trim()
                    .replace("''", "'");
                  this.CapDestinatario = res.data[0].trpcap
                    .trim()
                    .replace("''", "'");
                  this.$refs.provincia_consegna.focus();
                  this.$refs.riferimento_consegna.focus();
                  this.overlay = false;
                } else if (res.data.length > 1) {
                  this.lista_cappario = res.data;
                  this.CapparioDestino = true;
                  this.overlay = false;
                } else {
                  this.overlay = false;
                }
              })
              .catch((err) => {
                this.overlay = false;
              });
          } else {
            this.overlay = false;
            this.erroreCappario = true;
          }
        } else {
          this.overlay = false;
        }
      } else {
        this.overlay = false;
      }
    },
    SelezionaCapparioDestino(item) {
      var localita = item.trploc;
      var provincia = item.trpprv;
      var cap = item.trpcap;
      this.CapparioDestino = false;
      this.LocalitaDestinatario = localita.trim().replace("''", "'");
      this.ProvinciaDestinatario = provincia.trim().replace("''", "'");
      this.CapDestinatario = cap.trim().replace("''", "'");
      this.$refs.provincia_consegna.focus();
      this.$refs.riferimento_consegna.focus();
      this.searchCapparioDestino = "";
    },
    ChiudiCapparioDestino() {
      this.CapparioDestino = false;
      this.searchCapparioDestino = "";
      this.focusNextTick(this.$refs.cap_consegna);
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    CheckPunti(value) {
      if (value === null || value === undefined) value = "0";
      if (typeof value == "number") return true;
      var result = [];
      var regcheckDots = /[.]/g;
      result = value.match(regcheckDots);
      if (result != null)
        if (result.length > 1) return false;
        else return true;
      return true;
    },
    CheckDecimali(value, num) {
      var dec = value.toString().split(".")[1];
      if (dec === null || dec === undefined) return true;
      if (dec.length > num) return false;
      else return true;
    },
    Indietro() {
      this.dialogNotSave = false;
      this.Reset();
    },
    ControlloMisure() {
      var vol = 0;
      this.MisureBancali.forEach((el) => {
        vol +=
          Number(el.numeroPallet) *
          Number(el.lunghezza) *
          Number(el.larghezza) *
          Number(el.altezza);
      });
      var volume = vol / 1000000;
      if (
        this.Volume === null ||
        this.Volume === undefined ||
        this.Volume === "" ||
        this.Volume === NaN
      )
        this.Volume = 0;
      if (volume > this.Volume) this.Volume = volume;
      // if(this.Volume < 0.01 && this.Volume !== 0)
      //   this.Volume = 0.01;
      this.Volume = Math.round(this.Volume * 100) / 100;
      var tempMisuraSelezionata;
      var tempLunMerce = 0;
      this.MisureBancali.forEach((el) => {
        if (Number(el.lunghezza) > Number(tempLunMerce)) {
          tempLunMerce = el.lunghezza;
          tempMisuraSelezionata = 1;
        }
        if (Number(el.altezza) > Number(tempLunMerce)) {
          tempLunMerce = el.altezza;
          tempMisuraSelezionata = 2;
        }
        if (Number(el.larghezza) > Number(tempLunMerce)) {
          tempLunMerce = el.larghezza;
          tempMisuraSelezionata = 1;
        }
      });
      if (
        Number(tempLunMerce) >= 200 &&
        Number(tempLunMerce) > Number(this.LunghezzaMerce)
      ) {
        this.LunghezzaMerce = tempLunMerce;
        //this.misuraSelezionata = tempMisuraSelezionata;
        this.misuraScelta = tempMisuraSelezionata;
        if (Number(this.misuraScelta) === 1) {
          this.lunlar = true;
          this.altez = false;
        } else if (Number(this.misuraScelta) === 2) {
          this.lunlar = false;
          this.altez = true;
        }
      }
    },
    AddAutocompleteRitiro() {
      var val =
        this.RagioneSocialeRitiro.text +
        " -- " +
        this.RagioneSocialeRitiro.value;
      if (
        val in this.autocompletamento &&
        this.tempRagioneSocialeRitiro === ""
      ) {
        this.tempRagioneSocialeRitiro = "";
        this.lunedi = 0;
        this.martedi = 0;
        this.mercoledi = 0;
        this.giovedi = 0;
        this.venerdi = 0;
        this.giorniChiusura = false;
        this.preavvisare = false;
        this.preavvisoObbligatorio = false;
        this.RiferimentoRitiro = "";
        this.TelefonoRitiro = "";
        this.email = "";
        this.idModificaRitiro = this.autocompletamento[val].id;
        var i = this.autocomplete.indexOf(this.RagioneSocialeRitiro);
        var elemento = this.autocompletamento[val];
        this.autocomplete.splice(i);
        this.RagioneSocialeRitiro = {
          text: val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase(),
          value: this.RagioneSocialeRitiro.value,
        };
        this.autocomplete.push(this.RagioneSocialeRitiro);
        this.autocompletamento[
          this.RagioneSocialeRitiro.text.toUpperCase() +
            " -- " +
            this.RagioneSocialeRitiro.value
        ] = elemento;
        this.tempAutocomplete[
          val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase()
        ] = elemento;
        var numeroCivico =
          this.autocompletamento[val].numciv != null
            ? this.autocompletamento[val].numciv
            : "";
        this.IndirizzoRitiro =
          this.autocompletamento[val].indirizzo.replace("''", "'").trim() +
          " " +
          numeroCivico.trim();
        this.LocalitaRitiro = this.autocompletamento[val].comune.replace(
          "''",
          "'"
        );
        this.ProvinciaRitiro = this.autocompletamento[val].provincia.replace(
          "''",
          "'"
        );
        this.$refs.provincia_ritiro.focus();
        var cap =
          this.autocompletamento[val].capestero != "" &&
          this.autocompletamento[val].capestero != null
            ? this.autocompletamento[val].capestero
            : this.autocompletamento[val].cap;
        this.CAPRitiro = cap;
        if (this.autocompletamento[val].nazione !== null) {
          this.nazione_ritiro_selezionata = this.autocompletamento[val].nazione;
          this.nazione = this.nazione_ritiro_selezionata;
        } else {
          this.nazione_ritiro_selezionata = "I";
          this.nazione = { value: "I" };
        }
        var giorni = this.autocompletamento[val].giorniAperturaSettimanali;
        if (giorni.trim() != "") {
          this.giorniChiusura = true;
          this.lunedi =
            giorni.substring(0, 1) === " "
              ? 0
              : giorni.substr(0, 1) === "M"
              ? 1
              : giorni.substr(0, 1) === "P"
              ? 2
              : 3;
          this.martedi =
            giorni.substr(1, 1) === " "
              ? 0
              : giorni.substr(1, 1) === "M"
              ? 1
              : giorni.substr(1, 1) === "P"
              ? 2
              : 3;
          this.mercoledi =
            giorni.substr(2, 1) === " "
              ? 0
              : giorni.substr(2, 1) === "M"
              ? 1
              : giorni.substr(2, 1) === "P"
              ? 2
              : 3;
          this.giovedi =
            giorni.substr(3, 1) === " "
              ? 0
              : giorni.substr(3, 1) === "M"
              ? 1
              : giorni.substr(3, 1) === "P"
              ? 2
              : 3;
          this.venerdi =
            giorni.substr(4, 1) === " "
              ? 0
              : giorni.substr(4, 1) === "M"
              ? 1
              : giorni.substr(4, 1) === "P"
              ? 2
              : 3;
        }
        if (
          this.autocompletamento[val].referente !== "" &&
          this.autocompletamento[val].referente !== null
        ) {
          if (this.autocompletamento[val].referente.trim() !== "") {
            this.RiferimentoRitiro = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].referente2 !== "" &&
          this.autocompletamento[val].referente2 != null
        ) {
          if (this.autocompletamento[val].referente2.trim() !== "") {
            this.RiferimentoRitiro = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
        }
        if (
          this.autocompletamento[val].telefono !== "" &&
          this.autocompletamento[val].telefono !== null
        ) {
          if (this.autocompletamento[val].telefono.trim() !== "") {
            this.TelefonoRitiro = this.autocompletamento[val].telefono.replace(
              "''",
              "'"
            );
          }
        } else if (
          this.autocompletamento[val].telefono2 !== "" &&
          this.autocompletamento[val].telefono2 !== null
        ) {
          if (this.autocompletamento[val].telefono2.trim() !== "") {
            this.TelefonoRitiro = this.autocompletamento[val].telefono2.replace(
              "''",
              "'"
            );
          }
        }
        if (
          this.autocompletamento[val].email !== "" &&
          this.autocompletamento[val].email !== null
        ) {
          if (this.autocompletamento[val].email.trim() !== "") {
            this.email = this.autocompletamento[val].email.replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].email2 !== "" &&
          this.autocompletamento[val].email2 !== null
        ) {
          if (this.autocompletamento[val].email2.trim() !== "") {
            this.email = this.autocompletamento[val].email2.replace("''", "'");
          }
        }
        this.listaRiferimentiRitiro = [];
        if (
          (this.autocompletamento[val].referente !== "" &&
            this.autocompletamento[val].referente !== null) ||
          (this.autocompletamento[val].telefono !== "" &&
            this.autocompletamento[val].telefono !== null) ||
          (this.autocompletamento[val].email !== "" &&
            this.autocompletamento[val].email !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";
          if (this.autocompletamento[val].referente === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono.replace("''", "'");
          }
          if (this.autocompletamento[val].email === null) email = "";
          else {
            email = this.autocompletamento[val].email.replace("''", "'");
          }
          this.listaRiferimentiRitiro.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        if (
          (this.autocompletamento[val].referente2 !== "" &&
            this.autocompletamento[val].referente2 !== null) ||
          (this.autocompletamento[val].telefono2 !== "" &&
            this.autocompletamento[val].telefono2 !== null) ||
          (this.autocompletamento[val].email2 !== "" &&
            this.autocompletamento[val].email2 !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";
          if (this.autocompletamento[val].referente2 === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono2 === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono2.replace("''", "'");
          }
          if (this.autocompletamento[val].email2 === null) email = "";
          else {
            email = this.autocompletamento[val].email2.replace("''", "'");
          }
          this.listaRiferimentiRitiro.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        var el = this.autocompletamento[val];
        if (
          el.oraAperturaMattino != null &&
          el.oraAperturaMattino != undefined &&
          el.oraAperturaMattino != ""
        )
          this.MDM =
            el.oraAperturaMattino.substring(0, 2) +
            ":" +
            el.oraAperturaMattino.substring(2, 4);
        else this.MDM = "08:00";
        if (
          el.oraChiusuraMattino != null &&
          el.oraChiusuraMattino != undefined &&
          el.oraChiusuraMattino != ""
        )
          this.MAM =
            el.oraChiusuraMattino.substring(0, 2) +
            ":" +
            el.oraChiusuraMattino.substring(2, 4);
        else this.MAM = "13:00";
        if (
          el.oraAperturaPomeriggio != null &&
          el.oraAperturaPomeriggio != undefined &&
          el.oraAperturaPomeriggio != ""
        )
          this.PDP =
            el.oraAperturaPomeriggio.substring(0, 2) +
            ":" +
            el.oraAperturaPomeriggio.substring(2, 4);
        else this.PDP = "13:30";
        if (
          el.oraChiusuraPomeriggio != null &&
          el.oraChiusuraPomeriggio != undefined &&
          el.oraChiusuraPomeriggio != ""
        )
          this.PAP =
            el.oraChiusuraPomeriggio.substring(0, 2) +
            ":" +
            el.oraChiusuraPomeriggio.substring(2, 4);
        else this.PAP = "18:00";
        this.RubricaRitiro = false;
        this.$refs.indirizzo_ritiro.focus();
      } else {
        this.RagioneSocialeRitiro = {
          text: this.tempRagioneSocialeRitiro.toUpperCase(),
          value: 0,
        };
        this.autocomplete.push({
          text: this.tempRagioneSocialeRitiro.toUpperCase(),
          value: 0,
        });
        this.listaRiferimentiRitiro = [];
        this.idModificaRitiro = 0;
        this.MDM = "08:00";
        this.MAM = "13:00";
        this.PDP = "13:30";
        this.PAP = "18:00";
        this.email = "";
        this.TelefonoRitiro = "";
        this.RiferimentoRitiro = "";
      }
    },
    AddAutocompleteConsegna() {
      var val =
        this.RagioneSocialeDestinatario.text +
        " -- " +
        this.RagioneSocialeDestinatario.value;
      if (
        val in this.autocompletamento &&
        this.tempRagioneSocialeConsegna === ""
      ) {
        this.tempRagioneSocialeConsegna = "";
        this.idModificaDestinatario = this.autocompletamento[val].id;
        this.nominativoConsegna = "";
        this.telefonoConsegna = "";
        this.preavvisoConsegnaObbligatorio = false;
        this.preavvisoConsegna = false;
        this.mailConsegna = "";
        var numeroCivico =
          this.autocompletamento[val].numciv != null
            ? this.autocompletamento[val].numciv
            : "";
        this.IndirizzoDestinatario =
          this.autocompletamento[val].indirizzo.replace("''", "'").trim() +
          " " +
          numeroCivico.trim();
        this.LocalitaDestinatario = this.autocompletamento[val].comune.replace(
          "''",
          "'"
        );
        this.ProvinciaDestinatario = this.autocompletamento[
          val
        ].provincia.replace("''", "'");
        var cap =
          this.autocompletamento[val].capestero != "" &&
          this.autocompletamento[val].capestero != null
            ? this.autocompletamento[val].capestero
            : this.autocompletamento[val].cap;
        this.CapDestinatario = cap;
        this.$refs.provincia_consegna.focus();
        if (
          this.autocompletamento[val].nazione !== null &&
          this.autocompletamento[val].nazione !== undefined
        ) {
          this.nazione_destino_selezionata =
            this.autocompletamento[val].nazione;
          this.nazione_dest = { value: this.nazione_destino_selezionata };
        } else {
          this.nazione_destino_selezionata = "I";
          this.nazione_dest = { value: "I" };
        }
        if (
          this.autocompletamento[val].referente !== "" &&
          this.autocompletamento[val].referente !== null
        ) {
          if (this.autocompletamento[val].referente.trim() !== "") {
            this.nominativoConsegna = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].referente2 !== "" &&
          this.autocompletamento[val].referente2 != null
        ) {
          if (this.autocompletamento[val].referente2.trim() !== "") {
            this.nominativoConsegna = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
        }
        if (
          this.autocompletamento[val].telefono !== "" &&
          this.autocompletamento[val].telefono !== null
        ) {
          if (this.autocompletamento[val].telefono.trim() !== "") {
            this.telefonoConsegna = this.autocompletamento[
              val
            ].telefono.replace("''", "'");
          }
        } else if (
          this.autocompletamento[val].telefono2 !== "" &&
          this.autocompletamento[val].telefono2 !== null
        ) {
          if (this.autocompletamento[val].telefono2.trim() !== "") {
            this.telefonoConsegna = this.autocompletamento[
              val
            ].telefono2.replace("''", "'");
          }
        }
        if (
          this.autocompletamento[val].email !== "" &&
          this.autocompletamento[val].email !== null
        ) {
          if (this.autocompletamento[val].email.trim() !== "") {
            this.mailConsegna = this.autocompletamento[val].email.replace(
              "''",
              "'"
            );
          }
        } else if (
          this.autocompletamento[val].email2 !== "" &&
          this.autocompletamento[val].email2 !== null
        ) {
          if (this.autocompletamento[val].email2.trim() !== "") {
            this.mailConsegna = this.autocompletamento[val].email2.replace(
              "''",
              "'"
            );
          }
        }
        this.listaRiferimentiConsegna = [];
        if (
          (this.autocompletamento[val].referente !== "" &&
            this.autocompletamento[val].referente !== null) ||
          (this.autocompletamento[val].telefono !== "" &&
            this.autocompletamento[val].telefono !== null) ||
          (this.autocompletamento[val].email !== "" &&
            this.autocompletamento[val].email !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";
          if (this.autocompletamento[val].referente === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono.replace("''", "'");
          }
          if (this.autocompletamento[val].email === null) email = "";
          else {
            email = this.autocompletamento[val].email.replace("''", "'");
          }
          this.listaRiferimentiConsegna.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        if (
          (this.autocompletamento[val].referente2 !== "" &&
            this.autocompletamento[val].referente2 !== null) ||
          (this.autocompletamento[val].telefono2 !== "" &&
            this.autocompletamento[val].telefono2 !== null) ||
          (this.autocompletamento[val].email2 !== "" &&
            this.autocompletamento[val].email2 !== null)
        ) {
          var referente = "";
          var telefono = "";
          var email = "";
          if (this.autocompletamento[val].referente2 === null) referente = "";
          else {
            referente = this.autocompletamento[val].referente2
              .substr(0, 17)
              .replace("''", "'");
          }
          if (this.autocompletamento[val].telefono2 === null) telefono = "";
          else {
            telefono = this.autocompletamento[val].telefono2.replace("''", "'");
          }
          if (this.autocompletamento[val].email2 === null) email = "";
          else {
            email = this.autocompletamento[val].email2.replace("''", "'");
          }
          this.listaRiferimentiConsegna.push({
            ref: referente,
            cell: telefono,
            mail: email,
          });
        }
        var i = this.autocompleteConsegna.indexOf(
          this.RagioneSocialeDestinatario
        );
        var elemento = this.autocompletamento[val];
        this.autocompleteConsegna.splice(i);
        this.RagioneSocialeDestinatario = {
          text: val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase(),
          value: this.RagioneSocialeDestinatario.value,
        };
        this.autocompleteConsegna.push(this.RagioneSocialeDestinatario);
        this.autocompletamento[
          this.RagioneSocialeDestinatario.text.toUpperCase() +
            " -- " +
            this.RagioneSocialeDestinatario.value
        ] = elemento;
        this.tempAutocomplete[
          val
            .replace(" -- " + val.split(" -- ")[1], "")
            .replace(" -- " + val.split(" -- ")[2], "")
            .toUpperCase()
        ] = elemento;
      } else {
        this.RagioneSocialeDestinatario = {
          text: this.tempRagioneSocialeConsegna.toUpperCase(),
          value: 0,
        };
        this.autocompleteConsegna.push({
          text: this.tempRagioneSocialeConsegna.toUpperCase(),
          value: 0,
        });
        this.listaRiferimentiConsegna = [];
        this.idModificaDestinatario = 0;
        this.mailConsegna = "";
        this.telefonoConsegna = "";
        this.nominativoConsegna = "";
      }
    },
    SalvaRubricaRitiro() {
      this.overlay = true;
      if (this.modificaRitiro) {
        var giochi = "";
        giochi +=
          this.lunedi === 0
            ? " "
            : this.lunedi === 1
            ? "M"
            : this.lunedi === 2
            ? "P"
            : "T";
        giochi +=
          this.martedi === 0
            ? " "
            : this.martedi === 1
            ? "M"
            : this.martedi === 2
            ? "P"
            : "T";
        giochi +=
          this.mercoledi === 0
            ? " "
            : this.mercoledi === 1
            ? "M"
            : this.mercoledi === 2
            ? "P"
            : "T";
        giochi +=
          this.giovedi === 0
            ? " "
            : this.giovedi === 1
            ? "M"
            : this.giovedi === 2
            ? "P"
            : "T";
        giochi +=
          this.venerdi === 0
            ? " "
            : this.venerdi === 1
            ? "M"
            : this.venerdi === 2
            ? "P"
            : "T";
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
            id: 0,
            clientId: "",
            ragioneSociale: this.RagioneSocialeRitiro.text.replace("'", "''"),
            indirizzo: this.IndirizzoRitiro.replace("'", "''"),
            numciv: "",
            cap: this.CAPRitiro.replace("'", "''").substring(0, 5),
            comune: this.LocalitaRitiro.replace("'", "''"),
            provincia: this.ProvinciaRitiro.replace("'", "''"),
            referente: this.RiferimentoRitiro.replace("'", "''"),
            email: this.email.replace("'", "''"),
            telefono: this.TelefonoRitiro.replace("'", "''"),
            oraAperturaMattino: this.MDM.replace(":", ""),
            oraChiusuraMattino: this.MAM.replace(":", ""),
            oraAperturaPomeriggio: this.PDP.replace(":", ""),
            oraChiusuraPomeriggio: this.PAP.replace(":", ""),
            giorniAperturaSettimanali: giochi,
            referente2: "",
            email2: "",
            telefono2: "",
            capEstero: this.CAPRitiro.replace("'", "''"),
            nazione: this.nazione_ritiro_selezionata,
            lastAction: "",
            codiceAzienda: "",
          })
          .then((res) => {
            if (this.modificaDestinatario) {
              this.axios
                .post(
                  this.$store.state.servicePath + "/api/ArRubricaClientis/",
                  {
                    id: 0,
                    clientId: "",
                    ragioneSociale:
                      this.RagioneSocialeDestinatario.text.replace("'", "''"),
                    indirizzo: this.IndirizzoDestinatario.replace("'", "''"),
                    numciv: "",
                    cap: this.CapDestinatario.replace("'", "''").substring(
                      0,
                      5
                    ),
                    comune: this.LocalitaDestinatario.replace("'", "''"),
                    provincia: this.ProvinciaDestinatario.replace("'", "''"),
                    referente: this.nominativoConsegna.replace("'", "''"),
                    email: this.mailConsegna.replace("'", "''"),
                    telefono: this.telefonoConsegna.replace("'", "''"),
                    oraAperturaMattino: "",
                    oraChiusuraMattino: "",
                    oraAperturaPomeriggio: "",
                    oraChiusuraPomeriggio: "",
                    giorniAperturaSettimanali: "     ",
                    referente2: "",
                    email2: "",
                    telefono2: "",
                    capEstero: this.CapDestinatario.replace("'", "''"),
                    nazione: this.nazione_destino_selezionata,
                    lastAction: "",
                    codiceAzienda: "",
                  }
                )
                .then((res) => {
                  this.overlay = false;
                  this.salvaRitiro = false;
                  this.RitiroAggiunto = true;
                })
                .catch((err) => {
                  this.overlay = false;
                  this.salvaRitiro = false;
                  this.RitiroAggiunto = true;
                });
            } else {
              this.overlay = false;
              this.salvaRitiro = false;
              this.RitiroAggiunto = true;
            }
          })
          .catch((err) => {
            this.overlay = false;
            this.salvaRitiro = false;
            this.RitiroAggiunto = true;
          });
      } else if (this.modificaDestinatario) {
        this.axios
          .post(this.$store.state.servicePath + "/api/ArRubricaClientis/", {
            id: 0,
            clientId: "",
            ragioneSociale: this.RagioneSocialeDestinatario.text.replace(
              "'",
              "''"
            ),
            indirizzo: this.IndirizzoDestinatario.replace("'", "''"),
            numciv: "",
            cap: this.CapDestinatario.replace("'", "''").substring(0, 5),
            comune: this.LocalitaDestinatario.replace("'", "''"),
            provincia: this.ProvinciaDestinatario.replace("'", "''"),
            referente: this.nominativoConsegna.replace("'", "''"),
            email: this.mailConsegna.replace("'", "''"),
            telefono: this.telefonoConsegna.replace("'", "''"),
            oraAperturaMattino: "",
            oraChiusuraMattino: "",
            oraAperturaPomeriggio: "",
            oraChiusuraPomeriggio: "",
            giorniAperturaSettimanali: "     ",
            referente2: "",
            email2: "",
            telefono2: "",
            capEstero: this.CapDestinatario.replace("'", "''"),
            nazione: this.nazione_destino_selezionata,
            lastAction: "",
            codiceAzienda: "",
          })
          .then((res) => {
            this.overlay = false;
            this.salvaRitiro = false;
            this.RitiroAggiunto = true;
          })
          .catch((err) => {
            this.overlay = false;
            this.salvaRitiro = false;
            this.RitiroAggiunto = true;
          });
      }
    },
    focusNextTick(el) {
      this.$nextTick(() => {
        el.focus();
      });
    },
  },
  watch: {
    spedDaRitirare(val) {
      this.spedDaRitirare = val.toUpperCase();
    },
    selectedNazione(val) {
      if (val === null) {
        // do nothing
      }
      if (val === "") {
        val = " ";
        this.NazioneDestinoFinaleScelta = "";
      }
    },
    preavvisare: function (value) {
      if (value) {
        this.mostraPreavvisareRitiro = true;
        this.CheckPreavviso();
      } else {
        this.preavvisoObbligatorio = false;
        this.mostraPreavvisareRitiro = false;
      }
    },
    riferimentoRitiro: function (value) {
      this.riferimentoRitiro = value.toUpperCase();
    },
    riferimentoEstero: function (value) {
      this.riferimentoEstero = value.toUpperCase();
    },
    mailConsegna: function (value) {
      this.mailConsegna = value.toUpperCase();
    },
    nominativoConsegna: function (value) {
      this.nominativoConsegna = value.toUpperCase();
    },
    ProvinciaDestinatario: function (value) {
      this.ProvinciaDestinatario = value.toUpperCase();
    },
    LocalitaDestinatario: function (value) {
      this.LocalitaDestinatario = value.toUpperCase();
    },
    IndirizzoDestinatario: function (value) {
      this.IndirizzoDestinatario = value.toUpperCase();
    },
    email: function (value) {
      this.email = value.toUpperCase();
    },
    RiferimentoRitiro: function (value) {
      this.RiferimentoRitiro = value.toUpperCase();
    },
    IndirizzoRitiro: function (value) {
      this.IndirizzoRitiro = value.toUpperCase();
    },
    LocalitaRitiro: function (value) {
      this.LocalitaRitiro = value.toUpperCase();
    },
    ProvinciaRitiro: function (value) {
      this.ProvinciaRitiro = value.toUpperCase();
    },
    lunlar: function (value) {
      if (value) {
        this.altez = false;
        this.misuraScelta = 1;
      }
    },
    altez: function (value) {
      if (value) {
        this.lunlar = false;
        this.misuraScelta = 2;
      }
    },
    NumeroColli: function (value) {
      this.checkTotalePallet();
    },
    NumeroBancali: function (value) {
      this.checkTotalePallet();
    },
    Indi: function (value) {
      if (value) {
        this.GetTassativa(1);
        this.Tass = false;
      } else {
        this.GetTassativa(2);
        this.Tass = true;
        if (this.Monodestinatario) this.dialogRitiroTassativo = true;
      }
    },
    Tass: function (value) {
      if (value) {
        this.GetTassativa(2);
        this.Indi = false;
        if (this.Monodestinatario) this.dialogRitiroTassativo = true;
      } else {
        this.GetTassativa(1);
        this.Indi = true;
      }
    },
    Monodestinatario: function (value) {
      if (value) {
        this.Multidestinatario = false;
        this.GetDestinatario(1);
        if (this.Tass) this.dialogRitiroTassativo = true;
      } else {
        if (!this.Multidestinatario) this.GetDestinatario(0);
      }
    },
    Multidestinatario: function (value) {
      if (value) {
        this.Monodestinatario = false;
        this.GetDestinatario(2);
      } else {
        if (!this.Monodestinatario) this.GetDestinatario(0);
      }
    },
    MDM: function (value) {
      if (value.length == 2) this.MDM = value + ":";
    },
    MAM: function (value) {
      if (value.length == 2) this.MAM = value + ":";
    },
    PAP: function (value) {
      if (value.length == 2) this.PAP = value + ":";
    },
    PDP: function (value) {
      if (value.length == 2) this.PDP = value + ":";
    },
    soloMattino: function (value) {
      if (value) this.soloPomeriggio = false;
    },
    soloPomeriggio: function (value) {
      if (value) this.soloMattino = false;
    },
    MostraDettagli: function (value) {
      if (value && (this.NumeroColli > 0 || this.NumeroBancali > 0)) {
        this.attivaDettagli = true;
        if (this.MisureBancali.length == 0) {
          this.MisureBancali.push({
            numeroPallet: 0,
            lunghezza: 0,
            larghezza: 0,
            altezza: 0,
          });
        }
      } else {
        this.attivaDettagli = false;
      }
    },
    PesoMerce: function (value) {
      this.PesoMerce = value.toString().replace(",", ".");
    },
    assicurata: function (value) {
      this.assicurata = value.toString().replace(",", ".");
    },
    Volume: function (value) {
      this.Volume = value.toString().replace(",", ".");
    },
    nazione: function (value) {
      this.nazione_ritiro_selezionata =
        value.value != undefined ? value.value : value;
      if (
        this.nazione_ritiro_selezionata === "I" ||
        this.nazione_ritiro_selezionata === "V"
      ) {
        this.riferimentoEstero = "";
      }
    },
    nazione_dest: function (value) {
      this.nazione_destino_selezionata =
        value.value != undefined ? value.value : value;
      if (
        this.nazione_destino_selezionata !== "I" &&
        this.nazione_destino_selezionata !== "V"
      ) {
        //Commentati per modifica del 24/02/2022 => Chiesto di non nascondere più i campi se destinatario estero, tranne facchinaggio, cons. al piano e merce in cantina
        //this.fermoDeposito = false;
        //this.riferimentoRitiro = "";
        this.concordataConsegnaTassativa = false;
        this.tassativaConsegnaSelezionata = { value: -1 };
        this.consegnaTassativa = -1;
        this.data_partenza = "";
        this.espresso = false;
        this.facchinaggioConsegna = false;
        //this.aperturaLateraleConsegna = false;
        //this.transpalletConsegna = false;
        //this.furgoneConsegna = false;
        this.servizioPianoConsegna = false;
        //this.spondaConsegna = false;
        this.cantinaConsegna = false;
        //this.mailConsegna = "";
        //this.preavvisoConsegnaObbligatorio = false;
        //this.telefonoConsegna = "";
        //this.preavvisoConsegna = false;
      }
    },
    selectedRitiro(val) {
      if (val === null) {
        // do nothing
      } else {
        if (val === "") {
          val = " ";
          this.RagioneSocialeRitiro = { text: "", value: 0 };
        } else {
          if (!(val in this.tempAutocomplete)) {
            this.tempRagioneSocialeRitiro = val.toUpperCase();
          } else {
            this.tempRagioneSocialeRitiro = "";
            this.$refs.ragione_sociale_ritiro.blur();
            this.$refs.indirizzo_ritiro.focus();
          }
        }
      }
    },
    concordataConsegnaTassativa(value) {
      this.concordataTassativa = value;
      this.CambiaTassSelezionata(value);
    },
    selected(val) {
      if (val === null) {
        // do nothing
      } else {
        if (val === "") {
          val = " ";
          this.RagioneSocialeDestinatario = { text: "", value: 0 };
        } else {
          if (!(val in this.tempAutocomplete)) {
            this.tempRagioneSocialeConsegna = val.toUpperCase();
          } else {
            this.tempRagioneSocialeConsegna = "";
            this.$refs.ragione_sociale_consegna.blur();
            this.$refs.indirizzo_consegna.focus();
          }
        }
      }
    },
    rifclienteEstero(value) {
      this.rifclienteEstero = value.toUpperCase();
    },
    ragsocDestinoFinale(value) {
      this.ragsocDestinoFinale = value.toUpperCase();
    },
    indirizzoDestinoFinale(value) {
      this.indirizzoDestinoFinale = value.toUpperCase();
    },
    comuneDestinoFinale(value) {
      this.comuneDestinoFinale = value.toUpperCase();
    },
    capDestinoFinale(value) {
      this.capDestinoFinale = value.toUpperCase();
    },
    provinciaDestinoFinale(value) {
      this.provinciaDestinoFinale = value.toUpperCase();
    },
  },
};
</script>

<style scoped>
.v-text-field {
  margin: 0;
  padding: 0;
}
.sm="1"2 {
  max-width: 100%;
}
.sm="6" {
  max-width: 50%;
}
.sm="3" {
  max-width: 33%;
}
.sm="2" {
  max-width: 17, 5%;
}
.sm="1" {
  max-width: 17, 5%;
}
</style>