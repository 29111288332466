<template >
  <v-container>
    <v-row v-if="$store.state.visualizzaSelectMaster">
      <v-col cols="12" md="4">
        <v-select
          v-model="$store.state.MasterSelezionato"
          :items="$store.state.codiciDaVisualizzare"
          @change="CambiaCodiceSelezionato()"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-form>
      <v-layout justify-center>
        <v-dialog v-model="errore" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">{{ $t("key334") }}</v-card-title>
            <v-card-text
              >Gentile utente, si è verificato un errore in fase di scaricamento
              dei pod, ti invitiamo a riprovare.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="errore = false"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="acquista" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">{{ $t("key066") }}</v-card-title>
            <v-card-text
              >Gentile utente, confermi di voler scaricare la documentazione
              elettronica per queste spedizioni? Procedendo con questa
              operazione verranno applicate le clausole
              contrattuali.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                style="color: white"
                color="#A0A4A7"
                @click="acquista = false"
                >{{ $t("key335") }}</v-btn
              >
              <v-btn depressed color="primary" @click="ScaricaPod">{{
                $t("key336")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="erroreDimensione" persistent max-width="400">
          <v-card>
            <v-card-title class="headline">{{ $t("key334") }}</v-card-title>
            <v-card-text
              >Gentile utente, non è possibile scaricare la documentazione
              elettronica per un numero di spedizioni superiore a
              100.</v-card-text
            >
            <v-card-text
              >Riduci il numero di risultati affinando i filtri di
              ricerca.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="primary" @click="erroreDimensione = false"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-card>
        <v-container>
          <v-layout justify-center>
            <v-dialog v-model="erroreValidazione" persistent max-width="400">
              <v-card>
                <v-card-title class="headline">{{ $t("key337") }}</v-card-title>
                <v-card-text
                  >Gentile utente,<br /><br />
                  - inserire almeno le date di interesse o, alternativamente:
                  anno, filiale di competenza e progressivo spedizione.<br />
                  - se selezionata l'opzione per la visualizzazione della
                  certificazione peso / volume è obbligatorio selezionare le
                  date di interesse.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    @click="erroreValidazione = false"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
          <v-form>
            <v-container>
              <h1>{{ $t("key033") }}:</h1>
              <h4>( {{ $t("key034") }} )</h4>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="data_partenza"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="data_partenza"
                        :label="$t('key035')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="it-IT"
                      v-model="data_partenza"
                      scrollable
                      :min="minPartenza"
                      :max="maxPartenza"
                      @input="$refs.dialog.save(data_partenza)"
                      color="primary"
                      first-day-of-week="1"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="#A0A4A7"
                        style="color: white"
                        @click="modal = false"
                        >Annulla</v-btn
                      >
                      <v-btn depressed color="primary" @click="checkFinale"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="2">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="data_finale"
                    persistent
                    width="290px"
                  >
                    <v-spacer></v-spacer>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="data_finale"
                        :label="$t('key036')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="it-IT"
                      v-model="data_finale"
                      scrollable
                      :min="minFinale"
                      :max="maxFinale"
                      @input="$refs.dialog2.save(data_finale)"
                      color="primary"
                      first-day-of-week="1"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        depressed
                        color="#A0A4A7"
                        style="color: white"
                        @click="modal2 = false"
                        >Annulla</v-btn
                      >
                      <v-btn
                        depressed
                        color="primary"
                        @click="$refs.dialog2.save(data_finale)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
                  >From {{ pageStart }} to {{ pageStop }}</template
                > -->
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="4"> </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-btn
                    large
                    style="box-shadow: none; color: white"
                    color="primary"
                    @click="ApriManuale()"
                    >MANUALE TRACKING</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                    :label="$t('key037')"
                    v-model="rag_destinatario"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    :label="$t('key038')"
                    v-model="localita_dest"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    :label="$t('key039')"
                    v-model="provincia_dest"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4"> </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    :label="$t('key040')"
                    v-model="numero_ddt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="0" md="4"> </v-col>
                <v-col cols="12" sm="0" md="4"> </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select
                    :items="stato"
                    v-model="Stati"
                    :label="$t('key041')"
                    @change="GetStati"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select
                    :items="porto"
                    v-model="porti"
                    :label="$t('key042')"
                    @change="GetPorto"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2">
                  <v-select
                    :items="nazioni"
                    v-model="nazione"
                    :label="$t('key043')"
                    @change="GetNazioni"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    :items="elementiContrassegno"
                    v-model="filtroContrassegno"
                    :label="$t('key165')"
                    @change="GetContrassegno"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="2"> </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    :label="$t('key044')"
                    v-model="annoSpe"
                    maxlength="4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    :label="$t('key045')"
                    v-model="filialeSpe"
                    maxlength="3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    :label="$t('key046')"
                    v-model="Spe"
                    maxlength="8"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="1"> </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                    v-if="codice !== 0 && Number(codice) < 1000"
                    :items="ListaClienti"
                    v-model="cliente"
                    :label="$t('key051')"
                    @change="GetCliente"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-checkbox
                  v-model="rulliera"
                  :label="$t('key358')"
                  class="ml-2"
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    class="mx-2"
                    large
                    depressed
                    color="primary"
                    @click="validate"
                    >{{ $t("key004") }}</v-btn
                  >
                  <v-btn
                    large
                    style="box-shadow: none; color: white; margin-left: 0px"
                    color="#A0A4A7"
                    @click="EstraiCSV"
                    >{{ $t("key338") }}</v-btn
                  >
                  <v-btn
                    class="ml-2"
                    large
                    style="box-shadow: none; color: white; margin-left: 0px"
                    color="#A0A4A7"
                    @click="formReset"
                    >{{ $t("key065") }}</v-btn
                  >
                </v-col>

                <v-col cols="12" sm="4" md="2"> </v-col>
              </v-row>
            </v-container>

            <v-card-text>
              <strong
                >{{ $t("key047") }}: {{ itemsVisualizzati.length }}</strong
              >
              <v-progress-circular
                v-if="carica"
                class="text-center ml-2"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-spacer></v-spacer>

              <!-- <v-text-field append-icon="mdi-magnify" label="Cerca" single-line hide-details v-model="search"></v-text-field> -->
            </v-card-text>
          </v-form>

          <v-data-table
            id="tabella_tracking"
            :headers="headers"
            :items="this.itemsVisualizzati"
            :items-per-page="25"
            sort-by="dataSpedizioneSort"
            :sort-desc="true"
            :footer-props="{
              'items-per-page-options': [5, 10, 25, -1],
            }"
            :search="search"
            @click:row="updateRecord"
          >
            <template v-slot:[`item.dataSpedizioneSort`]="{ item }">
              {{ item.dataSpedizione }}
            </template>
            <template v-slot:[`item.colore`]="{ item }">
              <v-icon :style="item.colore">mdi-record</v-icon>
            </template>
            <template v-slot:[`item.noteStato`]="{ item }">
              {{ item.noteStato }}
            </template>
            <template v-slot:[`item.codiceSpedizione`]="{ item }">
              {{ item.codiceVisualizzato }}
            </template>

            <!-- <template slot="items" slot-scope="props">
              <tr @click="updateRecord(props.item.codiceSpedizione)">
                <td class="text-xs-left">{{ props.item.dataSpedizione }}</td>
                <td class="text-xs-left">
                  <v-icon :style="props.item.colore"
                    >fiber_manual_record</v-icon
                  >
                </td>
                <td class="text-xs-left" style="background-color: #f4f4f4">
                  {{ props.item.noteStato }}
                </td>
                <td class="text-xs-left">{{ props.item.dataStatoSort }}</td>
                <td class="text-xs-left">
                  {{ props.item.codiceVisualizzato }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.riferimentoCliente }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.ragioneSocialeDestinatario }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.localitaDestinatario }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.provinciaDestinatario }}
                </td>
                <td class="text-xs-left">{{ props.item.valContrassegno }}</td>
              </tr>
            </template>
            <template slot="pageText" slot-scope="{ pageStart, pageStop }"
              >From {{ pageStart }} to {{ pageStop }}</template
            > -->
          </v-data-table>
        </v-container>
      </v-card>
    </v-form>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    elementiContrassegno_it: [
      { text: "", value: 0 },
      { text: "SI", value: 1 },
      { text: "NO", value: 2 },
    ],
    elementiContrassegno_en: [
      { text: "", value: 0 },
      { text: "YES", value: 1 },
      { text: "NO", value: 2 },
    ],
    elementiContrassegno: [],
    filtroContrassegno: 0,
    errore: false,
    erroreDimensione: false,
    acquista: false,
    mask: "###############",
    maxLenSpe: 15,
    valid: true,
    carica: false,
    cutstring: [],
    erroreValidazione: false,
    nazioneSelezionata: "TUTTI",
    statoSelezionato: "TUTTI",
    portoSelezionato: "TUTTI",
    nazione: null,
    nazioni_it: [
      { value: "TUTTI", text: "" },
      { value: "E", text: "ESTERO" },
      { value: "I", text: "ITALIA" },
    ],
    nazioni_en: [
      { value: "TUTTI", text: "" },
      { value: "E", text: "OTHER COUNTRIES" },
      { value: "I", text: "ITALY" },
    ],
    nazioni: [],
    stato: [{ value: "TUTTI", text: "" }],
    Stati: { value: "TUTTI", text: "" },
    porto_it: [
      { value: "TUTTI", text: "" },
      { value: "F", text: "FRANCO" },
      { value: "A", text: "ASSEGNATO" },
      { value: "T", text: "TRIANGOLAZIONE" },
    ],
    porto_en: [
      { value: "TUTTI", text: "" },
      { value: "F", text: "DAP" },
      { value: "A", text: "EXWORK" },
      { value: "T", text: "CROSS TRADE ORDER" },
    ],
    porto: [],
    porti: null,
    data_partenza: "",
    data_finale: "",
    modal: false,
    modal2: false,
    numero_ddt: null,
    rag_destinatario: null,
    localita_dest: null,
    provincia_dest: null,
    search: "",
    numero_sped: null,
    minPartenza: "01-01-2000",
    maxPartenza: "01-01-2000",
    minFinale: "00-00-0000",
    maxFinale: "00-00-0000",
    valoreStatoSelezionato: "",
    valorePortoSelezionato: "",
    headers_en: [
      { text: "Shipment date", align: "left", value: "dataSpedizioneSort" },
      { text: "", align: "left", value: "colore" },
      {
        text: "Status",
        align: "left",
        value: "noteStato",
      },
      { text: "Last status date", align: "left", value: "dataStatoSort" },
      { text: "Shipment number", align: "left", value: "codiceSpedizione" },
      { text: "Customer ref.", align: "left", value: "riferimentoCliente" },
      {
        text: "Consignee business name",
        align: "left",
        value: "ragioneSocialeDestinatario",
      },
      { text: "City", align: "left", value: "localitaDestinatario" },
      { text: "Prov.", align: "left", value: "provinciaDestinatario" },
      { text: "COD", align: "left", value: "valContrassegno" },
    ],
    headers_it: [
      { text: "Data spedizione", align: "left", value: "dataSpedizioneSort" },
      { text: "", align: "left", value: "colore" },
      {
        text: "Stato",
        align: "left",
        value: "noteStato",
      },
      { text: "Data ultimo stato", align: "left", value: "dataStatoSort" },
      { text: "Numero spedizione", align: "left", value: "codiceSpedizione" },
      { text: "Rif. cliente", align: "left", value: "riferimentoCliente" },
      {
        text: "Ragione sociale destinatario",
        align: "left",
        value: "ragioneSocialeDestinatario",
      },
      { text: "Località", align: "left", value: "localitaDestinatario" },
      { text: "Prov.", align: "left", value: "provinciaDestinatario" },
      { text: "Imp. Contrassegno.", align: "left", value: "valContrassegno" },
    ],
    headers: [],
    items: [],
    itemsVisualizzati: [],
    annoSpe: "",
    filialeSpe: "",
    Spe: "",
    codice: 0,
    ListaClienti: [],
    cliente: { text: "", value: "TUTTI" },
    clienteSelezionato: "TUTTI",
    permessoPodRim: "",
    variazioni: [],
    rulliera: false,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
      this.nazioni = this.nazioni_it;
      this.porto = this.porto_it;
      this.elementiContrassegno = this.elementiContrassegno_it;
      this.Stati = { value: "TUTTI", text: "" };
      this.stato = [{ value: "TUTTI", text: "" }];
    } else {
      this.headers = this.headers_en;
      this.nazioni = this.nazioni_en;
      this.porto = this.porto_en;
      this.elementiContrassegno = this.elementiContrassegno_en;
      this.Stati = { value: "TUTTI", text: "" };
      this.stato = [{ value: "TUTTI", text: "" }];
    }
    this.nazione = this.nazioni[0];
    this.porti = this.porto[0];
    this.CheckPPodRimessi();
    this.GetCodice();
    this.annoSpe = new Date().toISOString().substr(0, 4);
    window.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) this.validate();
    });
    this.CheckJWT();
    this.CaricaStati();
    if (
      this.$store.state.filtri_tracking.data_partenza ||
      this.$store.state.filtri_tracking.numero_sped
    ) {
      this.data_partenza = this.$store.state.filtri_tracking.data_partenza;
      this.minPartenza = this.$store.state.filtri_tracking.minPartenza;
      this.maxPartenza = this.$store.state.filtri_tracking.maxPartenza;
      this.data_finale = this.$store.state.filtri_tracking.data_finale;
      this.numero_ddt = this.$store.state.filtri_tracking.numero_ddt;
      this.rag_destinatario =
        this.$store.state.filtri_tracking.rag_destinatario;
      this.localita_dest = this.$store.state.filtri_tracking.localita_dest;
      this.provincia_dest = this.$store.state.filtri_tracking.provincia_dest;
      this.numero_sped = this.$store.state.filtri_tracking.numero_sped;
      this.annoSpe = this.numero_sped.substr(0, 4);
      this.filialeSpe = this.numero_sped.substr(4, 3);
      this.Spe = this.numero_sped.substr(7, 8);
      this.minFinale = this.$store.state.filtri_tracking.minFinale;
      this.maxFinale = this.$store.state.filtri_tracking.maxFinale;
      this.statoSelezionato =
        this.$store.state.filtri_tracking.statoSelezionato;
      this.portoSelezionato =
        this.$store.state.filtri_tracking.portoSelezionato;
      this.Stati = { value: this.statoSelezionato };
      this.porti = { value: this.portoSelezionato };
      this.itemsVisualizzati = this.$store.state.items_tracking;
      this.nazioneSelezionata = this.$store.state.filtri_tracking.nazione;
      this.nazione = { value: this.nazioneSelezionata };
      this.clienteSelezionato = this.$store.state.filtri_tracking.cliente;
      this.cliente = { value: this.clienteSelezionato };
      this.filtroContrassegno = this.$store.state.filtri_tracking.contrassegno;
      //this.effettuaRicerca();
    } else {
      // this.data_partenza = this.addDays(new Date(), -7)
      //   .toISOString()
      //   .substr(0, 10);
      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = new Date().toISOString().substr(0, 10);
      this.annoSpe = new Date().toISOString().substr(0, 4);
      this.filialeSpe = "";
      this.Spe = "";
      // this.data_finale = new Date().toISOString().substr(0, 10);
      this.numero_ddt = null;
      this.rag_destinatario = null;
      this.localita_dest = null;
      this.provincia_dest = null;
      this.numero_sped = "";
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    }
    this.CheckTrkAdm();
  },
  methods: {
    ApriManuale() {
      var link =
        "http://clienti.arcologistica.it/download/manuale_tracking.pdf";
      window.open(link);
    },
    RicaricaPagina() {
      this.$store.dispatch("ImpostaScollegamento");
      this.$store.state.filtriTracking = {};
      this.$store.state.items_tracking = [];
      this.itemsVisualizzati = [];
      if (this.$i18n.locale == "it") {
        this.headers = this.headers_it;
        this.nazioni = this.nazioni_it;
        this.porto = this.porto_it;
        this.elementiContrassegno = this.elementiContrassegno_it;
        this.Stati = { value: "TUTTI", text: "" };
        this.stato = [{ value: "TUTTI", text: "" }];
      } else {
        this.headers = this.headers_en;
        this.nazioni = this.nazioni_en;
        this.porto = this.porto_en;
        this.elementiContrassegno = this.elementiContrassegno_en;
        this.Stati = { value: "TUTTI", text: "" };
        this.stato = [{ value: "TUTTI", text: "" }];
      }
      this.data_partenza = "";
      this.data_finale = "";
      this.nazione = this.nazioni[0];
      this.porti = this.porto[0];
      this.CheckPPodRimessi();
      this.GetCodice();
      this.annoSpe = new Date().toISOString().substr(0, 4);
      window.addEventListener("keypress", (e) => {
        if (e.keyCode === 13) this.validate();
      });
      this.CheckJWT();
      this.CaricaStati();

      this.minPartenza = this.addDays(new Date(), -365)
        .toISOString()
        .substr(0, 10);
      this.maxPartenza = new Date().toISOString().substr(0, 10);
      this.annoSpe = new Date().toISOString().substr(0, 4);
      this.filialeSpe = "";
      this.Spe = "";
      // this.data_finale = new Date().toISOString().substr(0, 10);
      this.numero_ddt = null;
      this.rag_destinatario = null;
      this.localita_dest = null;
      this.provincia_dest = null;
      this.numero_sped = "";
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
      this.CheckTrkAdm();
    },
    CambiaCodiceSelezionato() {
      this.overlay = true;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.MasterSelezionato
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          this.RicaricaPagina();
          //this.overlay = false;
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    allowedDates: (val) => el.indexOf(val) !== -1,
    checkFinale() {
      this.$refs.dialog.save(this.data_partenza);
      this.minFinale = this.data_partenza;
      this.maxFinale = new Date().toISOString().substr(0, 10);
    },
    validate() {
      if (this.annoSpe === "" || this.annoSpe === undefined) {
        this.annoSpe = new Date().toISOString().substr(0, 4);
      }
      if (this.filialeSpe === "" || this.filialeSpe === undefined) {
        this.filialeSpe = "000";
      }
      if (this.annoSpe.length === 2) {
        this.annoSpe = "20" + this.annoSpe;
      }
      if (this.Spe === "" || this.Spe === undefined) {
        this.Spe = "00000000";
      }
      var padFil = "000";
      var fil =
        padFil.substring(0, padFil.length - this.filialeSpe.length) +
        this.filialeSpe;
      var pad = "00000000";
      var numero = pad.substring(0, pad.length - this.Spe.length) + this.Spe;
      this.numero_sped = this.annoSpe + fil + numero;
      if (
        this.numero_sped === "00000000000" ||
        this.numero_sped === "000000000000000" ||
        this.numero_sped.includes("00000000000") ||
        fil === "000" ||
        numero === "00000000"
      ) {
        this.numero_sped = "";
        this.annoSpe = new Date().toISOString().substr(0, 4);
        this.filialeSpe = "";
        this.Spe = "";
      }

      if (
        (this.data_partenza !== "" &&
          this.data_partenza !== undefined &&
          this.data_partenza !== null &&
          this.data_finale !== "" &&
          this.data_finale !== undefined &&
          this.data_finale !== null) ||
        (this.numero_sped !== "" &&
          this.numero_sped !== undefined &&
          this.numero_sped !== null &&
          this.numero_sped.length > 14)
      ) {
        if (
          this.rulliera === true &&
          (this.data_partenza === "" ||
            this.data_partenza === undefined ||
            this.data_partenza === null ||
            this.data_finale === "" ||
            this.data_finale === undefined ||
            this.data_finale === null)
        ) {
          this.erroreValidazione = true;
        } else {
          this.Cerca();
        }
      } else {
        this.erroreValidazione = true;
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    CaricaStati() {
      this.axios
        .get(this.$store.state.servicePath + "/api/ArStatiSpediziones", {})
        .then((res) => {
          res.data.forEach((element) => {
            this.el = { value: element.codice, text: element.descrizione };
            this.el2 = { key: element.codice, value: element.cutString };
            this.stato.push(this.el);
            this.cutstring.push(this.el2);
          });
        });
    },
    Cerca() {
      var filtri = {};
      filtri.data_partenza = this.data_partenza;
      filtri.minPartenza = this.minPartenza;
      filtri.maxPartenza = this.maxPartenza;
      filtri.data_finale = this.data_finale;
      filtri.numero_ddt = this.numero_ddt;
      filtri.rag_destinatario = this.rag_destinatario;
      filtri.localita_dest = this.localita_dest;
      filtri.provincia_dest = this.provincia_dest;
      filtri.numero_sped = this.numero_sped;
      filtri.minFinale = this.minFinale;
      filtri.maxFinale = this.maxFinale;
      filtri.statoSelezionato = this.statoSelezionato;
      filtri.valoreStatoSelezionato = this.valoreStatoSelezionato;
      filtri.portoSelezionato = this.portoSelezionato;
      filtri.valorePortoSelezionato = this.valorePortoSelezionato;
      filtri.nazione = this.nazioneSelezionata;
      filtri.cliente = this.clienteSelezionato;
      filtri.contrassegno = this.filtroContrassegno;

      this.$store.commit("setFiltriTracking", filtri);
      if (this.rulliera == true) this.CaricaVariazioni();
      else this.effettuaRicerca();
    },
    CaricaVariazioni() {
      this.loading = true;
      this.carica = true;
      this.variazioni = [];
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArVwVariazioniRullieras/Differenze",
          {
            dataPartenza: this.data_partenza,
            dataFinale: this.data_finale,
            numeroDdt: this.numero_ddt ? this.numero_ddt : "",
            ragioneSocialeDestinatario: this.rag_destinatario
              ? this.rag_destinatario
              : "",
            localitaDestino: this.localita_dest ? this.localita_dest : "",
            provinciaDestino: this.provincia_dest ? this.provincia_dest : "",
            NumeroSpedizione: this.numero_sped,
            Porto: this.portoSelezionato,
            statoSpedizione: this.statoSelezionato,
            nazioneDestinatario: this.nazioneSelezionata,
            codiceCliente: this.clienteSelezionato,
            contrassegno: this.filtroContrassegno,
            //devono essere definiti i campi sotto altrimenti la chiamata va in errore
            codice: "",
          }
        )
        .then((res) => {
          res.data.forEach((el) => {
            if (el.codiceSpedizione in this.variazioni) {
              this.variazioni[el.codiceSpedizione] = el;
            } else {
              this.variazioni[el.codiceSpedizione] = [];
              this.variazioni[el.codiceSpedizione] = el;
            }
          });
          this.effettuaRicerca();
        });
    },
    effettuaRicerca() {
      this.loading = true;
      this.carica = true;
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackings/GetTracking", {
          dataPartenza: this.data_partenza,
          dataFinale: this.data_finale,
          numeroDdt: this.numero_ddt ? this.numero_ddt : "",
          ragioneSocialeDestinatario: this.rag_destinatario
            ? this.rag_destinatario
            : "",
          localitaDestino: this.localita_dest ? this.localita_dest : "",
          provinciaDestino: this.provincia_dest ? this.provincia_dest : "",
          NumeroSpedizione: this.numero_sped,
          Porto: this.portoSelezionato,
          statoSpedizione: this.statoSelezionato,
          nazioneDestinatario: this.nazioneSelezionata,
          codiceCliente: this.clienteSelezionato,
          contrassegno: this.filtroContrassegno,
          //devono essere definiti i campi sotto altrimenti la chiamata va in errore
          codice: "",
        })
        .then((res) => {
          this.items = res.data;
          this.itemsVisualizzati = [];
          this.items.forEach((el) => {
            if (
              el.codiceSpedizione !== null &&
              el.codiceSpedizione !== undefined
            ) {
              var element = {
                dataSpedizione: el.dataSpedizione,
                dataSpedizioneSort:
                  el.dataSpedizione.substr(6, 4) +
                  el.dataSpedizione.substr(3, 2) +
                  el.dataSpedizione.substr(0, 2),
                ultimoStato: el.ultimoStato,
                noteStato: el.noteStato,
                codiceSpedizione: el.codiceSpedizione,
                riferimentoCliente: el.riferimentoCliente2,
                ragioneSocialeDestinatario: el.ragioneSocialeDestinatario,
                localitaDestinatario: el.localitaDestinatario,
                provinciaDestinatario: el.provinciaDestinatario,
                capDestinatario: el.capDestinatario,
                codiceVisualizzato:
                  el.codiceSpedizione.substr(0, 4) +
                  "-" +
                  el.codiceSpedizione.substr(4, 3) +
                  "-" +
                  el.codiceSpedizione.substr(7, 8),
                codiceCliente: el.codiceCliente,
                dataStato: el.dataStato,
                dataStatoSort:
                  el.dataStato != null && el.dataStato != ""
                    ? el.dataStato.substr(8, 2) +
                      "/" +
                      el.dataStato.substr(5, 2) +
                      "/" +
                      el.dataStato.substr(0, 4)
                    : "",
                ragioneSocialeMittente: el.ragioneSocialeMittente,
                indirizzoMittente: el.indirizzoMittente,
                localitaMittente: el.localitaMittente,
                provinciaMittente: el.provinciaMittente,
                capMittente: el.capMittente,
                nazioneMittente: el.nazioneMittente,
                indirizzoDestinatario: el.indirizzoDestinatario,
                nazioneDestinatario: el.nazioneDestinatario,
                numeroColli: el.numeroColli,
                peso: el.peso,
                volume: el.volume,
                valContrassegno: el.valContrassegno,
                colore: "color: " + el.colore,
                tipoIncasso: el.tipoIncasso,
                valContrassegno:
                  el.valContrassegno === "00000000000"
                    ? "-"
                    : (Number(el.valContrassegno) / 100).toString() + " €",
                asnraS1: el.asnraS1 === null ? "" : el.asnraS1,
                asdbaN1: el.asdbaN1 === null ? "" : el.asdbaN1,
                asdpaG1:
                  el.asdpaG1 === 0.0
                    ? ""
                    : el.asdpaG1.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG1.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG1.toString().substring(0, 4),
                asimpO1: el.asimpO1 === 0.0 ? "" : el.asimpO1,
                asnbcS1: el.asnbcS1 === 0.0 ? "" : el.asnbcS1,
                asnraS2: el.asnraS2 === null ? "" : el.asnraS2,
                asdbaN2: el.asdbaN2 === null ? "" : el.asdbaN2,
                asdpaG2:
                  el.asdpaG2 === 0.0
                    ? ""
                    : el.asdpaG2.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG2.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG2.toString().substring(0, 4),
                asimpO2: el.asimpO2 === 0.0 ? "" : el.asimpO2,
                asnbcS2: el.asnbcS2 === 0.0 ? "" : el.asnbcS2,
                asnraS3: el.asnraS3 === null ? "" : el.asnraS3,
                asdbaN3: el.asdbaN3 === null ? "" : el.asdbaN3,
                asdpaG3:
                  el.asdpaG3 === 0.0
                    ? ""
                    : el.asdpaG3.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG3.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG3.toString().substring(0, 4),
                asimpO3: el.asimpO3 === 0.0 ? "" : el.asimpO3,
                asnbcS3: el.asnbcS3 === 0.0 ? "" : el.asnbcS3,
                asnraS4: el.asnraS4 === null ? "" : el.asnraS4,
                asdbaN4: el.asdbaN4 === null ? "" : el.asdbaN4,
                asdpaG4:
                  el.asdpaG4 === 0.0
                    ? ""
                    : el.asdpaG4.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG4.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG4.toString().substring(0, 4),
                asimpO4: el.asimpO4 === 0.0 ? "" : el.asimpO4,
                asnbcS4: el.asnbcS4 === 0.0 ? "" : el.asnbcS4,
                asnraS5: el.asnraS5 === null ? "" : el.asnraS5,
                asdbaN5: el.asdbaN5 === null ? "" : el.asdbaN5,
                asdpaG5:
                  el.asdpaG5 === 0.0
                    ? ""
                    : el.asdpaG5.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG5.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG5.toString().substring(0, 4),
                asimpO5: el.asimpO5 === 0.0 ? "" : el.asimpO5,
                asnbcS5: el.asnbcS5 === 0.0 ? "" : el.asnbcS5,
                asnraS6: el.asnraS6 === null ? "" : el.asnraS6,
                asdbaN6: el.asdbaN6 === null ? "" : el.asdbaN6,
                asdpaG6:
                  el.asdpaG6 === 0.0
                    ? ""
                    : el.asdpaG6.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG6.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG6.toString().substring(0, 4),
                asimpO6: el.asimpO6 === 0.0 ? "" : el.asimpO6,
                asnbcS6: el.asnbcS6 === 0.0 ? "" : el.asnbcS6,
                asnraS7: el.asnraS7 === null ? "" : el.asnraS7,
                asdbaN7: el.asdbaN7 === null ? "" : el.asdbaN7,
                asdpaG7:
                  el.asdpaG7 === 0.0
                    ? ""
                    : el.asdpaG7.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG7.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG7.toString().substring(0, 4),
                asimpO7: el.asimpO7 === 0.0 ? "" : el.asimpO7,
                asnbcS7: el.asnbcS7 === 0.0 ? "" : el.asnbcS7,
                asnraS8: el.asnraS8 === null ? "" : el.asnraS8,
                asdbaN8: el.asdbaN8 === null ? "" : el.asdbaN8,
                asdpaG8:
                  el.asdpaG8 === 0.0
                    ? ""
                    : el.asdpaG8.toString().substring(6, 8) +
                      "/" +
                      el.asdpaG8.toString().substring(4, 6) +
                      "/" +
                      el.asdpaG8.toString().substring(0, 4),
                asimpO8: el.asimpO8 === 0.0 ? "" : el.asimpO8,
                asnbcS8: el.asnbcS8 === 0.0 ? "" : el.asnbcS8,
              };
              if (this.rulliera && element.codiceSpedizione in this.variazioni)
                this.itemsVisualizzati.push(element);
              else if (!this.rulliera) this.itemsVisualizzati.push(element);
            }
          });
          this.$store.commit("setItemsTracking", this.itemsVisualizzati);
          this.carica = false;
          this.loading = false;
        });
    },
    updateRecord(item) {
      this.$router.push({ path: `/tracking/${item.codiceSpedizione}` });
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch((err) => {
          this.$router.push({ path: `/errore` });
        });
    },
    GetContrassegno(value) {
      this.filtroContrassegno = value;
    },
    GetStati(value) {
      this.statoSelezionato = value;
    },
    GetPorto(value) {
      this.portoSelezionato = value;
    },
    GetNazioni(value) {
      this.nazioneSelezionata = value;
    },
    GetCliente(value) {
      this.clienteSelezionato = value;
    },
    CheckTrkAdm() {
      this.axios
        .get(
          this.$store.state.servicePath + "/api/ArUtentiPermessis/Check/TRK-ADM"
        )
        .then((res) => {
          if (res.data == "" || res.data == "0" || res.data == 0) {
            if (
              this.data_finale === "" ||
              this.data_finale === undefined ||
              this.data_finale === null
            ) {
              var dataCorrente = new Date();
              this.data_finale =
                dataCorrente.getFullYear() +
                "-" +
                String(dataCorrente.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(dataCorrente.getDate()).padStart(2, "0");
              dataCorrente.setDate(dataCorrente.getDate() - 7);
              this.data_partenza =
                dataCorrente.getFullYear() +
                "-" +
                String(dataCorrente.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(dataCorrente.getDate()).padStart(2, "0");
              this.validate();
            }
          } else {
            this.data_finale = "";
            this.data_partenza = "";
          }
        });
    },
    // EstraiCSV() {
    //   var dati = []
    //   var intestazione = ['Data spedizione', 'Ultimo stato', 'Numero spedizione', 'Riferimento Cliente', 'Ragione Sociale Destinatario', 'Provincia destinatario', 'Località destinatario'];
    //   dati.push(intestazione)
    //   this.itemsVisualizzati.forEach(e => {
    //     var riga = []
    //     riga.push(e.dataSpedizione)
    //     riga.push(e.ultimoStato)
    //     riga.push(e.codiceSpedizione)
    //     riga.push(e.riferimentoCliente)
    //     riga.push(e.ragioneSocialeDestinatario)
    //     riga.push(e.provinciaDestinatario)
    //     riga.push(e.localitaDestinatario)
    //     dati.push(riga)
    //   })
    //   let csvContent = "data:text/csv;charset=utf-8," + dati.map(e => e.join(';')).join('\n');
    //   var encodedUri = encodeURI(csvContent);
    //   const link = document.createElement("a");
    //   link.setAttribute("href", encodedUri);
    //   link.setAttribute("download", "export.csv");
    //   link.click();
    // },
    EstraiCSV() {
      var dati = [];
      var intestazione = [
        "NUMERO SPEDIZIONE",
        "DATA SPEDIZIONE",
        "CODICE CLIENTE",
        "ULTIMO STATO",
        "DATA ULTIMO STATO",
        "NUM. DDT",
        "RAG. SOC. MITT.",
        "IND. MITT.",
        "LOC. MIT.",
        "PROV. MIT.",
        "CAP MIT.",
        "NAZ. MIT.",
        "RAG. SOC. DES.",
        "IND. DES.",
        "LOC. DES.",
        "PROV. DES.",
        "CAP DES.",
        "NAZ. DES.",
        "N.RO COLLI",
        "PESO KG.",
        "VOLUME MC",
        "CONTRASSEGNO",
        "TIPO INCASSO",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
        "TIPOLOGIA RIMBORSO",
        "BANCA",
        "DATA RIMBORSO",
        "IMPORTO RIMBORSO",
        "BOLLA CONSEGNA",
      ];
      dati.push(intestazione);
      //this.itemsVisualizzati.sort((a,b) => (a.dataStato < b.dataStato) ? 1 : ((b.dataStato < a.dataStato) ? -1 : 0));
      this.itemsVisualizzati.forEach((e) => {
        var riga = [];
        riga.push(
          e.codiceVisualizzato != null
            ? e.codiceVisualizzato.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.dataSpedizione != null
            ? e.dataSpedizione.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.codiceCliente != null
            ? e.codiceCliente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.noteStato != null
            ? e.noteStato.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.dataStato != null
            ? e.dataStato.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.riferimentoCliente != null
            ? e.riferimentoCliente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.ragioneSocialeMittente != null
            ? e.ragioneSocialeMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.indirizzoMittente != null
            ? e.indirizzoMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.localitaMittente != null
            ? e.localitaMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.provinciaMittente != null
            ? e.provinciaMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.capMittente != null
            ? e.capMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.nazioneMittente != null
            ? e.nazioneMittente.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.ragioneSocialeDestinatario != null
            ? e.ragioneSocialeDestinatario
                .trim()
                .replace("\r", "")
                .replace(";", "")
            : ""
        );
        riga.push(
          e.indirizzoDestinatario != null
            ? e.indirizzoDestinatario.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.localitaDestinatario != null
            ? e.localitaDestinatario.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.provinciaDestinatario != null
            ? e.provinciaDestinatario.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.capDestinatario != null
            ? e.capDestinatario.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(
          e.nazioneDestinatario != null
            ? e.nazioneDestinatario.trim().replace("\r", "").replace(";", "")
            : ""
        );
        riga.push(e.numeroColli != null ? Number(e.numeroColli) : 0);
        riga.push(e.peso != null ? Number(e.peso) / 10 : 0);
        riga.push(e.volume != null ? Number(e.volume) / 100 : 0);
        riga.push(
          e.valContrassegno != "-" ? e.valContrassegno.replaceAll("€", "") : ""
        );
        riga.push(e.tipoIncasso);
        riga.push(e.asnraS1.trim());
        riga.push(e.asdbaN1.trim());
        riga.push(e.asdpaG1);
        riga.push(e.asimpO1);
        riga.push(e.asnbcS1);
        riga.push(e.asnraS2.trim());
        riga.push(e.asdbaN2.trim());
        riga.push(e.asdpaG2);
        riga.push(e.asimpO2);
        riga.push(e.asnbcS2);
        riga.push(e.asnraS3.trim());
        riga.push(e.asdbaN3.trim());
        riga.push(e.asdpaG3);
        riga.push(e.asimpO3);
        riga.push(e.asnbcS3);
        riga.push(e.asnraS4.trim());
        riga.push(e.asdbaN4.trim());
        riga.push(e.asdpaG4);
        riga.push(e.asimpO4);
        riga.push(e.asnbcS4);
        riga.push(e.asnraS5.trim());
        riga.push(e.asdbaN5.trim());
        riga.push(e.asdpaG5);
        riga.push(e.asimpO5);
        riga.push(e.asnbcS5);
        riga.push(e.asnraS6.trim());
        riga.push(e.asdbaN6.trim());
        riga.push(e.asdpaG6);
        riga.push(e.asimpO6);
        riga.push(e.asnbcS6);
        riga.push(e.asnraS7.trim());
        riga.push(e.asdbaN7.trim());
        riga.push(e.asdpaG7);
        riga.push(e.asimpO7);
        riga.push(e.asnbcS7);
        riga.push(e.asnraS8.trim());
        riga.push(e.asdbaN8.trim());
        riga.push(e.asdpaG8);
        riga.push(e.asimpO8);
        riga.push(e.asnbcS8);
        dati.push(riga);
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var decodedString = dati.map((e) => e.join(";")).join("\n");
        var byteCharacters = decodedString;
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "image/jpg",
        });
        navigator.msSaveBlob(blob, "Estrazione_Tracking.csv");
      } else {
        var universalBOM = "\uFEFF";
        let csvContent =
          "data:text/csv;charset=utf-8," +
          encodeURIComponent(dati.map((e) => e.join(";")).join("\n"));
        var encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", csvContent);
        link.setAttribute("download", "export.csv");
        link.click();
      }
    },
    formReset() {
      this.itemsVisualizzati = [];
      this.items = [];
      //this.$refs.form.reset();
      this.annoSpe = new Date().toISOString().substr(0, 4);
      this.filialeSpe = "";
      this.Spe = "";
      this.statoSelezionato = "TUTTI";
      this.portoSelezionato = "TUTTI";
      this.nazioneSelezionata = "TUTTI";
      this.nazione = { value: "TUTTI", text: "" };
      this.Stati = { value: "TUTTI", text: "" };
      this.porti = { value: "TUTTI", text: "" };
      this.clienteSelezionato = { value: "TUTTI", text: "" };
      this.erroreValidazione = false;
    },
    ScaricaPod() {
      this.acquista = false;
      var lista = [];
      this.itemsVisualizzati.forEach((el) => {
        if (el.ultimoStato == "CON") {
          lista.push(el.codiceSpedizione);
        }
      });
      this.axios
        .post(this.$store.state.servicePath + "/api/Pod/Massivo/Download", {
          bolle: lista,
        })
        .then((res) => {
          this.EstraiImmagini(res.data);
        })
        .error((err) => {
          this.errore = true;
        });
    },
    EstraiImmagini(e) {
      let imgContent = "data:mime/" + e.estensione + ";base64," + e.src;
      // let imgContent = e.src;
      var encodedUri = encodeURI(imgContent);
      var decodedString = atob(imgContent.replace("data:mime/zip;base64,", ""));
      var byteCharacters = decodedString;
      var byteNumbers = new Array(byteCharacters.length);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "mime/zip",
        });
        navigator.msSaveBlob(blob, "pod.zip");
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "pod.zip");
        link.click();
      }
    },
    GetCodice() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Login/getCodice")
        .then((res) => {
          this.codice = res.data;
          this.GetClienti();
        });
    },
    GetClienti() {
      this.ListaClienti = [{ text: "", value: "TUTTI" }];
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Ttrcd10f/ClientiTracking/" +
            this.codice
        )
        .then((res) => {
          res.data.forEach((el) => {
            this.ListaClienti.push({
              text: el.trccli + " - " + el.trcrgs,
              value: el.trccli,
            });
          });
        });
    },
    CheckPPodRimessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-PODRIM"
        )
        .then((res) => {
          this.permessoPodRim = res.data;
        });
    },
  },
  watch: {
    data_partenza: function () {
      this.minFinale = this.data_partenza;
    },
  },
};
</script>

<style>
#tabella_tracking table > tbody > tr > td:nth-child(3) {
  background-color: #f4f4f4;
}
</style>